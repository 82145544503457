import * as Array from './array';
import * as Object from './object';
import * as Event from './event';
import { Validation } from './validation';

const utils = {
  Array,
  Object,
  Event,
  Validation,
};

export default utils;
