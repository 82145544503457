import React from 'react';
import * as Styled from './icon.styled';

export type IconProps = {
  /**
   * class name to be used as external styling
   */
  className: string | undefined;
  /**
   * for component level styling override (Design System)
   * @config implementation required
   */
  configStyles?: string;
  /**
   * To identify the component
   */
  id?: string;
};

export function Icon({ configStyles = '', className, id }: IconProps) {
  return <Styled.Icon className={className} configStyles={configStyles} id={id} />;
}
