import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as Styled from './rangeslider.styled';
import { formatValue } from 'react-currency-input-field';

export type RangesliderProps = {
  /**
   * to handle class names
   */
  className?: string;

  /**
   * to handle styles
   */
  configStyles?: string;

  /**
   * To set the currency code (ISO 4217).
   */
  currencyCode?: string;

  /**
   * To set the currency format (Language tags BCP 47).
   */
  currencyLocale?: string;

  /**
   * Specifies the separator between integer part and fractional part of value.
   */
  decimalSeparator?: string;

  /**
   * To disabled rangeslider component
   */
  disabled?: boolean;

  /**
   * Specifies the separator between thousand, million and billion.
   */
  groupSeparator?: string;

  /**
   * An identifier for the Slider Component.
   */
  id: string;

  /**
   * Specifies the default value for maximum value.
   */
  initialMax?: number;

  /**
   * Specifies the default value and  for minimum value.
   */
  initialMin?: number;

  /**
   * Determines if the range is valid or not
   */
  isRangeValid?: boolean;

  /**
   * Specifies the maximum value allowed.
   */
  maxValue: number;

  /**
   * Specifies the minimum value allowed.
   */
  minValue: number;

  /**
   * Specifies the name of the Slider Component.
   */
  name: string;

  /**
   * Specifies a graphical representation denoting the currency name.
   */
  prefixSymbol?: string;

  /**
   * Form field helper : sets form field value
   */
  setValue?: any;

  /**
   * Specifies whether the slider will be single or double.
   */
  sliderType: 'single' | 'range';

  /**
   * Specifies the legal number intervals
   */
  step?: number;

  /**
   * Specifies the Suffix symbol.
   */
  suffixSymbol?: string;

  /**
   * Form field value
   */
  value?: string;
};

export function Rangeslider({
  id,
  name,
  sliderType,
  step,
  minValue,
  maxValue,
  initialMin,
  initialMax,
  currencyLocale,
  currencyCode,
  groupSeparator = '',
  decimalSeparator = '',
  prefixSymbol,
  disabled,
  isRangeValid,
  configStyles,
  className,
  value,
  suffixSymbol,
  setValue,
}: RangesliderProps) {
  const [minVal, setMinVal] = useState<number>(0);
  const [maxVal, setMaxVal] = useState<number>(0);
  const [formattedMinVal, setFormattedMinVal] = useState<string>('');
  const [formattedMaxVal, setFormattedMaxVal] = useState<string>('');
  const [formattedMinValue, setFormattedMinValue] = useState<string>('');
  const [formattedMaxValue, setFormattedMaxValue] = useState<string>('');
  const minRef = useRef<{ current: number } | number>({ current: 0 });
  const maxRef = useRef<{ current: number } | number>({ current: 0 });
  const range = useRef(null);

  const toPercent = useCallback(
    (val) => {
      return Math.round(((val - minValue) / (maxValue - minValue)) * 100);
    },
    [minValue, maxValue],
  );

  useEffect(() => {
    const minPercentValLeft = toPercent(minVal);
    const maxPercentValLeft = toPercent(maxRef.current);
    if (range.current) {
      range.current.style.left = `${minPercentValLeft}%`;
      range.current.style.width = `${maxPercentValLeft - minPercentValLeft}%`;
    }
  }, [minVal, toPercent]);

  useEffect(() => {
    const minPercentValRight = toPercent(minRef.current);
    const maxPercentValRight = toPercent(maxVal);
    if (range.current) {
      range.current.style.width = `${maxPercentValRight - minPercentValRight}%`;
    }
  }, [maxVal, toPercent]);

  function buildFormattedValue(val: number, state: string) {
    let formattedVal: string;

    if (currencyLocale) {
      formattedVal = formatValue({
        value: val.toString(),
        intlConfig: {
          locale: currencyLocale,
          currency: currencyCode,
        },
      });

      if (state === 'minVal') {
        setFormattedMinVal(formattedVal);
      }
      if (state === 'maxVal') {
        setFormattedMaxVal(formattedVal);
      }
      if (state === 'minValue') {
        setFormattedMinValue(formattedVal);
      }
      if (state === 'maxValue') {
        setFormattedMaxValue(formattedVal);
      }
    }

    if (!currencyLocale) {
      formattedVal = formatValue({
        value: val.toString(),
        groupSeparator: groupSeparator,
        decimalSeparator: decimalSeparator,
        prefix: prefixSymbol,
        suffix: suffixSymbol,
      });

      if (state === 'minVal') {
        setFormattedMinVal(formattedVal);
      }
      if (state === 'maxVal') {
        setFormattedMaxVal(formattedVal);
      }
      if (state === 'minValue') {
        setFormattedMinValue(formattedVal);
      }
      if (state === 'maxValue') {
        setFormattedMaxValue(formattedVal);
      }
    }
  }

  function handleMinRangeChange(e) {
    let changedMinValue: number = 0;

    if (sliderType === 'single') {
      changedMinValue = Math.min(Number(e.target.value), maxVal);
    }
    if (sliderType === 'range') {
      changedMinValue = Math.min(Number(e.target.value), maxVal - step);
    }

    setMinVal(changedMinValue);
    minRef.current = changedMinValue;
    buildFormattedValue(changedMinValue, 'minVal');
    setValue(changedMinValue);
  }

  function handleMaxRangeChange(e) {
    const changedMaxValue = Math.max(Number(e.target.value), minVal + step);
    setMaxVal(changedMaxValue);
    maxRef.current = changedMaxValue;
    buildFormattedValue(changedMaxValue, 'maxVal');
  }

  const setInitialPosition = (
    initialMin: number,
    initialMax: number,
    maxValue: number,
    sliderType: string,
    minValue: number,
  ) => {
    if (sliderType === 'single') {
      setMinVal(initialMin || maxValue * 0.5);
      minRef.current = initialMin || maxValue * 0.5;
      buildFormattedValue(initialMin || maxValue * 0.5, 'minVal');
      buildFormattedValue(minValue, 'minValue');
      setMaxVal(maxValue);
      maxRef.current = maxValue;
      buildFormattedValue(maxValue, 'maxVal');
      buildFormattedValue(maxValue, 'maxValue');
    }
    if (sliderType === 'range') {
      setMinVal(initialMin || maxValue * 0.3);
      minRef.current = initialMin || maxValue * 0.3;
      buildFormattedValue(initialMin || maxValue * 0.3, 'minVal');
      buildFormattedValue(minValue, 'minValue');
      setMaxVal(initialMax || maxValue * 0.7);
      maxRef.current = initialMax || maxValue * 0.7;
      buildFormattedValue(initialMax || maxValue * 0.7, 'maxVal');
      buildFormattedValue(maxValue, 'maxValue');
    }
  };
  useEffect(() => {
    setInitialPosition(value ? Number(value) : initialMin, initialMax, maxValue, sliderType, minValue);
  }, []);

  return (
    <Styled.RangeContainer
      disabled={disabled}
      isRangeValid={isRangeValid}
      configStyles={configStyles}
      className={className}
    >
      <Styled.CurrentValuesContainer disabled={disabled} isRangeValid={isRangeValid}>
        <Styled.MinCurrentValue>{formattedMinVal}</Styled.MinCurrentValue>
        {sliderType === 'range' ? <Styled.MaxCurrentValue>{formattedMaxVal}</Styled.MaxCurrentValue> : null}
      </Styled.CurrentValuesContainer>
      <Styled.InputRangeMin
        id={id}
        name={name}
        min={minValue}
        max={maxValue}
        step={step}
        value={minVal}
        onChange={handleMinRangeChange}
        disabled={disabled}
      />
      {sliderType === 'range' ? (
        <Styled.InputRangeMax
          id={id}
          name={name}
          min={minValue}
          max={maxValue}
          step={step}
          value={maxVal}
          onChange={handleMaxRangeChange}
          disabled={disabled}
        />
      ) : null}
      <Styled.Slider>
        <Styled.Track sliderType={sliderType} disabled={disabled} isRangeValid={isRangeValid} />
        <Styled.Range ref={range} sliderType={sliderType} disabled={disabled} isRangeValid={isRangeValid} />
        <Styled.MinValue>{formattedMinValue}</Styled.MinValue>
        <Styled.MaxValue>{formattedMaxValue}</Styled.MaxValue>
      </Styled.Slider>
    </Styled.RangeContainer>
  );
}
