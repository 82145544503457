import { useState } from 'react';

// API
import { OneAppAPI } from 'Infrastructure/API/Configs/OneApp.api';

type File = {
  id: string;
  name: string;
  size: string;
  src: string;
};

type FetchFile = {
  files: File[] | null;
  error: string | null;
  loading: boolean;
};

const useFile = () => {
  const [fetch, setFetch] = useState<FetchFile>({
    files: null,
    error: null,
    loading: false,
  });

  const upload = async (fileList: FileList) => {
    setFetch((fetch) => ({ ...fetch, files: null, error: null }));
    if (!fileList.length) return;

    try {
      setFetch((fetch) => ({ ...fetch, loading: true }));
      const formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        formData.append('files', fileList[i]);
      }
      const data = await OneAppAPI.postFile(formData);
      setFetch((fetch) => ({ ...fetch, files: data }));
    } catch (err: any) {
      setFetch((fetch) => ({ ...fetch, error: err?.status || 'error' }));
    } finally {
      setFetch((fetch) => ({ ...fetch, loading: false }));
    }
  };

  return { upload, ...fetch };
};

export default useFile;
