import React, { useState, useEffect } from 'react';
import * as Styled from './countdown.styled';

export type CountdownProps = {
  /**
   * class names override
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * hours value
   */
  hours?: number | undefined;

  /**
   * component Id
   */
  id?: string;

  /**
   * minutes value
   */
  minutes?: number | undefined;

  /**
   * seconds value
   */
  seconds?: number | undefined;

  /**
   * separator indicator
   */
  separator?: string;
};

function getTime(futureTime) {
  const remainingTime = Date.parse(futureTime) - Date.now();
  return {
    hrs: Math.floor((remainingTime / (1000 * 60 * 60)) % 24),
    mins: Math.floor((remainingTime / 1000 / 60) % 60),
    secs: Math.floor((remainingTime / 1000) % 60),
  };
}

export function Countdown({
  className,
  configStyles,
  hours,
  id,
  minutes,
  seconds = 3,
  separator = ':',
}: CountdownProps) {
  const [timeLeft, setTimeLeft] = useState({
    hrs: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    setTimeLeft({
      hrs: hours || 0,
      mins: minutes || 0,
      secs: seconds || 0,
    });

    const duration = new Date();
    duration.setHours(
      duration.getHours() + (hours || 0),
      duration.getMinutes() + (minutes || 0),
      duration.getSeconds() + (seconds || 0) + 1, // This componsates the start of the interval
    );

    const tick = setInterval(() => {
      const updatedCounter = getTime(duration);
      setTimeLeft({
        hrs: updatedCounter.hrs,
        mins: updatedCounter.mins,
        secs: updatedCounter.secs,
      });
      if (updatedCounter.hrs === 0 && updatedCounter.mins === 0 && updatedCounter.secs === 0) clearInterval(tick);
    }, 1000);

    return () => {
      clearInterval(tick);
    };
  }, []);

  return (
    <Styled.Countdown className={className} configStyles={configStyles} id={id}>
      {hours !== undefined && `${timeLeft.hrs.toString().padStart(2, '0')}${separator}`}
      {timeLeft.mins.toString().padStart(2, '0')}
      {separator}
      {timeLeft.secs.toString().padStart(2, '0')}
    </Styled.Countdown>
  );
}
