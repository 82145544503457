import styled from 'styled-components';

const Dotloader = styled.div<{ state: 'default' | 'disabled'; configStyles: string | undefined }>`
  border-radius: 50%;
  height: 0.4rem;
  width: 0.4rem;
  background: ${(props) => props.theme.colors.white};
  position: relative;
  animation: loader 1000ms infinite;
  animation-delay: 100ms;
  animation-timing-function: step-end;

  &:after,
  &:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    height: 0.4rem;
    width: 0.4rem;
  }

  &:before {
    left: -1rem;
    animation: loader 1000ms infinite;
    animation-timing-function: step-end;
  }

  &:after {
    left: 1rem;
    animation: loader 1000ms infinite;
    animation-delay: 200ms;
    animation-timing-function: step-end;
  }

  @keyframes loader {
    0% {
      background: ${(props) => props.theme.colors.white};
    }
    50% {
      background: ${(props) =>
        props.state === 'default' ? props.theme.colors.primary2 : props.theme.colors.darkGray1};
    }
  }
  ${(props) => props.configStyles};
`;

export { Dotloader };
