// Actions
import { message, modal, toggleProperty } from './dialogs';
import { navigate, redirect } from './navigation';
import { scrollToTop, focusOnElement, focusOnFieldError } from './documentInteractions';

export type ExecutableActionsType = [
  {
    action: string;
    params?: string;
  },
];

const execute = (actionsConfiguration: any, ...args) => {
  actionsConfiguration.forEach(({ action, params = '' }: { action: string; params?: string }) => {
    Actions[action](params, ...args);
  });
};

const Actions: { [key: string]: any } = Object.freeze({
  navigate,
  redirect,
  modal,
  toggleProperty,
  message,
  scrollToTop,
  focusOnElement,
  focusOnFieldError,
});

export { execute, Actions };
