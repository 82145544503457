import React, {useState, useEffect} from 'react';
import moment from 'moment';
import 'moment/locale/en-in';
import * as Styled from './staticcalendar.styled';

export type StaticcalendarProps = {
  /**
   * To select the calendar type to display
   */
  calendarType?: 'weekly' | 'monthly';

  /**
   * For external libraries that implement icons as className
   */
  chevronIconClassName?: string;

  /**
   * Class names overrides
   */
  className?: string;

  /**
   * For component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * To set the first day of the week considering: Sunday is 0 and Saturday is 6
   */
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * To identify the Staticcalendar component
   */
  id?: string;

  /**
   * Sets the month format that will be visible in the Calendar header.  
   * 'MMMM YYYY':  October 2022  
   * 'MMM YYYY': Oct 2022  
   * It is also possible to add symbols to the format.
   * 'MMM - YYYY'
   */
  monthFormat?: string;

  /**
   * Sets the weekdays format.  
   * 'dddd':  Monday  
   * 'ddd': Mon
   * 'dd': Mo 
   */
  weekdaysFormat?: string;
};

export function Staticcalendar({
  calendarType = "weekly",
  chevronIconClassName = "zmdi zmdi-chevron-left zmdi-hc-2x",
  className,
  configStyles,
  firstDayOfWeek = 0,
  id,
  monthFormat = "MMMM YYYY",
  weekdaysFormat = "ddd"
}: StaticcalendarProps) {
  
  const [visibleMonth, setVisibleMonth] = useState<number>(null);
  const [monthdays, setMonthdays] = useState<moment.Moment[][]>([]);
  const [weekdays, setWeekdays] = useState<moment.Moment[]>([]);
  const [dayNames, setDayNames] = useState<moment.Moment[]>([]);
  const [weeklyDisplay, setWeeklyDisplay] = useState<moment.Moment>(null);

  moment.updateLocale(navigator.language.slice(0,2) || 'en', { week: {
    dow: firstDayOfWeek
  }
  });

  useEffect(() => {
    if(calendarType === 'monthly') {
      const today = moment().month()
      setVisibleMonth(today);
      buildMonthdays(today);
    } else {
      setWeekdays(buildWeekdays());
    }
  }, []);

  useEffect(() => {
    updateWeeklyHeader();
  }, [weekdays])

  const buildWeekdays = () => {
    var weekdays = [];
    const firstDayOfWeek = moment().startOf('week');
    const lastDayOfWeek = moment().endOf('week');
    let weekday = firstDayOfWeek;

    while(weekday.isSameOrBefore(lastDayOfWeek)){
      weekdays.push(moment(weekday));
      weekday.add(1, 'day');
    }

    return weekdays;
  };

  const buildMonthdays = (relatedMonth) => {
    const monthWeeks = [];

    const startDay = moment().clone().month(relatedMonth).startOf('month').startOf('week');
    const endDay = moment().clone().month(relatedMonth).endOf('month').endOf('week');
    const day = startDay.clone().subtract(1, 'day');

    while(day.isBefore(endDay, 'day')) {
      monthWeeks.push(
        Array(7).fill(0).map(() => day.add(1, 'day').clone())
      )
    }
    setMonthdays(monthWeeks);
    setDayNames(monthWeeks[0])
  };

  const handlePrevButton = () => {
    if(calendarType === 'monthly') {
      setVisibleMonth(visibleMonth - 1);
      buildMonthdays(visibleMonth - 1);
    } else {
      setWeekdays(weekdays.map(weekday => moment(weekday).subtract(7, 'days')));
    }
  };

  const handleNextButton = () => { 
    if(calendarType === 'monthly') {
      setVisibleMonth(visibleMonth + 1);
      buildMonthdays(visibleMonth + 1);
    } else {
      setWeekdays(weekdays.map(weekday => moment(weekday).add(7, 'days')));
    }
  };

  const updateWeeklyHeader = () => {
    if(calendarType === 'weekly') {
      const found = weekdays.find(day => moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"));
      if(found === undefined) {
        setWeeklyDisplay(weekdays[0]);
      } else {
        setWeeklyDisplay(found);
      }
    }
  };
  
  return (
    <Styled.StaticCalendar className={className} configStyles={configStyles} id={id}>
      <Styled.CalendarHader>
        <Styled.HeaderTitle>
          <Styled.Title>
            {
              calendarType === 'monthly'
              ? dayNames[6]?.format(monthFormat)
              : moment(weeklyDisplay).format(monthFormat)
            }
          </Styled.Title>
        </Styled.HeaderTitle>
        <Styled.NavButtonPrev onClick={handlePrevButton}>
          <Styled.NavButtonIcon className={chevronIconClassName}/>
        </Styled.NavButtonPrev>
        <Styled.NavButtonNext onClick={handleNextButton}>
          <Styled.NavButtonIcon className={chevronIconClassName}/>
        </Styled.NavButtonNext>
      </Styled.CalendarHader>
      <Styled.CalendarMain>
        {
          calendarType === 'monthly' ? 
            <>
            <Styled.MonthweekDays key={`name-of-days`}>
                {
                  dayNames.map((weekday) => (
                    <Styled.MonthweekText key={`${weekday.format('ddd')}-calendar-header`}>
                      {
                        weekday.format(weekdaysFormat)
                      }
                    </Styled.MonthweekText>
                  ))
                }
            </Styled.MonthweekDays>
            <Styled.CalendarMonthweeks>
              {
                monthdays.map((week, index) => (
                  <Styled.Monthweek key={`week-${index}`}>
                    {
                      week.map((day) => {
                        if(moment(day).isBefore(moment().month(visibleMonth).startOf('month')) || moment(day).isAfter(moment().month(visibleMonth).endOf('month'))) {
                          return (
                            <Styled.MonthDay isOutOfRange={true} key={`day-${day.format("dd-MM")}`}>{day.date()}</Styled.MonthDay>
                          )
                        }
                        if(day.format('DD-MM-YY') === moment().format('DD-MM-YY')) {
                          return (
                            <Styled.MonthDay isToday={true} key={`day-${day.format("dd-MM")}`}>{day.date()}</Styled.MonthDay>
                          )
                        } else {
                          return (
                            <Styled.MonthDay key={`day-${day.format("dd-MM")}`}>{day.date()}</Styled.MonthDay>
                          )
                        }
                      })
                    }
                  </Styled.Monthweek>
                ))
              }
            </Styled.CalendarMonthweeks>
            </>
          :
            <Styled.CalendarWeekdays>
              {
                weekdays.map((weekday) => {
                  if(weekday.format('DD-MM-YY') === moment().format('DD-MM-YY')) {
                    return (
                      <Styled.WeekdayPill isToday={true} key={`weekday-${weekday.format("dd-MM")}`}>
                        <Styled.Weekday>{weekday.format(weekdaysFormat)}</Styled.Weekday>
                        <Styled.WeekdayDate>{weekday.date()}</Styled.WeekdayDate>
                      </Styled.WeekdayPill>
                    );
                  } else {
                    return (
                      <Styled.WeekdayPill key={`weekday-${weekday.format("dd-MM")}`}>
                        <Styled.Weekday>{weekday.format(weekdaysFormat)}</Styled.Weekday>
                        <Styled.WeekdayDate>{weekday.date()}</Styled.WeekdayDate>
                      </Styled.WeekdayPill>
                    );
                  }
                })
              }
            </Styled.CalendarWeekdays>
        }
      </Styled.CalendarMain>
    </Styled.StaticCalendar>
  );
}