import React, { useState, useEffect, useRef } from 'react';
import * as Styled from './maskeddigitinput.styled';

export type MaskeddigitinputProps = {
  /**
   * it defines the length of the code and therefore the number of digit inputs
   */
  codeLength?: number;

  /**
   * to disable the digit inputs
   */
  disabledProp?: boolean;

  /**
   * an ID to identify the set of digit inputs
   */
  id: string;

  /**
   * determines if the OTP code is valid or not
   */
  isCodeValid?: boolean;

  /**
   * defines if the input should be masked or not
   */
  isMasked?: boolean;

  /**
   * a name assigned to the set of digit inputs
   */
  name: string;

  /**
   * an onchange handler function to serve as value
   */
  onChangeHandler: (value: string) => void;

  /**
   * to be triggered when the code input failure gets focused
   */
  onFocusHandler?: () => void;

  /**
   * an onchange handler function to serve as value
   */
  onPaste?: (value: string) => void;
};

export function Maskeddigitinput({
  id,
  name,
  codeLength = 0,
  isCodeValid = undefined,
  disabledProp,
  onFocusHandler,
  onChangeHandler,
  onPaste,
  isMasked,
}: MaskeddigitinputProps) {
  const [isInputMasked, setIsInputMasked] = useState(true);
  const [arrayCode, setArrayCode] = useState([...Array(codeLength)].map(() => ''));
  const inputRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    isCodeValid && setArrayCode(new Array(codeLength).fill(0));
    inputRef.current.map((inputItem) => {
      inputItem.addEventListener('paste', (e) => {
        const codeClipped = e.clipboardData.getData('text');
        if (/[^0-9]/.test(codeClipped)) return;
        setArrayCode([...codeClipped]);
        onPaste(codeClipped);
        inputItem.blur();
      });
    });
  }, []);

  const handleCodeInput = (e: any, slot: number) => {
    if (/[^0-9]/.test(e.target.value)) return;
    const newCode = [...arrayCode];
    if (!arrayCode[slot]) {
      isMasked && setIsInputMasked(true);
    }
    newCode[slot] = e.target.value;
    setArrayCode(newCode);
    onChangeHandler(newCode.join(''));
    if (!arrayCode[slot]) {
      if (slot !== codeLength - 1) {
        inputRef.current[slot + 1].focus();
      } else {
        if (e.target.value !== '') {
          inputRef.current[slot].blur();
        }
      }
    }
  };

  const onKeyDown = (e: any, slot: number) => {
    const newCode = [...arrayCode];
    if (e.key === 'Backspace') {
      isMasked && setIsInputMasked(false);
      if (arrayCode[slot]) {
        inputRef.current[slot].focus();
      } else if (!arrayCode[slot] && slot !== 0) {
        newCode[slot - 1] = '';
        setArrayCode(newCode);
        inputRef.current[slot - 1].focus();
      }
    } else {
      if (!arrayCode[slot]) {
        isMasked && setIsInputMasked(true);
      }
    }
  };

  const setInputType = () => {
    if (isCodeValid) {
      return 'password';
    } else {
      if (isInputMasked && isMasked) {
        return 'password';
      } else {
        return 'text';
      }
    }
  };

  return (
    <Styled.MaskedDigitInput isCodeValid={isCodeValid}>
      {arrayCode.map((digit, index) => {
        return (
          <input
            title={`inputMasked${index}`}
            id={`${id}-${index}`}
            name={`${name}-${index}`}
            key={`inputCodeKey-${index}`}
            type={setInputType()}
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={1}
            onChange={(e) => handleCodeInput(e, index)}
            onKeyDown={(e) => onKeyDown(e, index)}
            value={digit}
            ref={(ref) => inputRef.current.push(ref!)}
            onFocus={(e) => {
              if (e.target.value) {
                isMasked && setIsInputMasked(false);
              } else {
                isMasked && setIsInputMasked(true);
              }

              if (isCodeValid === false) {
                onFocusHandler();
                setArrayCode([...Array(codeLength)].map(() => ''));
                inputRef.current[0].focus();
              }
            }}
            onBlur={() => isMasked && setIsInputMasked(true)}
            autoComplete="off"
            disabled={disabledProp || isCodeValid}
          />
        );
      })}
    </Styled.MaskedDigitInput>
  );
}
