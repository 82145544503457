import styled from 'styled-components';

export const Toggle = styled.div<{
  disabled: boolean;
  checked: boolean | undefined;
  validationStatus: boolean | undefined;
}>`
  display: flex;
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacings.xsm || '0.5rem'};
  div {
    margin-bottom: 0;
  }

  ${(props) => {
    if (props.checked) {
      return `      
      ${ToggleSwitch}
      {
        justify-content: flex-end;
        background-color: ${props.theme.colors.primary2};
        &:after {
          background-color: ${props.theme.colors.white};
          border: 2px solid ${props.theme.colors.white};
        }
      }`;
    }
  }}

  ${(props) => {
    if (props.validationStatus === false) {
      return `
      ${ToggleSwitch}
      {
        background-color: ${props.checked ? props.theme.colors.negative2 : props.theme.colors.negative4};
        &:after{
          border: 2px solid ${props.theme.colors.white};
          background-color: ${props.theme.colors.white};
        }
      }`;
    }
  }}

  ${(props) => {
    if (props.disabled) {
      return `
      ${ToggleLabel}{
        ${(props) => props.disabled && `color: ${props.theme.colors.darkGray4 || '#E4E8ED'};`}
      };

      ${ToggleSwitch}{
        outline: ${props.theme.colors.darkGray4 || '#E4E8ED'};
        &:checked {
          background-color: ${(props) => props.theme.colors.darkGray3 || '#004DAD'};           
        }
      }
      `;
    }
  }}
`;

export const ToggleSwitch = styled.span`
   {
    border: none;
    border-radius: 1rem;
    padding: 2px;
    position: relative;
    margin: 0;
    margin-right: ${(props) => props.theme.spacings.xsm || '0.5rem'};
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 40px;
    background-color: ${(props) => props.theme.colors.darkGray4 || '#E4E8ED'};
    cursor: pointer;
    outline: none;

    &:after {
      content: '';
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      background-color: ${(props) => props.theme.colors.white || '#ffffff'};
      border: 2px solid ${(props) => props.theme.colors.darkGray3 || '##004DAD'};
    }

    &:checked {
      background-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
      &:after {
        left: 20px;
        margin-bottom: 0;
        border: none;
      }
    }
  }
`;

export const ToggleLabel = styled.div`
  flex: 1;
  cursor: pointer;
`;
