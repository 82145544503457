import styled from 'styled-components';

export const MaskedDigitInput = styled.div<{ isCodeValid: boolean | undefined }>`
  display: flex;
  > input[maxLength='1'][type='password'],
  > input[maxLength='1'][type='text'] {
    border-radius: ${(props) => props.theme.radius.sm};
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray2};
    display: block;
    font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
    outline: 0;
    box-sizing: content-box;
    padding: ${(props) => props.theme.spacings.default || '1rem'} ${(props) => props.theme.spacings.default || '1rem'};
    width: ${(props) => props.theme.spacings.default || '1rem'};
    height: ${(props) => props.theme.spacings.default || '1rem'};
    text-align: center;
    font-size: 1rem;
    background-color: ${(props) => props.theme.colors.lightGray1 || '#F0F4FA'};

    &:focus {
      background-color: ${(props) => props.theme.colors.white || 'white'};
      border: ${(props) =>
        `${props.theme.borders.normal || '0.0625rem'} solid ${props.theme.colors.primary1 || '#0279FE'}`};
    }
    ${(props) => {
      if (props.isCodeValid === true) {
        return `
        border-color: ${props.theme.colors.accent2 || '#61D3A4'};
        &:disabled{
            color: black;
            background-color: ${props.theme.colors.lightAccent2 || '#B9F8DE'};
            
        }`;
      } else if (props.isCodeValid === false) {
        return `background-color: ${props.theme.colors.lightAccent1 || '#F6C4CE'};
        border-color: ${props.theme.colors.accent1 || '#DF5672'};`;
      }
    }}
  }
  > input + input {
    margin-left: ${(props) => props.theme.spacings.sm || '0.75rem'};
  }
`;
