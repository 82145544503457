import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import Styled from 'styled-components';

import { Card } from '@pefai/scl-react.components.actionable.cards.card';
import { Container } from '@pefai/scl-react.components.layouts.containers.container';
import { Parragraph } from '@pefai/scl-react.components.typography.parragraph';
import { Icon } from '@pefai/scl-react.components.actionable.icons.icon';
import { Dotloader } from '@pefai/scl-react.components.informational.loaders.dotloader';

// Hooks
import { useScript } from 'Application/hooks';

// Facetec init
import config from './config';
import setup from './setup';

export default function FaceTec({
  BaseURL,
  captureMessage,
  DeviceKeyIdentifier,
  errorMessage,
  errorGeolocation,
  externalDatabaseRefid,
  loadingMessage,
  mode = undefined,
  name,
  ProductionKey,
  PublicFaceScanEncryptionKey,
  setValue,
  successMessage,
  value = {
    biometric: null,
    geolocation: {
      lat: null,
      lng: null,
    },
  },
  requireGeoLocation = false,
}) {
  // Scripts: FaceTec - SDK
  useScript({ src: '/facetec/FaceTecStrings.es.js' });
  useScript({ src: '/facetec/core-sdk/FaceTecSDK.js/FaceTecSDK.js' });
  // Scripts: FaceTec - Default demo processors
  // ToDo: Build custom processors (API)
  useScript({ src: '/facetec/processors/LivenessCheckProcessor.js' });
  useScript({ src: '/facetec/processors/PhotoIDMatchProcessor.js' });
  useScript({ src: '/facetec/processors/AuthenticateProcessor.js' });
  useScript({ src: '/facetec/processors/EnrollmentProcessor.js' });

  useScript({ src: '/facetec/processors/Authenticate3d2dProcessor.js' });
  useScript({ src: '/facetec/processors/Authenticate2d2dProcessor.js' });
  useScript({ src: '/facetec/processors/PhotoIDScanProcessor.js' });
  // init, errorBiometric, errorGeolocation, success
  const [facetecStep, setFacetecStep] = useState(() => {
    if (value.biometric === true) {
      return 'success';
    } else {
      return 'init';
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const secondTryFailsafe = useRef(0);
  // ToDo: This is a temporary fix due to bug unknown on facetec window bound object
  const FacetecTrigger = useRef(null);
  const geolocation = useRef('');

  const initFacetec = (mode) => {
    window.FaceTecSDK.setResourceDirectory('/facetec/core-sdk/FaceTecSDK.js/resources');
    window.FaceTecSDK.setImagesDirectory('/facetec/core-sdk/FaceTec_images');
    window.FaceTecSDK.setCustomization(window.Config.retrieveConfigurationWizardCustomization(window.FaceTecSDK));

    if (!mode) {
      throw new Error('Application: Mode is undefined');
    }

    switch (mode) {
      case 'authenticate':
        window.Config.initializeFromAutogeneratedConfig(window.FaceTecSDK, () => authenticationMode());
        break;
      case 'enrollement':
        window.Config.initializeFromAutogeneratedConfig(window.FaceTecSDK, () => enrollmentMode());
        break;
      case 'documents':
        window.Config.initializeFromAutogeneratedConfig(window.FaceTecSDK, () => photoIDCheckMode());
        break;
      case 'liveness':
        window.Config.initializeFromAutogeneratedConfig(window.FaceTecSDK, () => livenessCheckMode());
        break;
      default:
        throw new Error('Application: Mode not found');
    }
  };

  // Perform 3D to 3D Authentication against the Enrollment previously performed.
  function authenticationMode() {
    // Get a Session Token from the FaceTec SDK, then start the 3D to 3D Matching.
    window.Setup.setLatestServerResult(null);

    window.Setup.getSessionToken(function (sessionToken) {
      window.Setup.latestEnrollmentIdentifier = externalDatabaseRefid;
      window.Setup.latestProcessor = new window.AuthenticateProcessor(sessionToken, window.Setup);
    });
  }

  // Initiate a 3D Liveness Check, then storing the 3D FaceMap in the Database, also known as "Enrollment".  A random enrollmentIdentifier is generated each time to guarantee uniqueness.
  function enrollmentMode() {
    window.Setup.setLatestServerResult(null);

    window.Setup.getSessionToken(function (sessionToken) {
      window.Setup.latestEnrollmentIdentifier = externalDatabaseRefid;
      window.Setup.latestProcessor = new window.EnrollmentProcessor(sessionToken, window.Setup);
    });
  }

  // Initiate a 3D Liveness Check.
  const livenessCheckMode = () => {
    window.Setup.setLatestServerResult(null);

    window.Setup.getSessionToken(function (sessionToken) {
      window.Setup.latestProcessor = new window.LivenessCheckProcessor(sessionToken, window.Setup);
    });
  };

  // Perform 3D to 3D Authentication against the Enrollment previously performed.
  const photoIDCheckMode = async () => {
    window.Setup.setLatestServerResult(null);

    var XHR = new XMLHttpRequest();
    XHR.open('GET', window.Config.BaseURL + '/enrollment' + "?externalDatabaseRefID=" + externalDatabaseRefid);
    XHR.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        try {
          // Attempt to get the isActive value from the response object.

          var response = JSON.parse(this.responseText);
          var responseIsActive = response.isActive;

          if (responseIsActive) {
            window.Setup.getSessionToken(function (sessionToken) {
              window.Setup.latestEnrollmentIdentifier = externalDatabaseRefid;
              window.Setup.latestProcessor = new window.PhotoIDScanProcessor(sessionToken, window.Setup);
            });
          }
          else {
            window.Setup.getSessionToken(function (sessionToken) {
              window.Setup.latestEnrollmentIdentifier = externalDatabaseRefid;
              window.Setup.latestProcessor = new window.PhotoIDMatchProcessor(sessionToken, window.Setup);
            });
          }


        } catch (_a) {
          // Something went wrong in parsing the response. Return an error.
          console.log('Server error: Previous enrollment verification:2'); // Manage server error
        }
        console.log('Hide loading (session token process)'); // SampleAppUtilities.hideLoadingSessionToken();
      }
    };

    // Wait for the request to be completed and proceed with the next processor.
    window.setTimeout(function () {
      if (XHR.readyState !== XMLHttpRequest.DONE) {
        console.log('Loading... (verifying previous enrollment)'); // SampleAppUtilities.showLoadingSessionToken();
      }
    }, 3000);
    XHR.onerror = function () {
      console.log('Server error: Previous enrollment verification'); // Manage server error
    };
    XHR.send();

  };

  // Sample implementation
  useEffect(() => {
    if (facetecStep === 'init') {
      setValue('');
    }

    window.Config = config(DeviceKeyIdentifier, BaseURL, PublicFaceScanEncryptionKey, ProductionKey);
    window.Setup = setup((complete) => {
      if (complete) {
        setValue({
          geolocation: geolocation.current,
          biometric: complete ? true : '', // for validation purposes
        });
      } else {
        setValue('');
      }

      // Internal component step status
      setFacetecStep(complete ? 'success' : 'error');
      setIsLoading(false);
    });
  }, []);

  // ToDo: This is a temporary fix due to bug unknown on facetec window bound object
  useLayoutEffect(() => {
    if (secondTryFailsafe.current === 2) {
      setIsLoading(false);
      FacetecTrigger.current.click();
    }
  }, [isLoading]);

  function FacetecStatusMessage({ isLoadingState, status }) {
    return (
      <Parragraph fontSize={'1.125rem'} textAlign={'center'}>
        {isLoadingState
          ? loadingMessage
          : {
            success: successMessage,
            error: errorMessage,
            errorGeolocation: errorGeolocation,
            init: captureMessage,
          }[status]}
      </Parragraph>
    );
  }

  function FacetecIconStatus({ status }) {
    return {
      success: (
        <Icon
          className={'zmdi zmdi-check zmdi-hc-4x'}
          configStyles={{
            display: 'flex !important',
            'align-self': 'center',
            'align-items': 'center !important',
            'justify-content': 'center !important',
            width: '80px',
            height: '80px',
            'border-radius': '50%',
            background: '#F2F2F2',
            color: '#BAC5D4',
          }}
        />
      ),
      error: (
        <Icon
          className={'zmdi zmdi-close zmdi-hc-4x'}
          configStyles={{
            display: 'flex !important',
            'align-self': 'center',
            'align-items': 'center !important',
            'justify-content': 'center !important',
            width: '80px',
            height: '80px',
            'border-radius': '50%',
            background: '#F2F2F2',
            color: '#BAC5D4',
          }}
        />
      ),
      errorGeolocation: (
        <Icon
          className={'zmdi zmdi-close zmdi-hc-4x'}
          configStyles={{
            display: 'flex !important',
            'align-self': 'center',
            'align-items': 'center !important',
            'justify-content': 'center !important',
            width: '80px',
            height: '80px',
            'border-radius': '50%',
            background: '#F2F2F2',
            color: '#BAC5D4',
          }}
        />
      ),
      init: (
        <Icon
          className={'zmdi zmdi-camera zmdi-hc-4x'}
          configStyles={{
            display: 'flex !important',
            'align-self': 'center',
            'align-items': 'center !important',
            'justify-content': 'center !important',
            width: '80px',
            height: '80px',
            'border-radius': '50%',
            background: '#F2F2F2',
            color: '#BAC5D4',
          }}
        />
      ),
    }[status];
  }

  // ToDo: Migrate all this to a library component
  return (
    <Style.Facetec
      name={name}
      type={'button'}
      ref={FacetecTrigger}
      onClick={() => {
        if (!isLoading) {
          secondTryFailsafe.current = secondTryFailsafe.current + 1;
          setIsLoading(true);
          setFacetecStep('init');
          if (requireGeoLocation) {
            navigator.geolocation.getCurrentPosition(
              function (position) {
                geolocation.current = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                initFacetec(mode);
              },
              function (error) {
                // Internal component step status\
                setIsLoading(false);
                setFacetecStep('errorGeolocation');
              },
            );
          } else {
            setIsLoading(true);
            initFacetec(mode);
          }
        }
      }}
    >
      <Card configStyles={{ padding: '2rem 1rem' }}>
        <Container semanticType="div" contentOrientation={'vertical-centered'}>
          <FacetecIconStatus status={facetecStep} />
          <Style.Loader>
            {
              {
                true: <Dotloader />,
              }[isLoading]
            }
          </Style.Loader>
          <FacetecStatusMessage isLoadingState={isLoading} status={facetecStep} />
        </Container>
      </Card>
    </Style.Facetec>
  );
}

const Style = {
  Facetec: Styled.div`
  border: none;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  width: 100%;
  background: none;
  cursor: pointer;
  text-align: center;
`,
  Loader: Styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
  `,
};
