import React, { ReactNode, useState, useEffect, useRef, useCallback } from 'react';
import * as Styled from './carousel.styled';

export type CarouselProps = {
  /**
   * An array of elements to be treated as carousel slides
   */
  children: ReactNode[];

  /**
   * class names override
   */
  className?: string;

  /**
   * styling overrides
   */
  configStyles?: string;

  /**
   * When set to true the carousel rotates automatically
   */
  hasAutoRotation?: boolean;

  /**
   * Adds a slide picker to the carousel when true
   */
  hasSlidePicker?: boolean;

  /**
   * To identify the carousel
   */
  id: string;

  /**
   * The interval in milliseconds between one slide and the other when autoRotation is set to true
   */
  interval?: number;

  /**
   * Classname of the icon for the next slide button
   */
  nextSlideIcon?: string;

  /**
   * Classname of the icon for the previous slide button
   */
  previousSlideIcon?: string;

  /**
   * Duration of the slide transition
   */
  transitionDuration?: number;
};

export function Carousel({
  children,
  className,
  configStyles,
  hasAutoRotation = false,
  hasSlidePicker = false,
  interval = 3000,
  transitionDuration = 800,
  nextSlideIcon,
  previousSlideIcon,
  id,
}: CarouselProps) {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const moveSlidesRef = useRef<HTMLDivElement | null>(null);
  const slideIntervalTimeRef: { current: NodeJS.Timeout | null } = useRef(null);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const getSlideIndex = useCallback(
    (controlType: 'next' | 'previous', currentIndex: number) => {
      switch (controlType) {
        case 'next':
          if (currentIndex >= children.length - 1) {
            return 0;
          } else {
            return currentIndex + 1;
          }

        case 'previous':
          if (currentIndex <= 0) {
            return children.length - 1;
          } else {
            return currentIndex - 1;
          }
      }
    },
    [children],
  );

  const nextSlideClickHandler = useCallback(() => {
    setTimeout(() => {
      if (moveSlidesRef.current) {
        moveSlidesRef.current.style.transition = `${transitionDuration}ms ease-out all`;
        moveSlidesRef.current.style.transform = `translateX(0)`;
      }
    }, 0);

    if (moveSlidesRef.current && moveSlidesRef.current.children[0].children[0] instanceof HTMLElement) {
      moveSlidesRef.current.style.transition = 'none';
      const slideSize = moveSlidesRef.current.children[0].children[0].offsetWidth;
      moveSlidesRef.current.style.transform = `translateX(${slideSize}px)`;
    }
    setCurrentSlide((current) => getSlideIndex('next', current));
  }, [getSlideIndex, transitionDuration]);

  const previousSlideClickHandler = useCallback(() => {
    setTimeout(() => {
      if (moveSlidesRef.current) {
        moveSlidesRef.current.style.transition = `${transitionDuration}ms ease-out all`;
        moveSlidesRef.current.style.transform = `translateX(0)`;
      }
    }, 0);

    if (moveSlidesRef.current && moveSlidesRef.current.children[0].children[0] instanceof HTMLElement) {
      moveSlidesRef.current.style.transition = 'none';
      const slideSize = moveSlidesRef.current.children[0].children[0].offsetWidth;
      moveSlidesRef.current.style.transform = `translateX(-${slideSize}px)`;
    }

    setCurrentSlide((current) => getSlideIndex('previous', current));
  }, [getSlideIndex, transitionDuration]);

  useEffect(() => {
    const rotate = () => {
      slideIntervalTimeRef.current = setInterval(() => {
        nextSlideClickHandler();
      }, interval);
    };

    if (hasAutoRotation && carouselRef.current) {
      rotate();

      carouselRef.current.addEventListener('mouseenter', () => {
        clearInterval(slideIntervalTimeRef.current as NodeJS.Timeout);
        if (carouselRef.current) {
          carouselRef.current.removeEventListener('focusout', rotate);
          carouselRef.current.addEventListener('mouseleave', rotate);
        }
      });

      carouselRef.current.addEventListener('focusin', () => {
        clearInterval(slideIntervalTimeRef.current as NodeJS.Timeout);
        if (carouselRef.current) {
          carouselRef.current.removeEventListener('mouseleave', rotate);
          carouselRef.current.addEventListener('focusout', rotate);
        }
      });
    }

    return () => {
      clearInterval(slideIntervalTimeRef.current as NodeJS.Timeout);
    };
  }, [nextSlideClickHandler, hasAutoRotation, interval]);

  return (
    <Styled.Carousel
      id={id}
      role="region"
      aria-roledescription="carousel"
      ref={carouselRef}
      configStyles={configStyles}
      className={className}
    >
      <Styled.CenterControls>
        <Styled.CarouselWindow>
          <Styled.Slider ref={moveSlidesRef}>
            {children.map((slide, index) => {
              if (index === currentSlide) {
                return (
                  <Styled.SlidesSet key={`slideset${index}`}>
                    <Styled.SlideContainer>
                      <Styled.Slide>{children[getSlideIndex('previous', index)]}</Styled.Slide>
                    </Styled.SlideContainer>

                    <Styled.SlideContainer>
                      <Styled.Slide role={hasSlidePicker ? 'tabpanel' : 'group'} aria-roledescription="slide">
                        {slide}
                      </Styled.Slide>
                    </Styled.SlideContainer>

                    <Styled.SlideContainer>
                      <Styled.Slide>{children[getSlideIndex('next', index)]}</Styled.Slide>
                    </Styled.SlideContainer>
                  </Styled.SlidesSet>
                );
              }
            })}
          </Styled.Slider>
        </Styled.CarouselWindow>

        <Styled.Control direction="previous" onClick={previousSlideClickHandler}>
          <Styled.IconControl className={previousSlideIcon} />
        </Styled.Control>

        <Styled.Control direction="next" onClick={nextSlideClickHandler}>
          <Styled.IconControl className={nextSlideIcon} />
        </Styled.Control>
      </Styled.CenterControls>
      {hasSlidePicker && (
        <Styled.SlidePickerControl role="tablist">
          {children.map((slide, index) => {
            return (
              <Styled.SlideOption
                role="tab"
                key={`slideoption${index}`}
                isActive={currentSlide === index}
                onClick={() => setCurrentSlide(index)}
              ></Styled.SlideOption>
            );
          })}
        </Styled.SlidePickerControl>
      )}
    </Styled.Carousel>
  );
}
