import styled from 'styled-components';

export const Carousel = styled.div<{configStyles:string | undefined}>`
  ${(props)=>props.configStyles}
`;
export const CarouselWindow = styled.div`
  min-width: 100%;
  overflow: hidden;
`;

export const CenterControls = styled.div`
  position: relative;
`;

export const Slider = styled.div``;
export const SlidesSet = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
`;

export const SlideContainer = styled.li`
  min-width: 100%;
`;

export const Slide = styled.div<{ current?: boolean | undefined }>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  aspect-ratio: 3/2;
`;

export const Control = styled.button<{ direction: 'previous' | 'next' }>`
  position: absolute;
  background-color: transparent;
  cursor: pointer;
  border: none;
  font-size: ${(props) => props.theme.typography.heading5 };
  color: ${(props) => props.theme.colors.darkGray2 };
  width: fit-content;
  height: fit-content;
  padding: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  ${(props) => {
    if (props.direction === 'next') {
      return `right:0rem;`;
    } else if (props.direction === 'previous') {
      return `left:0rem;`;
    }
  }}
  &:hover {
    color: ${(props) => props.theme.colors.white };
  }
  i {
    font-size: 3rem;
  }
`;
export const IconControl = styled.i`
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
`;

export const SlidePickerControl = styled.div`
  display: flex;
  justify-content: center;

  button + button {
    margin-left: ${(props) => props.theme.spacings.xsm};
  }
`;

export const SlideOption = styled.button<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive ? `${props.theme.colors.black }` : `${props.theme.colors.darkGray2 }`};
  cursor: pointer;
  border: none;
`;
