import styled from 'styled-components';

export const FileBrowser = styled.div<any>`
  border-style: solid;
  border-width: ${(props) => props.theme.borders.normal || '0.125rem'};
  border-color: ${(props) => props.theme.colors.darkGray2};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    border-color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray3 || '#BAC5D4' : props.theme.colors.primary2 || '#004dad'};
  }

  &:active {
    border-color: ${(props) => props.theme.colors.primary2 || '#004dad'};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.primary2 || '#004dad'};
  }
`;

export const Label = styled.label<{ htmlFor: string; disabled?: boolean }>`
  display: flex;
  contain: inline-size;
  cursor: pointer;
`;

export const Input = styled.input.attrs({ type: 'file' })`
  width: 0;
  height: 0;
  position: absolute;
  &:disabled + p {
    border-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
    background-color: ${(props) => props.theme.colors.lightGray2 || '#F7F9FC'};
  }

  &:disabled + p + span {
    background-color: ${(props) => props.theme.colors.grayDark2 || '#9BABC1'};
    color: ${(props) => props.theme.colors.white || '#FFF'};
  }
`;

export const Placeholder = styled.span<{ hasFiles: boolean }>`
  flex: 1;
  padding: ${(props) => props.theme.spacings.sm || '1rem'} ${(props) => props.theme.spacings.default || '1rem'};
  color: ${(props) => (props.hasFiles ? props.theme.colors.black : props.theme.colors.darkGray2)};
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Span = styled.span`
  padding: ${(props) => props.theme.spacings.sm || '1rem'} ${(props) => props.theme.spacings.md || '1.5rem'};
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.typography.base1};
  background: ${(props) => props.theme.colors.primary2 || '#004dad'};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
  text-align: center;
`;
