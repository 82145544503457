import React, { ReactNode, useEffect, useState } from 'react';

import * as Styled from './formfield.styled';

export type FormfieldProps = {
  /**
   * an input element
   */
  children: ReactNode | ReactNode[];

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * a function to clear validation status
   */
  clearValidation?: boolean | undefined;

  /**
   * property to detemine how child items should be ordered inside a form
   */
  contentOrder?: 'rows' | 'columns' | 'grid' | undefined;

  /**
   * @contentOrder - if set to grid, this property sets the layout for it
   */
  contentOrderGrid?:
    | {
        rows: number | undefined;
        columns: number | undefined;
      }
    | undefined;

  /**
   * variants for the input
   */
  disabled?: boolean;

  /**
   * a text to help the user about the input itself
   */
  helpText?: ReactNode;

  /**
   * id - for label/input
   */
  id: string;

  /**
   * a string to be rendered as a label
   */
  label?: ReactNode;

  /**
   * based on Formik
   * meta object to handle field additional descriptive data
   *  - error
   *  - touched
   *  - value
   */
  meta?: any;

  /**
   * a message about the input state
   */
  msg?: ReactNode;

  /**
   * Msg icon as any child
   */
  msgIcon?: ReactNode;

  /**
   * field name to be attached to the component
   */
  name?: string;

  /**
   * onBlur event
   */
  onBlur?: any;

  /**
   * onChange event
   */
  onChange?: any;

  /**
   * field value
   */
  value?: string;
  /**
   * function to handle update parent errors
   */
  setError?: any;

  /**
   * function to handle updates on parent touched field status
   */
  setTouched?: any;

  /**
   * function to update parent's value
   */
  setValue?: any;
};

export function Formfield({
  children,
  className,
  clearValidation = undefined,
  contentOrder = undefined,
  contentOrderGrid,
  disabled = false,
  helpText,
  id,
  label,
  meta = {},
  msg,
  msgIcon,
  name,
}: FormfieldProps) {
  const [isFieldValid, setfieldValidationStatus] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const { value, error, touched } = meta;
    if (name || meta) {
      if (touched && value && !error) {
        // ... if input has been touched, HAS value, and has PASSED validation : input and blur
        setfieldValidationStatus(true);
      } else if (touched && error) {
        // ... if input has been touched and has FAILED validation : blur
        setfieldValidationStatus(false);
      } else if (clearValidation === true) {
        setfieldValidationStatus(undefined);
      }
    }
  }, [meta]);

  return (
    <Styled.FormField className={className} disabled={disabled} validationStatus={isFieldValid}>
      {label && <Styled.FormFieldLabel htmlFor={id}>{label}</Styled.FormFieldLabel>}
      <Styled.FormFields contentOrder={contentOrder} contentOrderGrid={contentOrderGrid}>
        {children}
      </Styled.FormFields>
      {msg && <Styled.FormFieldHelpTxt>{msg}</Styled.FormFieldHelpTxt>}
      {helpText && <Styled.FormFieldHelpTxt>{helpText}</Styled.FormFieldHelpTxt>}
      {isFieldValid === false && meta.touched && <Styled.FormFieldMsg>{meta.error}</Styled.FormFieldMsg>}
    </Styled.FormField>
  );
}
