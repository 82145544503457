import styled from 'styled-components';

const Pill = styled.span<{
  checked: any;
  disabled: boolean | undefined;
  formState: any;
  name: string | undefined;
}>`
  display: block;
  border: 0.0625rem solid transparent;
  border-radius: 4rem;
  padding: ${(props) => `${props.theme.spacings.sm || '0.75rem'} ${props.theme.spacings.default || '1rem'}`};
  min-width: 5rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.tertiary4};
  color: ${(props) => props.theme.colors.black};
  font-weight: normal;
  font-size: ${(props) => props.theme.typography.base1};
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.ligthGray1 || '#F0F4FA' : props.theme.colors.tertiary2 || '#D6EEFF'};
    color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray1 || '#778AA5' : props.theme.colors.black || '#000000'};
  }

  &:active {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.ligthGray1 || '#F0F4FA' : props.theme.colors.primary2 || '#004DAD'};
    outline-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
    color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray1 || '#778AA5' : props.theme.colors.white || '#ffffff'};
  }

  &:focus {
    outline-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
  }

  &:focus-visible {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.ligthGray1 || '#F0F4FA' : props.theme.colors.primary3 || '#003E8C'};
    outline-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
  }

  ${(props) => {
    if (props.disabled) {
      return `
        background-color: ${props.theme.colors.ligthGray1 || '#F0F4FA'};
        color: ${props.theme.colors.darkGray1 || '#778AA5'};
        cursor: default;
      `;
    }
  }}

  ${(props) => {
    if (props.checked) {
      return `
        background-color: ${props.disabled ? props.theme.colors.darkGray2 : props.theme.colors.primary2};
        color: ${props.theme.colors.white};
        
        &:hover {
          background-color: ${props.disabled ? props.theme.colors.darkGray2 : props.theme.colors.primary2};
          color: ${props.theme.colors.white};
        }
      `;
    }
  }}

  ${(props) => {
    if (props.formState.error !== undefined && props.formState.touched) {
      return `
        background-color: ${
          props.checked && props.disabled
            ? props.theme.colors.darkGray2
            : !props.checked && props.disabled
            ? props.theme.colors.darkGray2
            : props.checked && !props.disabled
            ? props.theme.colors.negative2
            : props.theme.colors.negative4
        };
        color: ${props.theme.colors.white};

        &:hover:not(:disabled) {
          background-color: ${
            props.checked && props.disabled
              ? props.theme.colors.darkGray2
              : !props.checked && props.disabled
              ? props.theme.colors.darkGray2
              : props.checked && !props.disabled
              ? props.theme.colors.negative1
              : props.theme.colors.negative1
          };
          color: ${props.theme.colors.white};

        }
    `;
    }
  }}
`;

export { Pill };
