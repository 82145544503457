import React from 'react';
import { Portal } from 'react-portal';
import { Helmet, HelmetProvider } from 'react-helmet-async';

type DependenciesTypes = [
  {
    attributes: {
      link?: string;
      rel?: string;
      href?: string;
      src?: string;
      script?: string;
    };
    type: string;
    value: string;
  },
];

function attachHeadElements(dependencies: DependenciesTypes) {
  return (
    <HelmetProvider>
      <Helmet>
        {dependencies.map((dependency, index) => {
          switch (true) {
            case dependency.type === 'link':
              return <link key={`external-asset-dependeny-${index}`} {...dependency.attributes} />;
            case dependency.type === 'script':
              return <script key={`external-asset-dependeny-${index}`} {...dependency.attributes} />;
            case dependency.type === 'inline-script':
              return <script key={`external-asset-dependeny-${index}`}>{dependency.attributes.script}</script>;
            case dependency.type === 'meta':
              return <meta key={`external-asset-dependeny-${index}`} {...dependency.attributes} />;
            case dependency.type === 'title':
              return <title key={`external-asset-dependeny-${index}`}>{dependency.value}</title>;
            default:
              return false;
          }
        })}
      </Helmet>
    </HelmetProvider>
  );
}

function attachOtherElements(dependencies: DependenciesTypes) {
  return dependencies.map((dependency, index) => {
    return (
      <Portal key={`external-asset-dependeny-${index}`} node={document && document.body}>
        <script key={`external-asset-dependeny-${index}`} {...dependency.attributes} />
      </Portal>
    );
  });
}

function DocumentScriptsProvider({ configDependencies }) {
  return (
    <>
      {attachHeadElements(configDependencies.filter((dependency) => dependency?.options?.attachTo === undefined))}
      {attachOtherElements(configDependencies.filter((dependency) => dependency?.options?.attachTo))}
    </>
  );
}

export { DocumentScriptsProvider };
