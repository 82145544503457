import React, { ReactNode } from 'react';
import * as Styled from './button.styled';

export type ButtonProps = {
  /**
   * for content nested inside the Button tag.
   */
  children?: ReactNode;

  /**
   * class names override
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;
  /**
   * To identify the component
   */
  id?: string;

  /**
   * Disabeld attribute
   */
  disabled?: boolean;

  /**
   * to handle click events
   */
  onClick?: () => void;

  /**
   * component width. auto = fits to content, full fit to container
   */
  size?: 'auto' | 'full';

  /**
   * specify button type
   */
  type?: 'button' | 'submit' | 'reset';

  /**
   * configurable button variant
   */
  variant: 'text' | 'button-primary' | 'button-secondary' | 'button-tertiary' | 'button-success' | 'button-danger';
};

export function Button({
  id,
  children,
  className,
  configStyles,
  disabled = false,
  onClick = () => {},
  size = 'auto',
  type = 'button',
  variant,
}: ButtonProps) {
  return (
    <Styled.Button
      as={'button'}
      className={className}
      configStyles={configStyles}
      disabled={disabled}
      onClick={onClick}
      size={size}
      type={type}
      variant={variant}
      id={id}
    >
      {children}
    </Styled.Button>
  );
}
