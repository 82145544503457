import React, { useState, useRef, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import * as Styled from './googlemaps.styled';
export type GooglemapsProps = {
  /**
   * Identifies the Map component
   */
  id: string;
  /**
  * Specifies the name of the Map component
  */
  name: string;
  /**
   * Specifies the map value
   */
  value: string;
  /**
   * Select between map component and searchbox component
   */
  mapType: 'standaloneSearchbox' | 'map' | 'mapWithSearchbox';
  /**
   * Defines the width of the map in PX units.
   */
  mapWidth?: number;
    /**
   * Defines the height of the map in PX units.
   */
  mapHeight?: number;
  /**
   * Defines the initial location of the marker on the map as well as its center
   */
  initialPosition?: google.maps.LatLngLiteral;
  /**
   * Enables/disables all default UI buttons. 
   */
  disableControls?: boolean;
  /**
   * The enabled/disabled state of the Fullscreen control.
   */
  fullScreenControl?: boolean;
  /**
   * If true, the marker can be dragged.
   */
  draggableMarker?: boolean;
  /**
   * Setting controls how the API handles gestures on the map
   */
  gestureHandling?: 'cooperative' | 'greedy' | 'none' | 'auto';
  /**
   * The initial enabled/disabled state of the Street View Pegman control.
   */
  streetViewControl?: boolean;
  /**
   * The initial Map zoom level.
   */
  initialZoom?: number;
  /**
   * The enabled/disabled state of the Zoom control.
   */
  zoomControl?: boolean;
  /**
   * Defines the marker visibility.
   */
  markerVisible?: boolean;
  /**
   * Defines the placeholder for input searchbox
   */
  searchPlaceholder?: string;
  /**
   * for component-level styling override (Design System)
   */
  _configStyles?: 'string';
};
type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];
const mapsLibraries: Libraries = ["places"];
export function Googlemaps({
    id,
    name,
    value,
    initialPosition,
    mapType,
    mapHeight = 400,
    mapWidth = 600,
    disableControls = false,
    fullScreenControl,
    draggableMarker,
    gestureHandling,
    streetViewControl,
    zoomControl,
    initialZoom = 15,
    markerVisible,
    searchPlaceholder = 'Custom input search',
    _configStyles
  }: GooglemapsProps){
    const mapRef = useRef(null);
    const [searchBox, setSearchBox] = useState(null);
    const [placeBounds,setPlaceBounds] = useState(
      {
        lat: 19.42719144689876,
        lng:  -99.16757886928848
      }
    );
    useEffect(() => {
      navigator.geolocation.getCurrentPosition(
        function(position){
          setPlaceBounds({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        function (error) {
          console.log('Geolocation Denied', error);
          setPlaceBounds(initialPosition);
        }
      );
    }, [initialPosition]);
    const mapOptions = {
        fullscreenControl: fullScreenControl,
        disableDefaultUI: disableControls,
        gestureHandling: gestureHandling,
        mapTypeControlOptions: {
          position: 6
        },
        fullscreenControlOptions: {
          position: 8
        },
        streetViewControl: streetViewControl,
        zoom: initialZoom,
        zoomControl: zoomControl
    }
    const markerOptions = {
      draggable: draggableMarker,
      visible: markerVisible
    };
    const containerStyle = {
      width: `${mapWidth}px`,
      height: `${mapHeight}px`,
    };
    const onLoadMap = useCallback(
      (map) => {
        mapRef.current = map;
      },
      []
    )
    const onLoadSearchBox = (ref) => setSearchBox(ref);
    function handlePlaceChanged () {
      const place = searchBox.getPlace();
      const placeLat = place.geometry.location.lat();
      const placeLng = place.geometry.location.lng();
      setPlaceBounds({
        lat: placeLat,
        lng: placeLng
      });
    }
    const { isLoaded } = useJsApiLoader(
      {
        googleMapsApiKey: "AIzaSyCOcmWgZ9TtwklPbdrfHoaCh7y7z7W-r1I",
        libraries: mapsLibraries,
      }
      );
    if (!isLoaded) return <div>Loading...</div>;
    if (mapType === 'map') {
      return(
        <GoogleMap
        id={id}
        center={placeBounds}
        mapContainerStyle={containerStyle}
        options={mapOptions}
        onLoad={onLoadMap}
      >
        <Marker
        position= {placeBounds}
        options={markerOptions}
        />
        </GoogleMap>
      )
    }
    if (mapType === 'mapWithSearchbox') {
      return (
      <GoogleMap
        id={id}
        center={placeBounds}
        mapContainerStyle={containerStyle}
        options={mapOptions}
        onLoad={onLoadMap}
      >
        <Marker
          position= {placeBounds}
          options={markerOptions}
        />
        <Autocomplete
          onLoad={onLoadSearchBox}
          onPlaceChanged={handlePlaceChanged}
        >
          <Styled.SearchInput 
            type={'text'}
            placeholder={searchPlaceholder}
          />
        </Autocomplete>
      </GoogleMap>
      )
      
    }
    if (mapType === 'standaloneSearchbox') {
      return (
        <Autocomplete
          onLoad={onLoadSearchBox}
          onPlaceChanged={handlePlaceChanged}
        >
          <Styled.SearchInput 
          type={'text'}
          placeholder={searchPlaceholder}
          _configStyles={mapType}
          />
        </Autocomplete>
      )
    }
  }