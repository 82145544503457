import styled from 'styled-components';

export const List = styled.div<any>`
  padding: 0;
  width: 100%;
  ${(props) => {
    if (props.variant === 'horizontal') {
      return `
                display: flex;
                list-style-type: none;
                align-items: center;
                & > li{
                    &:not(:last-child){
                        margin-right: ${props.theme.spacings.xs};
                    }
                }
            `;
    }
  }}

  ${(props) => props.configStyles};
`;

export const Li = styled.li``;
