/*
 * Evaluation and test related utilities
 */

/* 
  Function name: equals
  Description: Returns true if its arguments are equivalent, false otherwise.
  Params: (val1, val2)
  ToDo: Cyclical data structures comparisson handling (arrays and objects).
*/
export const equals = (val1: any, val2: any): boolean => val1 === val2;

/* 
  Function name: isEmpty
  Description: Returns true if the given value is its type's empty value; false otherwise.
  Params: (val)
  - val: Value for evaluation (array, object, string, number)
*/
export const isEmpty = (val: any): boolean => {
  if (Array.isArray(val)) return !val.length;
  if (typeof val === 'object') {
    for (var i in val) return false;
    return true;
  }
  if (val) return false;
  else return true;
};

/* 
  Function name: gt (greatherThan)
  Description: Returns true if the first argument is greater than the second; false otherwise.
  - val: Value for evaluation (array, object, string, number)
*/
export const gt = (val1: any, val2: any): boolean => {
  return val1 > val2;
};

/* 
  Function name: lt (lowerThan)
  Returns true if the first argument is less than the second; false otherwise.
  Params: (val)
  - val: Value for evaluation (array, object, string, number)
*/
export const lt = (val1: any, val2: any): boolean => {
  return val1 < val2;
};

export const test = (type, val1, val2): boolean => {
  switch (type) {
    case 'equals':
      return equals(val1, val2);
    case 'notEquals':
      return !equals(val1, val2);
    case 'empty':
      return isEmpty(val1);
    case 'notEmpty':
      return !isEmpty(val1);
    case 'greaterThan':
      if (!isEmpty(val1)) {
        if (Array.isArray(val1) && gt(val1.length, val2)) {
          return true;
        } else if (gt(val1, val2)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    case 'lowerThan':
      if (!isEmpty(val1)) {
        if (Array.isArray(val1) && lt(val1.length, val2)) {
          return true;
        } else if (lt(val1, val2)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    default:
      return false;
  }
};
