import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import * as Styled from './currency.styled';

export type CurrencyProps = {
  /**
   * allows the browser to predict the value
   */
  autoComplete?: string;

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * to set the currency code (ISO 4217)
   */
  currency?: string;

  /**
   * to disable or enable the Currency element
   */
  disabled?: boolean;

  /**
   * an identifier for the Currency element
   */
  id: string;

  /**
   * to set the currency format (Language tags BCP 47)
   */
  locale?: string;

  /**
   * specifies the name of the Currency element
   */
  name: string;

  /**
   * a function to be triggered when the Currency element loses focus
   */
  onBlur?: () => void;

  /**
   * a function to be triggered when the Currency element gets focus
   */
  onFocus?: () => void;

  /**
   * a placeholder for the Currency element
   */
  placeholder?: string;

  /**
   * specifies that the Currency is read-only
   */
  readOnly?: boolean;

  /**
   * specifies that the Currency must be filled
   */
  required?: boolean;

  /**
   * a function to be triggered when the Currency element value change
   */
  setValue?: any;

  /**
   * To include a suffix.
   */
  suffix?: string;

  /**
   * field value
   */
  value?: string;
};

export function Currency({
  autoComplete = 'off',
  className = '',
  configStyles = '',
  currency,
  disabled,
  id,
  locale = '',
  name,
  onBlur,
  onFocus,
  placeholder,
  readOnly,
  required,
  setValue,
  suffix,
  value,
}: CurrencyProps) {
  return (
    <Styled.CurrencyField className={className} configStyles={configStyles}>
      <CurrencyInput
        autoComplete={autoComplete}
        disabled={disabled}
        id={id}
        inputMode="numeric"
        intlConfig={{ locale: locale, currency: currency }}
        name={name}
        onValueChange={(e) => setValue(e)}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        suffix={suffix}
        value={value}
      />
    </Styled.CurrencyField>
  );
}
