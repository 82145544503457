import React, { useState } from 'react';
//import {Document, Page} from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import * as Styled from './pdfviewer.styled';

export type PdfviewerProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * PDF file source
   */
  srcFile: string;
  /**
   * To enable or disable the zoom buttons
   */
  scalable?: boolean;
  /**
   * To enable or disable the download button
   */
  downloadable?: boolean;
  /**
   * To enable or disable the navigation buttons
   */
  navigable?: boolean;
};

export function Pdfviewer({ srcFile, scalable = true, downloadable = true, navigable = true, id }: PdfviewerProps) {
  const [numPages, setNumPages] = useState<number>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(null);

  const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setScale(1);
  };

  function changePage(pageOffset: number) {
    setPageNumber((prevPage) => prevPage + pageOffset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function changeScale(scaleOffset: number) {
    setScale((prevScale) => prevScale + scaleOffset);
  }

  function zoomIn() {
    changeScale(0.1);
  }

  function zoomOut() {
    changeScale(-0.1);
  }

  return (
    <Styled.PdfBook id={id}>
      <Styled.PdfDisplay>
        <Document file={srcFile} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} scale={scale} width={width - 45} />
        </Document>
      </Styled.PdfDisplay>
      <Styled.PdfControls>
        <Styled.ControlGroup>
          <Styled.ControlButton as={'a'} href={srcFile} download downloadable={downloadable}>
            {'⬇'}
          </Styled.ControlButton>
        </Styled.ControlGroup>
        <Styled.ControlGroup>
          <Styled.ControlButton disabled={pageNumber <= 1 || !navigable} onClick={previousPage}>
            {'<'}
          </Styled.ControlButton>
          <Styled.PaginationSpan>
            {pageNumber} de {numPages}
          </Styled.PaginationSpan>
          <Styled.ControlButton disabled={pageNumber >= numPages || !navigable} onClick={nextPage}>
            {'>'}
          </Styled.ControlButton>
        </Styled.ControlGroup>
        <Styled.ControlGroup>
          <Styled.ControlButton disabled={scale <= 0.3 || !scalable} onClick={zoomOut}>
            {'-'}
          </Styled.ControlButton>
          <Styled.ControlButton disabled={scale >= 5 || !scalable} onClick={zoomIn}>
            {'+'}
          </Styled.ControlButton>
        </Styled.ControlGroup>
      </Styled.PdfControls>
    </Styled.PdfBook>
  );
}
