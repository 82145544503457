//Scroll to top of window
const scrollToTop = (behavior?: ScrollBehavior) => {
  window.scrollTo({ top: 0, behavior: behavior || 'smooth' });
};

// Focus on DOM elmeent based of its [id] attribute
// Notes:
// - element must no have a display: none style property or else it will not be focusable
const focusOnElement = (elementId: string) => {
  if (elementId) {
    document.getElementById(elementId).focus();
  }
};

// Focus on DOM form element based of its [name] attribute in the order set in the reference form
// Note:
// - name property is used as this lookup is for form elements
// - ...args is used to extend the "exectue" function default params
// - element must no have a display: none style property or else it will not be focusable
const focusOnFieldError = (params, ...args) => {
  if (args.length && args[0].name) {
    document.getElementsByName(args[0].name)[0].scrollIntoView();
    document.getElementsByName(args[0].name)[0].focus();
  }
};

export { scrollToTop, focusOnElement, focusOnFieldError };
