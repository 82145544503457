import React from 'react';
import * as Styled from './dotloader.styled';

export type DotloaderProps = {
  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * To identify the Dotloader component
   */
  id?: string;

  /**
   * represents how the component colors respond per the host component's current state (normal, disabled)
   */
  state?: 'default' | 'disabled';
};

export function Dotloader({ configStyles, id, state = 'default'}: DotloaderProps) {
  return <Styled.Dotloader configStyles={configStyles} id={id} state={state}/>;
}
