import React, { ReactNode } from 'react';
import * as Styled from './container.styled';

export type ContainerProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  /**
   * classes for external library implementations
   */
  className?: string;

  /**
   * styling configuration overrides
   */
  configStyles?: string;

  /**
   * layout orientation configuration
   * [free: default container display value,
   * horizontal: display flex with row behavior,
   * vertical: display flex with column behavior]
   */
  contentOrientation?: 'free' | 'horizontal' | 'horizontal-centered' | 'vertical' | 'vertical-centered' | 'centered';

  /**
   * inherited props from higher components
   */
  props?: any;

  /**
   * value that sets the semantic HTML element attribute
   */
  semanticType?: 'div' | 'section' | 'article' | 'footer' | 'nav' | 'header' | 'main' | 'aside';
};

export function Container({
  children,
  className,
  configStyles,
  contentOrientation = 'free',
  props,
  semanticType = 'div',
  ...rest
}: ContainerProps) {
  return (
    <Styled.Container
      {...props}
      className={className}
      as={semanticType}
      contentOrientation={contentOrientation}
      configStyles={configStyles}
      {...rest}
    >
      {children}
    </Styled.Container>
  );
}
