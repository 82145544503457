import styled from 'styled-components';

export const Navbar = styled.nav`
  display: none;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacings.lg};
  background-color: ${(props) => props.theme.colors.primary4};
`;

export const NavbarBurger = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${(props) => props.theme.spacings.lg};
  background-color: inherit;
`;

export const NavbarItems = styled.ul.attrs({ role: 'list' })`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FlatNavigation = styled.div<{
  configStyles: string | undefined;
  mode: 'horizontal' | 'vertical' | undefined;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary4};
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    & > ${Navbar} {
      display: flex;
      flex-direction: ${(props) => (props.mode === 'horizontal' ? 'row' : 'column')};
      background-color: ${(props) => props.configStyles?.['background-color']};
    }

    & > ${Navbar} > ${NavbarItems} {
      ${(props) => {
        if (props.mode === 'vertical') {
          return `
            & > li{
                &:not(:last-child){
                    margin-top: ${props.theme.spacings.xsm};
                }
            }
          `;
        } else {
          return `
                display: flex;
                align-items: center;
                & > li{
                    &:not(:last-child){
                        margin-right: ${props.theme.spacings.sm};
                    }
                }
            `;
        }
      }}
    }

    & > ${NavbarBurger} {
      display: none;
    }
  }

  ${(props) => props.configStyles}
`;

export const NavbarCollapse = styled.nav.attrs({ role: 'navigation' })<{
  collapsibleNavHeight: number | undefined;
  collapsibleNavPosition: number | undefined;
  configStyles: string | undefined;
}>`
  position: absolute;
  top: ${(props) => props.collapsibleNavPosition}px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.primary4};
  padding: ${(props) => props.theme.spacings.lg};
  padding-top: ${(props) => props.theme.spacings.xlg};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: ${(props) => props.collapsibleNavHeight}px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }

  ${(props) => props.configStyles}
`;

export const Item = styled.li<{ isNavLinks: boolean | undefined }>``;

export const Link = styled.a.attrs({ role: 'link' })`
  &:link {
    text-decoration: none;
    color: inherit;
  }
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`;

export const NavbarCollapseItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li + li {
    margin-top: ${(props) => props.theme.spacings.xlg};
  }
`;

export const Bar = styled.div<{
  firstCloseBar?: boolean | undefined;
  secondCloseBar?: boolean | undefined;
  burgerBar?: boolean | undefined;
}>`
  width: 18px;
  height: 2px;
  background-color: ${(props) => props.theme.colors.white};

  ${(props) => (props.firstCloseBar ? `transform: rotate(45deg)` : ``)};
  ${(props) => (props.secondCloseBar ? `transform: rotate(-45deg) translateX(2px) translateY(-1px)` : ``)};
  ${(props) => (props.burgerBar ? `margin: 3px 0` : ``)};
`;

export const BurgerIcon = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 30px;
  height: 20px;
  padding: 0;
`;
