import React from 'react';
import * as Styled from './image.styled';

export type ImageProps = {
  /**
   * address of the source
   */
  src: string;
  /**
   * text description for accesibility and semantics purposes
   */
  alt: string;
  /**
   * define the height of the image
   */
  height?: string;
  /**
   * define the width of the image
   */
  width?: string;
  /**
   * a set of classes separated by one space applied to the button
   */
  className?: string;
  /**
   * for component level styling override (Design System)
   */
  configStyles?: string;
  /**
   * To identify the component
   */
  id?: string;

  /**
   * loading modes for images
   * lazy : defer loading offscreen
   * eager: prioritize loading
   * auto: ...well, auto
   */
  loading?: 'lazy' | 'eager' | 'auto';
};

export function Image({ alt, className, configStyles, height, loading = 'auto', src, width, id }: ImageProps) {
  return (
    <Styled.Image
      id={id}
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      configStyles={configStyles}
      loading={loading}
    />
  );
}
