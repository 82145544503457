import React from 'react';
import * as Styled from './number.styled';
export type NumberProps = {
  /**
   * autocomplete allows the browser to predict the value
   */
  autocomplete?: string;

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * disabled specifies that the semantic element input[number] should be disable
   */
  disabled?: boolean;

  /**
   * id specifies a unique id for semantic element input[number]
   */
  id: string;

  /**
   * max sets the maximum value to accept
   */
  max?: number;

  /**
   * min sets the minimum value to accept
   */
  min?: number;

  /**
   * name specifies the name of the input element
   */
  name: string;

  /**
   * A function to be triggered when the input element loses focus
   */
  onBlur?: () => void;

  /**
   * onChange gets the current value of the input field
   */
  onChange?: (e) => void;

  /**
   * A function to be triggered when the input element gets focus
   */
  onFocus?: () => void;

  /**
   * placeholder provides a brief hint to the user as to what kind of information is expected in the field
   */
  placeholder?: string;

  /**
   * readonly can be set to keep a user from changing the value
   */
  readonly?: boolean;

  /**
   * required specifies that an input field must be filled out before submitting the form
   */
  required?: boolean;

  /**
   * step specifies the increment/decrement value
   */
  step?: number;

  /**
   * value sets an initial value for the input
   */
  value?: string;
};

export function Number({
  autocomplete = 'off',
  className,
  configStyles,
  disabled,
  id,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readonly = false,
  required = false,
  step,
  value,
}: NumberProps) {
  return (
    <Styled.Input
      autoComplete={autocomplete}
      className={className}
      configStyles={configStyles}
      disabled={disabled}
      id={id}
      inputMode="numeric"
      max={max}
      min={min}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      pattern="[0-9]*"
      placeholder={placeholder}
      readOnly={readonly}
      required={required}
      step={step}
      type="number"
      value={value}
    />
  );
}
