// React
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Rematch : Redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState, Dispatch } from '../../Application/State/store';

// API Calls Fns
import { OneAppAPI } from '../../Infrastructure/API/Configs/OneApp.api';

// OneApp : Components
import { Component } from './component/Component';

interface IRegions {}

export const Regions = (props: IRegions) => {
  const [regions, setRegions] = useState<
    | {
        id: string;
        region: string;
        components: [any];
      }[]
    | undefined
  >([]);
  const dispatch = useDispatch<Dispatch>();
  const { layoutId } = useSelector((state: RootState) => state.OneAppModel, shallowEqual);
  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      const response: any = await OneAppAPI.getRegions(layoutId, Object.fromEntries(new URLSearchParams(search)));
      setRegions(response);
    })();
  }, [dispatch]);

  return <>{regions && regions.map((region) => region.components.map((c) => <Component key={c.id} {...c} />))}</>;
};
