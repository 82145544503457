import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik, useFormikContext } from 'formik';

// Component
import { Component } from 'Presentation/component/component/Component';

// Utils
import utils from 'Application/utils';

// Hooks
import { useDebounce } from 'Application/hooks';

// API
import { OneAppAPI } from 'Infrastructure/API/Configs/OneApp.api';

type Values = {
  [key: string]: any;
};

type ConditionalProps = {
  childPreloaderId: string;
  children: React.ReactNode[];
  conditional_field_id: string;
  rateLimit: number;
  conditional: string;
  field: string;
  query?: Values;
};

const Conditional = ({
  childPreloaderId,
  rateLimit = 100,
  conditional_field_id,
  field: name,
  conditional,
  query,
  children,
}: ConditionalProps) => {
  const [field, setField] = useState([]);
  const { values: form, setFieldValue } = useFormikContext();
  const { search } = useLocation();

  const onSubmit = async (values: Values, { setSubmitting }: Values) => {
    let conditionalField = [];
    try {
      setSubmitting(true);
      const payload = { ...values, ...query };
      // fetch new components
      conditionalField = await OneAppAPI.postComponent(
        conditional_field_id,
        payload,
        Object.fromEntries(new URLSearchParams(search)),
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
    setField(conditionalField);
  };

  const formik = useFormik({
    initialValues: { [name]: form[name] },
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const clearValue = useCallback((currentValue: any) => {
    if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
      return Object.entries(currentValue).reduce(utils.Object.replacer(''), {});
    } else if (Array.isArray(currentValue)) return [];
    else return '';
  }, []);

  // On dependency update, trigger submit
  useDebounce(
    () => {
      if (form[name] && !formik.isSubmitting) {
        // Forcing render to happen per children as shallow evaluation will fail if nothing changes at this level
        setField([]);
        setFieldValue(conditional, clearValue(form[conditional]));
        formik.submitForm();
      }
    },
    rateLimit,
    [form[name]],
  );

  return (
    <>
      {/* These are the component's children that have been added on mount and on re render due to a call to /components */}
      {children.filter((component) => {
        return component['props'].id !== childPreloaderId;
      })}
      {formik.isSubmitting
        ? // This represents the child component that'll be used as an indicator for fetching
          children.filter((component) => {
            return component['props'].id === childPreloaderId;
          })
        : field.map((c: any) => {
            // This represent the new component being fetched and attached from /components call
            return <Component key={c.id} id={c.id} {...c} />;
          })}
    </>
  );
};

export default Conditional;
