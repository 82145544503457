import React, { ReactNode } from 'react';
import * as Styled from './fieldelement.styled';

export type FieldelementProps = {
  children: ReactNode;
  configStyles?: string;
  className?: string;
  id?: string;
};

export function Fieldelement({ children, className = '', configStyles = '', id }: FieldelementProps) {
  return (
    <Styled.Fieldelement className={className} configStyles={configStyles} id={id}>
      {Array.isArray(children)
        ? children.map((child, index) => {
            return React.cloneElement(child as any, { key: `field-element-${index}` });
          })
        : React.cloneElement(children as any, { key: `field-element` })}
    </Styled.Fieldelement>
  );
}
