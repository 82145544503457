import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStylesResets } from '../../../Application/Common/GlobalStyles';

function DesignSystemProvider({ activeTheme, children }) {
  return (
    <ThemeProvider theme={activeTheme}>
      {children}
      <GlobalStylesResets />
    </ThemeProvider>
  );
}

export { DesignSystemProvider };
