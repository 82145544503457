// Events
import { Events } from 'Application/enums/events';

// Actions
import { execute } from 'Application/actions';

// API
import { OneAppAPI } from 'Infrastructure/API/Configs/OneApp.api';

export const eventsSchema = (schema) => {
  if (!schema) return null;
  return schema.reduce((events: any, { event, trigger, actions }: any) => {
    if (event in Events) {
      const evnt = Object.entries(Events).find((pair) => pair[0] === event)?.[1] || '';
      events[evnt] = () => {
        if (actions) execute(actions);
        if (trigger)
          (async () => {
            const actns = await OneAppAPI.getTrigger(trigger);
            if (actns) execute(actns);
          })();
      };
    }
    return events;
  }, {});
};
