import React, { ReactNode, useState, useRef, useEffect, Children, ReactElement } from 'react';
import { Portal } from 'react-portal';
import * as Styled from './flatnavigation.styled';

export type FlatnavigationProps = {
  /**
   * for content nested inside the nav
   */
  children: ReactNode | ReactNode[];
  /**
   * class names override
   */
  className?: string;
  /**
   * styling overrides
   */
  configStyles?: string;
  /**
   * to identify the flat navigation component
   */
  id: string;
  /**
   * to set content orientation
   */
  mode?: 'vertical' | 'horizontal';
};

type NavlinksProps = {
  /**
   * for content nested inside the nav
   */
  children?: ReactNode | ReactNode[];
  /**
   * to identify as a set of links for navigation
   */
  isNavLinks?: boolean;
  /**
   * nav items configuration
   */
  itemsConfig?: { linkTo: string; target: '_blank' | '_self' | '_parent' | '_top' }[];
};

type BurgerProps = {
  /**
   * to determine if the burger icon is opened
   */
  isBurgerOpen?: boolean;
  /**
   * function to be called when the burger icon gets clicked
   */
  onClick?: () => void;
};

function BurgerIcon({ onClick, isBurgerOpen }: BurgerProps) {
  return (
    <Styled.BurgerIcon onClick={onClick}>
      {isBurgerOpen ? (
        <>
          <Styled.Bar firstCloseBar />
          <Styled.Bar secondCloseBar />
        </>
      ) : (
        <>
          <Styled.Bar burgerBar />
          <Styled.Bar burgerBar />
          <Styled.Bar burgerBar />
        </>
      )}
    </Styled.BurgerIcon>
  );
}

export function Flatnavigation({ children, className, configStyles, id, mode = 'horizontal' }: FlatnavigationProps) {
  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
  const [collapsibleNavHeight, setCollapsibleNavHeight] = useState<number>(0);
  const [collapsibleNavPosition, setCollapsibleNavPosition] = useState<number>(0);
  const navRef = useRef<HTMLDivElement>(null);
  const navBarCollapse = useRef<HTMLElement>(null);
  useEffect(() => {
    function onResizeRestoreMenu() {
      document.body.style.overflowY = 'auto';
      setIsBurgerOpen(false);
    }
    window.addEventListener('resize', onResizeRestoreMenu);
    return () => window.removeEventListener('resize', onResizeRestoreMenu);
  }, []);

  useEffect(() => {
    const navHeight = navRef.current.getBoundingClientRect().height;
    const navYposition = navRef.current.getBoundingClientRect().y;
    const collapsibleNavHeight =
      (window.innerHeight || document.documentElement.clientHeight) - navHeight - navYposition;
    setCollapsibleNavHeight(collapsibleNavHeight);
    const collapsibleNavPosition = navYposition + navHeight;
    setCollapsibleNavPosition(collapsibleNavPosition);
    if (isBurgerOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isBurgerOpen]);

  const getNavItems = (childrenReceived: ReactNode | ReactNode[], itemsType: 'links' | 'others') => {
    return Children.toArray(childrenReceived).filter((child) => {
      const childReceived = child as ReactElement;
      if (itemsType === 'links') {
        return childReceived?.props?.attributes?.isNavLinks
          ? childReceived?.props?.attributes?.isNavLinks
          : childReceived?.props?.isNavLinks
          ? childReceived?.props?.isNavLinks
          : null;
      } else if (itemsType === 'others') {
        if (!childReceived?.props?.attributes?.isNavLinks && !childReceived?.props?.isNavLinks) {
          return !childReceived?.props?.attributes?.isNavLinks || !childReceived?.props?.isNavLinks;
        }
        return null;
      }
    });
  };

  return (
    <Styled.FlatNavigation ref={navRef} className={className} configStyles={configStyles} id={id} mode={mode}>
      <Styled.Navbar>
        <Styled.NavbarItems>{getNavItems(children, 'links')}</Styled.NavbarItems>
        {getNavItems(children, 'others')}
      </Styled.Navbar>
      <Styled.NavbarBurger>
        <BurgerIcon isBurgerOpen={isBurgerOpen} onClick={() => setIsBurgerOpen((isOpen) => !isOpen)} />
        {getNavItems(children, 'others')}
      </Styled.NavbarBurger>
      {isBurgerOpen && (
        <Portal>
          <Styled.NavbarCollapse
            ref={navBarCollapse}
            collapsibleNavHeight={collapsibleNavHeight}
            collapsibleNavPosition={collapsibleNavPosition}
            configStyles={configStyles}
          >
            <Styled.NavbarCollapseItems>{getNavItems(children, 'links')}</Styled.NavbarCollapseItems>
          </Styled.NavbarCollapse>
        </Portal>
      )}
    </Styled.FlatNavigation>
  );
}

export function Navlinks({ children, itemsConfig, isNavLinks = true }: NavlinksProps) {
  return (
    <>
      {Children.toArray(children).map((childLink, index) => (
        <Styled.Item key={`nav-item-${index}`} isNavLinks={isNavLinks}>
          <Styled.Link href={itemsConfig[index].linkTo} target={itemsConfig[index].target}>
            {childLink}
          </Styled.Link>
        </Styled.Item>
      ))}
    </>
  );
}
