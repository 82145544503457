// API
import OneAppAPIHandler from 'Infrastructure/API/OneAppApiHandler';

// Hooks
import { useFile } from 'Application/hooks';

const useHttp = () => OneAppAPIHandler.configs();

const Handlers: { [key: string]: any } = Object.freeze({
  file: useFile,
  http: useHttp,
});

export const useHandlers = (settings: any) => {
  const handlers = Object.entries(settings).reduce((accum, [key, value]) => {
    if (key in Handlers) {
      const schema = { [key]: Handlers[key](value) };
      return { ...accum, ...schema };
    }
    return accum;
  }, {});

  return { handlers: handlers };
};
