/**
 * One App API Handler
 *
 * This module's purpose is to create and configure the HTTP client instance using Axios
 * and globally handle Authentication/Credentials for OneApp.
 *
 */
import axios from 'axios';
import { BaseURL } from '../../Application/Common/Globals';
import { dispatch, store } from '../../Application/State/store';

// ToDo: @dependency on definition and implementation of 'utility pages' (login, auth, 404, 403,)
// Note: https://v5.reactrouter.com/web/api/history/history-is-mutable
// let history = createBrowserHistory();

/**
 * OneAppInstance : Axios Instance
 */
const OneAppInstance = axios.create({
  baseURL: BaseURL,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

/**
 * ! OneApp Instance : Axios instance
 * @Request
 *
 * OneApp's interceptors @request function has the following responsibilities:
 * - Handle credentials via the application's CredentialsModel
 * - Error handling:
 *    N/A
 *
 * Authentication flow:
 *
 * From application's store CredentialsModel,
 *    only if there is a sessionId set,
 *    then a the intercepted call will conditionally have headers where the
 *    following values are present:
 *      'x-session-id' : sessionId
 *      'x-refresh-token' : refresh_token
 *      'Authorization' : Bearer access_token
 *
 */
OneAppInstance.interceptors.request.use(async (requestConfig) => {
  const { sessionId, access_token, refresh_token } = await store.getState().CredentialsModel;
  let heads = {};

  if (sessionId !== 'null') {
    heads = {
      ...(sessionId !== 'null' && { 'x-session-id': sessionId }),
      ...(access_token !== 'null' && { Authorization: `Bearer ${access_token}` }),
      ...(refresh_token !== 'null' && { 'x-refresh-token': refresh_token }),
    };
  }
  requestConfig.headers = {
    ...heads,
  };

  OneAppInstance.defaults.headers.common = {
    ...heads,
  };

  return requestConfig;
});

/**
 * ! OneApp Instance : Axios instance
 * @Response
 *
 * OneApp's interceptors @response function has the following responsibilities:
 * - Attach headers progressively depending on headers availability upon response
 * - Error handling:
 *  * Status code : 401 - Unauthorized - User needs to authenticate
 *    - Silent refresh (already login) -- where a *valid 'refresh_token' and an *invalid 'access_token' are available in application's
 *      store CredentialsModel
 *    - Unauthorized (needs login) -- where 'refresh_token' and 'access_token' are not present either in header
 *      and store, resulting in storign sessionid in local storage and redirecting the user to the application's hard authentication
 *      page (provisional auth.pefai.com).
 *  * Status code : 412 - Precondition Failed - Session has expired or failed
 *    - When session has expired, the user credentials will be cleared from store and local storage and the user will be redirected out
 *      of the experience*
 *
 *    *Redirection is currently provisional to oneapp}/{org}/{appname}, excluding potential headers.
 */
OneAppInstance.interceptors.response.use(
  async function (responseConfig) {
    const { orgName, appName } = await store.getState().OneAppModel;
    // Note: the initial store value of the CredentialsModel is set to 'null' (string) to default type on init call
    const { sessionId, access_token, refresh_token } = await store.getState().CredentialsModel;

    const header_sessionId = responseConfig.headers['x-session-id'];
    const header_access_token = responseConfig.headers['x-access-token'];
    const header_refresh_token = responseConfig.headers['x-refresh-token'];

    if (header_sessionId && sessionId === 'null') {
      dispatch.CredentialsModel.setSession({ orgName, appName, sessionId: header_sessionId });
    }

    if (header_access_token && access_token === 'null') {
      dispatch.CredentialsModel.setAccessToken({ orgName, appName, access_token: header_access_token });
    }

    if (header_refresh_token && refresh_token === 'null') {
      dispatch.CredentialsModel.setRefreshToken({ orgName, appName, refresh_token: header_refresh_token });
    }
    return responseConfig;
  },
  async (error) => {
    const { refresh_token } = await store.getState().CredentialsModel;
    const { orgName, appName, organizationId } = await store.getState().OneAppModel;
    // ? Authentication via auth.pefai.com
    if (error.response.status === 401) {
      // * Scenario : Already authenticated
      /**
       * If there is an a refresh_token, the user has been previously authenticated,
       * and the access_token is no longer valid and requires a silent-refresh
       * */
      if (refresh_token !== 'null') {
        const failedAuhRequestHeaders = error.config;
        failedAuhRequestHeaders.headers['Authorization'] = `Bearer null`;
        await dispatch.CredentialsModel.setAccessToken({ orgName, appName, access_token: 'null' });
        await dispatch.CredentialsModel.getCredentials({ organizationId });
        return OneAppInstance(failedAuhRequestHeaders);
      }
      // * Scenario : Not authenticated
      /**
       * If there aren't any values for access_token and refresh_token, the user hasn't been authenticated
       * and will be redirected to a login page to get authenticated
       */
      else {
        const { formLoginUrl } = error.response.data;
        window.location.assign(formLoginUrl);
      }
    }

    // Precondition Failed: Invalid sessions or expired sessions : Exit user
    if (error.response.status === 412) {
      await dispatch.CredentialsModel.clearCredentials({ orgName, appName });
      const redirectUrl = window.location.href;
      window.location.replace(redirectUrl);
    }
    return Promise.reject(error);
  },
);

/**
 * OneAppAPIHandler
 *
 * This is an Axios wrapper for OneApps HTTP client instance.
 *
 * @GET:
 * + url: uses instance's BASE_URL + url to build endpoint string. BASE_URL can be suppressed if absolute url is detected
 * + params: used to pass object structure to endpoint
 * - headers (not implemented): used to extend or override instance's headers
 *
 * @POST:
 * + url: uses instance's BASE_URL + url to build endpoint string. BASE_URL can be suppressed if absolute url is detected
 * + data: data to be sent as request body
 * + params: are the URL parameters to be sent with the request
 * - headers (not implemented): used to extend or override instance's headers
 *
 * @UPDATE: (not implemented yet)
 * @DELETE: (not implemented yet)
 *
 */
const OneAppAPIHandler = {
  GET: (url: string, params?: any) => {
    return OneAppInstance({
      method: 'GET',
      url,
      params,
    })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((e) => {
        console.log(e);
        return Promise.reject(e.response);
      });
  },
  POST: (url: string, data?: any, params?: any, headers?: any) => {
    return OneAppInstance({
      method: 'POST',
      url,
      headers,
      data,
      params,
    })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((e) => {
        console.log(e);
        return Promise.reject(e.response);
      });
  },
  UPDATE: () => {
    return null;
  },
  DELETE: () => {
    return null;
  },
  configs: () => {
    return {
      baseURL: OneAppInstance.defaults.baseURL,
      headers: {
        ...OneAppInstance.defaults.headers,
        ...OneAppInstance.defaults.headers.common,
      },
    };
  },
};

export default OneAppAPIHandler;
