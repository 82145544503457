import React from 'react';
import { store } from '../../../../Application/State/store';

export const useModel = (model, attributes, id) => {
  const modelName = Object.keys(model).toString() || undefined;

  React.useEffect(() => {
    if (id !== '' && Object.keys(model)) {
      store.dispatch.ComponentsModel.buildDynamicState({
        [modelName]: { ...model[modelName].state },
      });
    }
  }, []);

  return { store, model, modelName };
};
