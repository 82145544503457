import React, { useRef, useEffect, useState } from 'react';
import * as Styled from './svgindicator.styled';

export type SvgindicatorProps = {
  /**
   * class names override
   */
  className?: string;

  /**
   * styling overrides
   */
  configStyles?: string;

  /**
   * The displayed height of the rectangular viewport. (Not the height of its coordinate system.)
   */
  height?: number | string;

  /**
   * to identify the flat navigation component
   */
  id?: string;

  /**
   * A path definition (path commands) to define the progress path to be drawn
   */
  progressBar: string;

  /**
   * Percentage value of the progress bar relative to the track
   */
  progressValue: number;

  /**
   * A path definition (path commands) to define the track path to be drawn
   */
  track: string;

  /**
   * To scale the SVG element. SVG viewport coordinates for the current SVG fragment
   */
  viewBox?: string;

  /**
   * The displayed width of the rectangular viewport. (Not the width of its coordinate system.)
   */
  width?: number | string;
};

export function Svgindicator({
  className,
  configStyles,
  height = '100%',
  id,
  progressBar,
  progressValue,
  track,
  viewBox = '0 0 100 100',
  width = '100%',
}: SvgindicatorProps) {
  const [progressOffest, setProgressOffset] = useState<{ dashArray: string; dashOffset: string }>({
    dashArray: '',
    dashOffset: '',
  });
  const progressRef = useRef<SVGGeometryElement>(null);

  useEffect(() => {
    const getProgressOffset = () => {
      if (progressRef.current.getTotalLength) {
        const progressLength = progressRef.current.getTotalLength();
        const progressBarOffset = progressLength * ((100 - progressValue) / 100);
        return { dashArray: progressLength.toString(), dashOffset: Math.max(0, progressBarOffset).toString() };
      } else {
        return { dashArray: '', dashOffset: '' };
      }
    };
    const progress = getProgressOffset();
    setProgressOffset({ dashArray: progress.dashArray, dashOffset: progress.dashOffset });
  }, [progressValue]);

  return (
    <Styled.Svgindicator
      id={id}
      className={className}
      configStyles={configStyles}
      height={height}
      viewBox={viewBox}
      width={width}
    >
      <Styled.TrackPath d={track} />
      <Styled.ProgressPath
        d={progressBar}
        ref={progressRef}
        strokeDasharray={progressOffest.dashArray}
        strokeDashoffset={progressOffest.dashOffset}
      />
    </Styled.Svgindicator>
  );
}
