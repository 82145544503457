import styled from 'styled-components';

const columnWidth = {
  1: 8.33333333,
  2: 16.66666667,
  3: 25,
  4: 33.33333333,
  5: 41.66666667,
  6: 50,
  7: 58.33333333,
  8: 66.666667,
  9: 75,
  10: 83.33333333,
  11: 91.66666667,
  12: 100,
};

export const Columns = styled.div<any>`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  ${(props) => {
    return `flex: 0 0 ${columnWidth[props.columnSpan]}%; max-width: ${columnWidth[props.columnSpan]}%;`;
  }}
  ${(props) => props.configStyles};
`;
