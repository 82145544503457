import React from 'react';
import * as Styled from './url.styled';
export type UrlProps = {
  /**
   * autocomplete allows the browser to predict the value.
   */
  autoComplete?: string;

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * To disable the input[url] field.
   */
  disabled?: boolean;

  /**
   * To identifies the input[url] semantic element.
   */
  id: string;

  /**
   * name specifies a name for an input element.
   */
  name: string;

  /**
   * A function to be triggered when the input element loses focus
   */
  onBlur?: () => void;

  /**
   * A function to be triggered when the input element changes its value
   */
  onChange?: (e) => void;

  /**
   * A function to be triggered when the input element gets focus
   */
  onFocus?: () => void;

  /**
   * Pattern is a regular expression that the input's value must match in order for the value to pass constraint validation.
   */
  pattern?: string;

  /**
   * Placeholder is a string that provides a brief hint to the user as to what kind of information is expected in the field.
   */
  placeholder?: string;

  /**
   * readOnly is a Boolean attribute which, if present, means this field cannot be edited by the user.
   */
  readOnly?: boolean;

  /**
   * required specifies that an input field must be filled out before submitting the form
   */
  required?: boolean;

  /**
   * Value contains a string which is automatically validated as conforming to URL syntax.
   */
  value?: string;
};

export function Url({
  autoComplete = 'off',
  className,
  configStyles,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  readOnly = false,
  required,
  value,
}: UrlProps) {
  return (
    <Styled.Input
      autoComplete={autoComplete}
      className={className}
      configStyles={configStyles}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      pattern={pattern}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      type="url"
      value={value}
    />
  );
}
