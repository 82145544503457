import { useContext, useMemo } from 'react';

// Context
import { ViewportContext } from 'Application/context/viewport';

export const useProps = (viewport: any, attr: { [key: string]: any } | null) => {
  const { breakpoint, breakpoints } = useContext(ViewportContext);

  const props = useMemo(() => {
    const sb =
      viewport &&
      [...viewport].sort((a, b) => parseInt(breakpoints[a.breakpoint], 10) - parseInt(breakpoints[b.breakpoint], 10));

    if (sb !== null && sb.some((bp: any) => bp.breakpoint === breakpoint)) {
      const { attributes } = sb.find((bp: any) => bp.breakpoint === breakpoint);
      return { ...attr, ...attributes };
    } else if (
      sb !== null &&
      parseInt(breakpoints[sb.slice(-1)[0].breakpoint], 10) < parseInt(breakpoints[breakpoint], 10)
    ) {
      const { attributes } = sb.slice(-1)[0];
      return { ...attr, ...attributes };
    } else return attr;
  }, [breakpoint]);

  return props;
};
