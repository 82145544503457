import { createGlobalStyle } from 'styled-components';

export const GlobalStylesResets = createGlobalStyle`html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: baseline; 
      letter-spacing: 0.25px;
  }

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
      display: block;
  }

  html, body{
    height: 100%;
  }

  html{
    font-size: ${(props) => props.theme['typography']['baseMobileSize']}
  }

  @media screen and (min-width: ${(props) => props.theme['breakpoints']['sm']}) {
    html{
      font-size: ${(props) => props.theme['typography']['baseScreenSize'] || '16px'}
    }
  }

  body {
      line-height: 1;
  }

  ol, ul {
      list-style: none;
  }

  blockquote, q {
      quotes: none;
  }
  
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }

  table {
      border-collapse: collapse;
      border-spacing: 0;
  }
  
  * {
    font-family: ${(props) => props.theme['typography']['fontFace']}
  }
`;
