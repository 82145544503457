import styled from 'styled-components';

export const Otpverification = styled.div<{ isSubmitting: boolean | undefined }>`
  div {
    display: flex;
    > button {
      white-space: nowrap;
      margin-top: 0;
      margin-left: ${(props) => props.theme.spacings.sm || '0.75rem'};
      padding: ${(props) => props.theme.spacings.sm || '0.75rem'} ${(props) => props.theme.spacings.xlg || '2rem'};
      &:disabled {
        background-color: ${(props) =>
          props.isSubmitting ? props.theme.colors.primary2 : props.theme.colors.darkGray2};
      }
    }
    > input {
      font-size: ${(props) => props.theme.typography.base1};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      flex-direction: column;
      > button {
        margin-left: 0;
        margin-top: ${(props) => props.theme.spacings.default || '1rem'};
        padding: ${(props) => props.theme.spacings.default || '1rem'} ${(props) => props.theme.spacings.xlg || '2rem'};
      }
      > div > div {
        flex-direction: row;
        justify-content: start;
      }
    }
  }
`;

export const PhoneNumberLabel = styled.span`
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
`;

export const ResendButton = styled.button`
  border: none;
  padding: 0;
  background: 0;
  font-weight: bold;
  font-size: ${(props) => props.theme.typography.base3};
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary2};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary3};
  }
`;
