import styled from 'styled-components';
import { LinkProps } from './link';

import { Styled } from '@pefai/scl-react.components.actionable.buttons.button';

export const LinkText = styled.a<LinkProps>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary3};
  cursor: pointer;
  &:hover,
  :focus {
    color: ${(props) => props.theme.colors.primary3};
  }
  &:active {
    color: ${(props) => props.theme.colors.primary1};
  }

  ${(props) => props.configStyles};
`;

export const LinkButton = Styled.Button;
