import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { ExtraModelsFromLoading } from '@rematch/loading';
import { models, RootModel } from './Models/OneApp/index';

type FullModel = ExtraModelsFromLoading<RootModel, { type: 'full' }>;

export const store = init<RootModel, FullModel>({
  models,
  plugins: [],
  redux: {
    devtoolOptions: {
      disabled: false, // true on local off everywhere else
    },
  },
});

export const { dispatch } = store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
