import React, { ReactNode } from 'react';
import * as Styled from './link.styled';

export type LinkProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * for content nested within the semantic a tag
   */
  children: ReactNode | ReactNode[];

  /**
   * class names override
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /* *
   * configurable url attribute
   */
  href: string;

  /**
   * browsing context
   */
  target?: '_self' | '_blank' | '_parent' | '_top';

  /**
   * configurable link variant
   */
  variant?: 'text' | 'button-primary' | 'button-secondary' | 'button-tertiary' | 'button-success' | 'button-danger';
};

export function Link({ children, variant = 'text', href, configStyles, className, target, id }: LinkProps) {
  return (
    <>
      {variant === 'text' ? (
        <Styled.LinkText
          id={id}
          className={className}
          configStyles={configStyles}
          href={href}
          target={target}
          variant={variant}
        >
          {children}
        </Styled.LinkText>
      ) : (
        <Styled.LinkButton
          id={id}
          as={'a'}
          className={className}
          configStyles={configStyles}
          href={href}
          target={target}
          variant={variant}
        >
          {children}
        </Styled.LinkButton>
      )}
    </>
  );
}
