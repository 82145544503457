import styled from 'styled-components';

export const FormField = styled.div<{
  validationStatus: any;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacings.default || '1rem'};

  ${(props) => {
    if (props.disabled === true) {
      return `
        ${FormFieldLabel},
        ${FormFieldMsg},
        ${FormFieldHelpTxt} {
          color: ${props.theme.colors.darkGray4};
        };
        ${FormFields}{
          textarea,
          input,
          .react-select--is-disabled {
            color: ${props.theme.colors.darkGray4};
            border-color: ${props.theme.colors.darkGray2};

            &:hover{
              border-color: ${props.theme.colors.darkGray2};
            }

            .react-select__placeholder,
            &::placeholder{
              color: ${props.theme.colors.darkGray2};
            }
          }
        }
      }`;
    }
  }}

  ${(props) => {
    if (props.validationStatus === false) {
      return `
      ${FormFields}{
        .react-select__control,
        input:not([type='radio']):not([type='checkbox']),
        textarea,
        div[role='listbox'] > button {
          border-color: ${props.validationStatus === false && props.theme.colors.negative2};
          
          &:focus {
            border-color: ${props.validationStatus === false && props.theme.colors.negative1};
          }
        }
      }

      ${FormFields}{
        input[type='radio'],
        input[type='checkbox'],
         input[type='radio'] + label,
         input[type='checkbox'] + label {
          border-color: ${props.validationStatus === false && props.theme.colors.negative2};
          color: ${props.validationStatus === false && props.theme.colors.negative2};
        }
      }

      ${FormFieldMsg}{
        color: ${props.validationStatus === false && props.theme.colors.negative2};
      }
      `;
    }
  }}
`;

export const FormFieldLabel = styled.label`
  margin: ${(props) => props.theme.spacings.sm} 0 ${(props) => props.theme.spacings.sm} 0.13rem;
  font-size: ${(props) => props.theme.typography.base3};
  color: ${(props) => props.theme.colors.darkGray3};
`;

export const FormFields = styled.div<{
  contentOrder?: 'rows' | 'columns' | 'grid' | undefined;
  contentOrderGrid?: {
    rows: number | undefined;
    columns: number | undefined;
  };
}>`
  margin: 0 0 ${(props) => props.theme.spacings.xsm} 0;
  ${(props) => {
    switch (props.contentOrder) {
      case 'rows':
        return `display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; gap: 1rem;`;
      case 'columns':
        return `display: flex; flex-direction: column; flex-wrap: wrap; gap: 1rem;`;
      case 'grid':
        return `
          display:grid; 
          grid-gap: 1rem;
          grid-template-columns: repeat(${props?.contentOrderGrid?.columns}, 1fr);
          ${props?.contentOrderGrid?.rows && `grid-template-rows: repeat(${props.contentOrderGrid.rows}, 1fr);`}
        `;
    }
  }};

  input:not([type='radio']):not([type='checkbox']),
  textarea {
    border-radius: ${(props) => props.theme.radius.sm};

    &::placeholder {
      color: ${(props) => props.theme.colors.darkGray2};
    }
  }

  input[type='radio'] {
    padding: 0;
    width: 12px;
    height: 12px;
    box-sizing: content-box;
  }

  input[type='checkbox'] {
    padding: 0;
    width: 12px;
    height: 12px;
    box-sizing: content-box;
    border-radius: ${(props) => props.theme.borders.radiusSm};
  }

  div > label > p {
    font-size: ${(props) => props.theme.typography.base1};
  }
`;

export const FormFieldHelpTxt = styled.p`
  font-size: ${(props) => props.theme.typography.base3};
  color: ${(props) => props.theme.colors.darkGray3};
  margin-bottom: ${(props) => props.theme.spacings.xsm};
`;

export const FormFieldMsg = styled.p`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.typography.base3};
  color: ${(props) => props.theme.colors.darkGray3};
`;
