import React, { ReactNode } from 'react';
import * as Styled from './heading.styled';

export type HeadingProps = {
  /**
   * for content nested inside the semantic H# tag
   */
  children: ReactNode | ReactNode[];

  /**
   * placeholder for external stylesheets
   */
  className?: string;
  /**
   * To identify the component
   */
  id?: string;

  /**
   * configurable color property
   */
  color?: string;

  /**
   * for component level styling override (Design System)
   * @config implementation required
   */
  configStyles?: string;

  /**
   * for semantic Heading element levels
   * [H1, H2, H3, H4, H5, H6]
   */
  level: 1 | 2 | 3 | 4 | 5 | 6;

  /**
   * configurable font-weight property
   */
  weight?: 'normal' | 'bold';
};

export function Heading({ level = 1, children, weight, configStyles, color, className, id }: HeadingProps) {
  const headingLevel: any = `h${level}`;
  return (
    <Styled.Heading
      id={id}
      as={headingLevel}
      className={className}
      color={color}
      configStyles={configStyles}
      level={level}
      weight={weight}
    >
      {children}
    </Styled.Heading>
  );
}
