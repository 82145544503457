import { useState, useEffect } from 'react';
import { useFormikContext, useField as useInput } from 'formik';

// Utils
import utils from 'Application/utils';

type FormSettings = {
  type: 'field' | 'meta' | 'form';
  name: string;
  visible: any | boolean;
  transform?: 'upperCase' | 'lowerCase';
};

// Field: used to read/write a single field's context
// Usage: form elements like input, text area, radios, ...
const useField = ({ name, visible, transform }: FormSettings) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useInput(name);

  const handleChange = (transform: string) => {
    switch (transform) {
      case 'upperCase':
        return (e) => {
          let value = e.target.value || '';
          value = value.toUpperCase();
          e.target.value = value;
          helpers.setValue(value);
        };
      case 'lowerCase':
        return (e) => {
          let value = e.target.value || '';
          value = value.toLowerCase();
          e.target.value = value;
          helpers.setValue(value);
        };
      default:
        return field.onChange;
    }
  };

  return {
    checked: field.checked,
    isSubmitting,
    meta: meta,
    onBlur: field.onBlur,
    onChange: handleChange(transform),
    setError: helpers.setError,
    setTouched: helpers.setTouched,
    setValue: helpers.setValue,
    value: field.value,
    visible,
  };
};

// Meta: used to read a single field's context
// Usage: A component that reads field errors
const useMeta = ({ name, visible }: { name: string; visible: boolean }) => {
  const { getFieldMeta, isSubmitting } = useFormikContext();

  return {
    isSubmitting,
    meta: getFieldMeta(name),
    visible,
  };
};

// Element: used to be able to read the forms context
const useElement = ({ visible }: FormSettings) => {
  const { values, isSubmitting, status, isValid, errors, dirty, setErrors, setStatus } = useFormikContext();

  return {
    formStatus: status,
    hasErrors: errors,
    isDirty: dirty,
    isFormValid: isValid,
    isSubmitting,
    formValues: values,
    setErrors,
    setStatus,
    status,
    visible,
  };
};

const Form: { [key: string]: any } = Object.freeze({
  field: useField,
  meta: useMeta,
  form: useElement,
});

export const useForm = ({ type, ...settings }: FormSettings) => {
  const { values, isValid, setFieldValue } = useFormikContext();
  const [visible, setVisible] = useState(true);

  const clearValue = (currentValue: any) => {
    if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
      return Object.entries(currentValue).reduce(utils.Object.replacer(''), {});
    } else if (Array.isArray(currentValue)) return [];
    else return '';
  };

  useEffect(() => {
    if (settings.visible) {
      const show = utils.Array.arrayEvaluator(settings.visible, (condition): boolean => {
        if (condition.ref !== 'form') {
          //Form field
          const value = utils.Object.resolvePath(values, condition.ref);
          return utils.Validation.test.test(condition.key, value, condition.val);
        } else {
          //Form
          return utils.Validation.test.test(condition.key, isValid.toString(), condition.val.toString());
        }
      });
      if (!show && settings.name) setFieldValue(settings.name, clearValue(values[settings.name]));
      setVisible(show);
    }
  }, [values, isValid]);

  return Form[type]({ ...settings, visible });
};
