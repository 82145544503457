var config = function (DKI, BURL, PFSEK, PK) {
  // -------------------------------------
  // REQUIRED
  // Available at https://dev.facetec.com/#/account
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var DeviceKeyIdentifier = DKI;

  // -------------------------------------
  // REQUIRED
  // The URL to call to process FaceTec SDK Sessions.
  // In Production, you likely will handle network requests elsewhere and without the use of this variable.
  // See https://dev.facetec.com/#/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.

  // -------------------------------------
  // REQUIRED
  // The URL to call to process FaceTec SDK Sessions.
  // In Production, you likely will handle network requests elsewhere and without the use of this variable.
  // See https://dev.facetec.com/#/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var BaseURL = BURL;

  // -------------------------------------
  // REQUIRED
  // The FaceMap Encryption Key you define for your application.
  // Please see https://dev.facetec.com/#/keys?link=keys for more information.
  // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
  var PublicFaceScanEncryptionKey = PFSEK;

  /*YOUR_PRODUCTION_KEY FROM {{BaseURL}}/getInitString/web*/
  var ProductionKey = PK;

  // -------------------------------------
  // Convenience method to initialize the FaceTec SDK.
  // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
  function initializeFromAutogeneratedConfig(FaceTecSDK, callback) {
    /* Development Mode  */
    /*
          FaceTecSDK.initializeInDevelopmentMode(this.DeviceKeyIdentifier, this.PublicFaceScanEncryptionKey,
              function (initializedSuccessfully) {
                  callback(initializedSuccessfully);
              });
          */
    /* Production Mode */
    FaceTecSDK.initializeInProductionMode(
      this.ProductionKey,
      this.DeviceKeyIdentifier,
      this.PublicFaceScanEncryptionKey,
      function (initializedSuccessfully) {
        callback(initializedSuccessfully);
      },
    );
  }

  // This app can modify the customization to demonstrate different look/feel preferences
  // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
  function retrieveConfigurationWizardCustomization(FaceTecSDK) {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    defaultCustomization.overlayCustomization.showBrandingImage = false;
    this.currentCustomization = defaultCustomization;
    return defaultCustomization;
  }

  var currentCustomization;

  // -------------------------------------
  // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
  // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's Design Showcase,
  // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
  // Configuration Wizard.
  var wasSDKConfiguredWithConfigWizard = false;

  return {
    wasSDKConfiguredWithConfigWizard: wasSDKConfiguredWithConfigWizard,
    DeviceKeyIdentifier: DeviceKeyIdentifier,
    BaseURL: BaseURL,
    PublicFaceScanEncryptionKey: PublicFaceScanEncryptionKey,
    ProductionKey: ProductionKey,
    initializeFromAutogeneratedConfig: initializeFromAutogeneratedConfig,
    currentCustomization: currentCustomization,
    retrieveConfigurationWizardCustomization: retrieveConfigurationWizardCustomization,
  };
};

export default config;
