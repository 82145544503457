import styled from 'styled-components';
import { ButtonProps } from './button';

export const Button = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => props.theme.borders.normal || '0.0625rem'} solid transparent;
  border-radius: ${(props) => {
    return props.theme.radius.sm;
  }};
  padding: ${(props) => `${props.theme.spacings.default || '3.5rem'} ${props.theme.spacings.xlg || '1.25rem'}`};
  width: ${(props) => (props.size === 'full' ? '100%' : 'auto')};
  gap: ${(props) => props.theme.spacings.sm};
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
  outline: 0;

  &:hover:not(:disabled) {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
  }

  &:focus {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
  }

  cursor: pointer;

  &:focus-visible,
  &:focus,
  &:hover,
  &:active,
  &:disabled {
    outline: 1px solid transparent;
  }

  ${(props) => applyTheme(props.variant, props.theme)};
  ${(props) => props.configStyles};
`;

const applyTheme = (buttonType: string, theme) => {
  switch (buttonType) {
    case 'button-primary':
      return `
        background-color: ${theme.colors.primary2 || '#004DAD'};
        color: ${theme.colors.white || 'white'};
        
        &:hover:not(:disabled){
          background-color: ${theme.colors.primary3 || '#003E8C'};
        }
        &:active:not(:disabled){
          background-color: ${theme.colors.primary2 || '#004DAD'};
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }
        &:focus{
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }
        &:focus-visible {
          background-color: ${theme.colors.primary3 || '#003E8C'};
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }
        &:disabled{
          background-color: ${theme.colors.grayDark1 || '#BAC5D4'};
          cursor: default;
        }
      `;
    case 'button-secondary':
      return `
        border-color: ${theme.colors.primary2 || '#004DAD'};
        background-color: ${theme.colors.white || 'white' || '#'};
        color: ${theme.colors.primary2 || '#004DAD'};
    
        &:hover:not(:disabled){
          border-color: ${theme.colors.primary3 || '#003E8C'};
          color: ${theme.colors.primary3 || '#003E8C'};
          border: ${theme.borders.normal} solid ${theme.colors.primary3};
        }

        &:active:not(:disabled){
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }

        &:focus{
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }

        &:focus-visible {
          border-color: ${theme.colors.primary4 || '#'};
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }

        &:disabled{
          border-color: ${theme.colors.darkGray3 || '#BAC5D4'};
          background-color: ${theme.colors.lightGray2 || '#F7F9FC'};
          color: ${theme.colors.darkGray3 || '#BAC5D4'};
          cursor: default;
        }
      `;
    case 'button-tertiary':
      return `
        background-color: ${theme.colors.tertiary1 || '#F0F8FF'};
        color: ${theme.colors.primary3 || '#003E8C'};
            
        &:hover:not(:disabled){
          background-color: ${theme.colors.tertiary2 || '#D6EEFF'};
        }
        &:active:not(:disabled){
          background-color: ${theme.colors.tertiary4 || '#F0F8FF'};
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }
        &:focus{
          background-color: ${theme.colors.tertiary4 || '#F0F8FF'};
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }
        &:focus-visible {
          background-color: ${theme.colors.tertiary2 || '#D6EEFF'};
          border: ${theme.borders.normal} solid ${theme.colors.primary1};
        }
        &:disabled{
          background-color: ${theme.colors.lightGray1 || '#F0F4FA'};
          color: ${theme.colors.darkGray3 || '#BAC5D4'};
          cursor: default;
        }    
      `;
    case 'button-danger':
      return `
          background-color: ${theme.colors.negative1 || '#D81A49'};
          color: ${theme.colors.white || 'white'};
          
          &:hover:not(:disabled){
            background-color: ${theme.colors.negative2 || '#AF0A45'};
            border: ${theme.borders.normal} solid ${theme.colors.negative1};
          }
          &:active:not(:disabled){
            background-color: ${theme.colors.negative1 || '#D81A49'};
            border: ${theme.borders.normal} solid ${theme.colors.negative1};
          }
          &:focus{
            background-color: ${theme.colors.negative1 || '#D81A49'};
            border: ${theme.borders.normal} solid ${theme.colors.negative1};
          }
          &:focus-visible {
            background-color: ${theme.colors.negative2 || '#AF0A45'};
            border: ${theme.borders.normal} solid ${theme.colors.negative1};
          }
          &:disabled{
            background-color: ${theme.colors.grayDark1 || '#BAC5D4'};
            cursor: default;
          }
        `;
    case 'button-success':
      return `
          background-color: ${theme.colors.positive1 || '#0DA1A1'};
          color: ${theme.colors.white || 'white'};
          
          &:hover:not(:disabled){
            background-color: ${theme.colors.positive2 || '#026363'};
            border: ${theme.borders.normal} solid ${theme.colors.positive1};
          }
          &:active:not(:disabled){
            border: ${theme.borders.normal} solid ${theme.colors.positive1};
          }
          &:focus{
            border: ${theme.borders.normal} solid ${theme.colors.positive1};
          }
          &:focus-visible {
            background-color: ${theme.colors.positive2 || '#026363'};
            border: ${theme.borders.normal} solid ${theme.colors.positive2};
          }
          &:disabled{
            background-color: ${theme.colors.grayDark1 || '#BAC5D4'};
            cursor: default;
          }
          `;
    case 'text':
      return `
          background-color: transparent;
          color: ${theme.colors.black || 'black'};

          &:active:not(:disabled){
            border: ${theme.borders.normal} solid ${theme.colors.primary1};
          }
          &:focus{
            border: ${theme.borders.normal} solid ${theme.colors.primary1};
          }
          &:focus-visible {
            border: ${theme.borders.normal} solid ${theme.colors.primary1};
          }
          
          `;
    default:
      break;
  }
};
