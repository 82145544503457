import styled from 'styled-components';

export const StaticCalendar = styled.div<{
  configStyles: string | undefined;
}>`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary2};
  cursor: default;

  ${(props) => props.configStyles};
`;

export const CalendarHader = styled.div.attrs({ role: 'heading' })`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacings.xlg};

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0.25rem;
    margin-left: ${(props) => props.theme.spacings.xsm};
    width: ${(props) => props.theme.spacings.xlg};
    height: ${(props) => props.theme.spacings.xlg};
    border-radius: 50%;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.white};

    & > i {
      font-size: ${(props) => props.theme.typography.heading3};
    }
  }
`;

export const HeaderTitle = styled.div`
  flex: 1;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.typography.base1};
`;

export const NavButtonPrev = styled.button.attrs({ role: 'button', type: 'button' })``;

export const NavButtonNext = styled.button.attrs({ role: 'button', type: 'button' })`
  margin-left: ${(props) => props.theme.spacings.xsm};

  & > i {
    transform: rotate(180deg);
  }
`;

export const NavButtonIcon = styled.i`
  color: ${(props) => props.theme.colors.secondary1};
`;

export const CalendarMain = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.spacings.xsm};
`;

export const CalendarWeekdays = styled.div.attrs({ role: 'grid' })`
  display: flex;
  justify-content: space-between;
`;

export const WeekdayPill = styled.div<{
  isToday?: boolean | undefined;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.default};
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isToday ? props.theme.colors.black : props.theme.colors.white)};
  max-width: 2.3rem;
  height: ${(props) => props.theme.spacings.xxlg};
  background-color: ${(props) => (props.isToday ? props.theme.colors.white : 'transparent')};
  border-radius: ${(props) => props.theme.spacings.xlg};
`;

export const Weekday = styled.div.attrs({ role: 'columnheader' })`
  font-size: ${(props) => props.theme.typography.base3};
`;

export const WeekdayDate = styled.div.attrs({ role: 'gridcell' })`
  font-size: ${(props) => props.theme.typography.base1};
`;

export const CalendarMonthweeks = styled.div.attrs({ role: 'grid' })`
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  //flex: 1 0 0;
`;

export const Monthweek = styled.div.attrs({ role: 'row' })`
  display: flex;
  justify-content: space-between;
`;

export const MonthweekDays = styled.div.attrs({ role: 'row' })`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacings.sm};
`;

export const MonthweekText = styled.div.attrs({ role: 'columnheader' })`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.typography.base3};
  color: ${(props) => props.theme.colors.white};
  padding: 0.25rem;
  max-width: ${(props) => props.theme.spacings.lg};
  height: ${(props) => props.theme.spacings.lg};
`;

export const MonthDay = styled.div.attrs({ role: 'gridcell' })<{
  isToday?: boolean | undefined;
  isOutOfRange?: boolean | undefined;
}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.typography.base1};
  color: ${(props) => (props.isOutOfRange ? props.theme.colors.darkGray1 : props.theme.colors.white)};
  padding: 0.25rem;
  max-width: ${(props) => props.theme.spacings.lg};
  height: ${(props) => props.theme.spacings.lg};
  background-color: ${(props) => (props.isToday ? props.theme.colors.secondary4 : 'transparent')};
  border-radius: ${(props) => props.theme.spacings.xlg};
`;
