import React, { ReactNode } from 'react';

import * as Styled from './toggle.styled';

export type ToggleProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * checked state
   */
  checked?: boolean;

  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  /**
   * a styling element to inherit active status
   */
  disabled?: boolean | undefined;

  /**
   * Convention [Forms] : This prop is used to determine if a user input field has been validated or not
   */
  validationStatus?: boolean | undefined;

  /**
   * toggle value
   */
  value?: string;
};

export function Toggle({ checked, validationStatus = undefined, children, disabled = false, id }: ToggleProps) {
  return (
    <Styled.Toggle validationStatus={validationStatus} disabled={disabled} checked={checked} id={id}>
      <Styled.ToggleSwitch />
      <Styled.ToggleLabel>{children}</Styled.ToggleLabel>
    </Styled.Toggle>
  );
}
