import { Fragment } from 'react';
import styled from 'styled-components';

// Although this is a frowned upon practice (styling a fragment), this serves as a template for our text components
export const Element = styled(Fragment)<{ configStyles: string }>`
  ${(props) => props.configStyles};
`;

export const TextInput = styled.textarea<{ height: number }>`
  width: 100%;
  height: fit-content;
  height: ${(props) => (props.height > 1 ? `${props.height}px` : 'auto')};
  border: none;
  overflow: auto;

  -webkit-box-shadow: none;

  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
  overflow: hidden;
`;

export const EditableElement = styled.span``;
