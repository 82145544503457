import styled from 'styled-components';

export const PdfBook = styled.div`
  display: inline-block;
  width: 100%;
`;
export const PdfDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacings.xs || '0.5rem'};
  background-color: ${(props) => props.theme.colors.grayDark4 || '#E4E8ED'};
  color: ${(props) => props.theme.colors.grayDark1 || '#778aa5'};
`;

export const PdfControls = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.sm || '1rem'};
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.whtie || '#fff'};
`;
export const ControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 ${(props) => props.theme.spacings.xs || '0.5rem'};
  border-radius: ${(props) => props.theme.spacings.xs || '0.5rem'};
  background-color: ${(props) => props.theme.colors.white || '#fff'};

  & button:first-child,
  & a:first-child {
    border-top-left-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
    border-bottom-left-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
  }

  & button:last-child,
  & a:last-child {
    border-top-right-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
    border-bottom-right-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ControlButton = styled.button.attrs({ type: 'button' })<any>`
  width: ${(props) => props.theme.spacings.lg || '2rem'};
  height: ${(props) => props.theme.spacings.lg || '2rem'};
  border: none;
  background-color: ${(props) => props.theme.colors.white || '#fff'};
  color: ${(props) => props.theme.colors.primary2 || '#004dad'};

  &:disabled {
    background-color: ${(props) => props.theme.colors.grayDark3 || '#bac5d4'};
    color: ${(props) => props.theme.colors.white || '#fff'};
  }

  &:hover:not(:disabled) {
    background-color: ${(props) => props.theme.colors.grayDark3 || '#bac5d4'};
    cursor: pointer;
  }

  ${(props) => {
    if (props.downloadable === false) {
      return `
        pointer-events: none;
        cursor: default;
        background-color: ${props.theme.colors.grayDark3 || '#bac5d4'};
        color: ${props.theme.colors.white || '#fff'};
      `;
    }
  }}
`;

export const PaginationSpan = styled.span`
  margin: 0 ${(props) => props.theme.spacings.xs || '0.5rem'};
  color: ${(props) => props.theme.colors.primary2 || '#004dad'};
  background-color: ${(props) => props.theme.colors.white || '#fff'};
`;
