import { Models } from '@rematch/core';

import { OneAppModel } from './OneApp.model';
import { CredentialsModel } from './Credentials.model';
import { ComponentsModel } from './Components.model';

export interface RootModel extends Models<RootModel> {
  OneAppModel: typeof OneAppModel;
  CredentialsModel: typeof CredentialsModel;
  ComponentsModel: typeof ComponentsModel;
}

export const models: RootModel = { OneAppModel, CredentialsModel, ComponentsModel };
