import React, { ReactNode, useState } from 'react';
import * as Styled from './dropdown.styled';

export type DropdownProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * a list of children to be rendered as the dropdown items
   */
  children: ReactNode[];

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * to disable the dropdown
   */
  disabled?: boolean;

  /**
   * A function to be triggered when the input element loses focus.
   */
  onBlur?: () => void;

  /**
   * A function to be triggered when an option from the list has been chosen.
   */
  onChange?: (e) => void;

  /**
   * the default child of the button element
   */
  placeholder?: string;

  /**
   * the child of the button element
   */
  value?: string;
};

export function Dropdown({
  id,
  children,
  configStyles,
  value,
  placeholder,
  disabled,
  className,
  onBlur,
  onChange,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Styled.Dropdown className={className} configStyles={configStyles} id={id}>
      <Styled.DropdownButton
        onBlur={onBlur}
        onChange={onChange}
        onClick={toggleDropdown}
        disabled={disabled}
        isOpen={isOpen}
        value={value}
        type={'button'}
      >
        {value ? value : placeholder}
        <Styled.DropdownIcon isOpen={isOpen}>
          <i className="zmdi zmdi-chevron-down"></i>
        </Styled.DropdownIcon>
      </Styled.DropdownButton>
      {isOpen && (
        <Styled.DropdownItems>
          {Array.isArray(children) &&
            children.map((child, index) => (
              <li
                key={index}
                onClick={() => {
                  toggleDropdown();
                }}
              >
                {child}
              </li>
            ))}
        </Styled.DropdownItems>
      )}
    </Styled.Dropdown>
  );
}
