import styled from 'styled-components';
import { DropdownProps } from './dropdown';

export const Dropdown = styled.div<{ configStyles: string | undefined }>`
  position: relative;
  width: 100%;
  ${(props) => props.configStyles};
`;

export const DropdownButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: ${(props) => props.theme.borders.normal || '0.0625rem'} solid
    ${(props) => props.theme.colors.darkGray2 || '#778AA5'};
  border-radius: ${(props) => props.theme.radius.sm || '0.05rem'};
  padding: ${(props) => `${props.theme.spacings.sm || '0.75rem'} ${props.theme.spacings.md || '1.25rem'}`};
  background-color: ${(props) => props.theme.colors.white || '#FFFFFF'};
  color: ${(props) => (!props.value ? props.theme.colors.darkGray2 || '#9BABC1' : props.theme.colors.black)};
  &:hover:not(:disabled),
  :active {
    border-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
  }
  &:focus,
  &:focus-visible {
    color: ${(props) => props.theme.colors.primary4 || '#002752'};
    border-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1 || '#0279FE'};
    outline: none;
  }
  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.colors.darkGray2 || '#9BABC1'};
    border-color: ${(props) => props.theme.colors.darkGray2 || '#9BABC1'};
    background-color: ${(props) => props.theme.colors.lightGray1 || '#F0F4FA'};
  }
`;

export const DropdownIcon = styled.span<{ isOpen: boolean }>`
  margin-left: ${(props) => props.theme.spacings.default || '1rem'};
  transform: rotate(${(props) => (props.isOpen === true ? '180deg' : '0deg')});
`;

export const DropdownItems = styled.ul.attrs({ role: 'listbox' })`
  position: absolute;
  margin-top: 1px;
  width: 100%;
  list-style: none;
  padding-left: 0;
  background-color: ${(props) => props.theme.colors.white || 'white'};
  border-radius: ${(props) => props.theme.radius.sm || '0.05rem'};
  overflow: hidden;
  box-shadow: ${(props) => props.theme.shadows.lightest};
  border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray4};
  box-sizing: border-box;
  z-index: 100;
  > li > * {
    cursor: pointer;
    border: ${(props) => props.theme.borders.normal || '0.0625rem'} solid transparent;
    padding: ${(props) => `${props.theme.spacings.sm || '0.75rem'} ${props.theme.spacings.md || '1.25rem'}`};
    margin: 0;
    display: block;
    color: ${(porps) => porps.theme.colors.darkGray3 || '#436087'};
    font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
    outline: 0;

    &:hover {
      border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
    }

    &:focus {
      border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
    }

    &:hover {
      background-color: ${(porps) => porps.theme.colors.tertiary2};
      color: ${(porps) => porps.theme.colors.primary4 || '#002752'};
    }
    &:active {
      background-color: ${(porps) => porps.theme.colors.primary2 || '#004DAD'};
      color: ${(porps) => porps.theme.colors.white || 'white'};
    }
    &:focus,
    &:focus-visible {
      border-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
      box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1 || '#0279FE'};
      outline: none;
    }
  }
`;
