import OneAppAPIHandler from '../../../../Infrastructure/API/OneAppApiHandler';
import { BASECONFIG_ENDPOINTS } from '../../../Common/Endpoints/OneApp/index';

interface IComponentsModel {}

const ComponentsModelInitialState: IComponentsModel = {};

const ComponentsModel = {
  state: {
    ...ComponentsModelInitialState,
  },
  reducers: {
    BASE_TOGGLE_BOOLEAN(state, modelName, affectedProperty) {
      return {
        ...state,
        [modelName]: {
          ...state[modelName],
          [affectedProperty]: !state[modelName][affectedProperty],
        },
      };
    },
    BUILD_DYNAMIC_STATE(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    async buildDynamicState(payload) {
      dispatch.ComponentsModel.BUILD_DYNAMIC_STATE(payload);
    },
    async baseEffects({ effectActionName, modelName, affectedProperty }) {
      await dispatch.ComponentsModel[effectActionName](modelName, affectedProperty);
    },
  }),
};

export { ComponentsModel };
