import OneAppAPIHandler from '../OneAppApiHandler';
import { CREDENTIALS_ENDPOINTS } from '../../../Application/Common/Endpoints/OneApp/index';

const AUTH = {
  getSession: (organizationId: string) => {
    return OneAppAPIHandler.POST(CREDENTIALS_ENDPOINTS.GET_CREDENTIALS, { organizationId: organizationId });
  },
};

export { AUTH };
