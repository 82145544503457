import styled from 'styled-components';
export const SearchInput = styled.input<{ _configStyles?: string }>`
  box-sizing: border-box;
  border: ${(props) => props.theme.borders.normal || '0.0625rem'} solid transparent;
  width: 100%;
  height: ${(props) => props.theme.spacings.xlg || '2rem'};
  padding: 0 ${(props) => props.theme.spacings.sm || '0.75rem'};
  border-radius: 3px;
  font-size: ${(props) => props.theme.typography.base2 || '0.875rem'};
  outline: none;
  text-overflow: ellipsis;
  position: ${(props) => (props._configStyles === 'standaloneSearchbox' ? 'relative' : 'absolute')};
  &:active {
    border-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
  }
  &:focus,
  &:focus-visible {
    border-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1 || '#0279FE'};
    outline: none;
  }
`;
