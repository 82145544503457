import React, { useState } from 'react';
import { Formik, useFormikContext } from 'formik';

// Component
import { Component } from 'Presentation/component/component/Component';

// Utils
import utils from 'Application/utils';

// Hooks
import { useDebounce } from 'Application/hooks';

// API
import { OneAppAPI } from 'Infrastructure/API/Configs/OneApp.api';

type Values = {
  [field: string]: any;
};

type CatalogProps = {
  resultsFallbackMessage: string;
  childPreloaderId: string;
  query_id: string;
  query: Values;
  children: React.ReactNode[];
};

// ToDo: Catalog & Conditional, abstract similar behaviors
const AutoSubmit = ({ children, preloaderConfig }) => {
  const { dirty, isSubmitting, values, submitForm } = useFormikContext();
  const submit = () => dirty && !isSubmitting && submitForm();
  useDebounce(submit, 500, [values]);
  return !!isSubmitting ? <>{preloaderConfig.current}</> : children;
};

const Catalog = ({ query_id, query, children, childPreloaderId, resultsFallbackMessage }: CatalogProps) => {
  const [results, setResults] = useState([]);
  const preloaderConfig = React.useRef<React.ReactNode>(
    children.filter((component) => {
      return component['props'].id === childPreloaderId;
    }),
  );

  const onSubmit = async (values: Values, { setSubmitting }: Values) => {
    try {
      const search = await OneAppAPI.postComponent(
        query_id,
        Object.entries(values).reduce(utils.Object.replacer(null), {}),
      );
      setResults(search.length ? search : []);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={Object.entries(query).reduce(utils.Object.replacer(''), {})} onSubmit={onSubmit}>
      {({ dirty, submitCount }) => (
        <>
          {children.filter((component) => {
            return component['props'].id !== childPreloaderId;
          })}
          <AutoSubmit preloaderConfig={preloaderConfig}>
            {results.length ? (
              results.map((c: any) => <Component key={c.id} id={c.id} {...c} />)
            ) : submitCount && dirty ? (
              //ToDo: Config this and conditional to have a configurable fallback message component rather than text
              <div>{resultsFallbackMessage}</div>
            ) : null}
          </AutoSubmit>
        </>
      )}
    </Formik>
  );
};

export default Catalog;
