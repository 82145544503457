import styled from 'styled-components';

export const Select = styled.div<{
  className: string | undefined;
  validationStatus: boolean | undefined;
  configStyles: string | undefined;
}>`
  position: relative;
  width: 100%;

  ${(props) => {
    if (props.validationStatus !== undefined) {
      return `
      ${SelectButton}{

          border-color: ${props.validationStatus === false && props.theme.colors.negative2};
          
          &:focus {
            border-color: ${props.validationStatus === false && props.theme.colors.negative2};
            outline: 1px solid ${props.validationStatus === false && props.theme.colors.negative2};
          }
        
      }
 
      `;
    }
  }}

  ${(props) => props.configStyles}
`;

export const SelectButton = styled.button`
  border: ${(props) => props.theme.borders.normal || '0.0625rem'} solid
    ${(props) => props.theme.colors.darkGray2 || '#778AA5'};
  border-radius: ${(props) => props.theme.radius.sm || '0.05rem'};
  padding: ${(props) => `${props.theme.spacings.sm || '0.75rem'} ${props.theme.spacings.md || '1.25rem'}`};
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
  outline: 0;

  &:hover {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
  }

  &:focus {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
  }

  color: ${(props) => (!props.value ? props.theme.colors.darkGray2 : props.theme.colors.black)};

  &:hover:not(:disabled),
  :active {
    border-color: ${(props) => props.theme.colors.primary2};
  }
  &:focus,
  &:focus-visible {
    border-color: ${(props) => props.theme.colors.primary2};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1};
    outline: none;
  }
  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.colors.darkGray2};
    border-color: ${(props) => props.theme.colors.darkGray2};
    background-color: ${(props) => props.theme.colors.lightGray1};
  }
`;

export const SelectButtonIcon = styled.i<{ isOpen: boolean }>`
  transform: rotate(${(props) => (props.isOpen === true ? '180deg' : '0deg')});
`;

export const SelectOptionList = styled.ul<{ isOpen: boolean }>`
  ${(props) => (props.isOpen ? `display:block;` : `display:none;`)}
  margin-top: 1px;
  width: 100%;
  position: absolute;
  list-style: none;
  background-color: ${(props) => props.theme.colors.white || 'white'};
  border-radius: ${(props) => props.theme.radius.sm || '0.05rem'};
  overflow: hidden;
  box-shadow: ${(props) => props.theme.shadows.lightest};
  border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray4};
  box-sizing: border-box;
  z-index: 100;
`;

export const SelectOptionListItem = styled.li<{ isOption: boolean }>`
  ${(props) => {
    if (props.isOption) {
      return `border: ${props.theme.borders.normal} solid transparent;
      padding: ${props.theme.spacings.sm} ${props.theme.spacings.md}};
      margin: 0;
      display: block;
      color: ${props.theme.colors.darkGray3};
    font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
  outline: 0;

  &:hover {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
  }

  &:focus {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
  }
      cursor: pointer;
      &:hover {
        background-color: ${props.theme.colors.tertiary2};
        color: ${props.theme.colors.primary4};
      }
    
      &:active {
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.primary1};
      }
    
      &:focus,
      &:focus-visible {
        outline: none;
      }
      `;
    }
  }}
`;

export const SelectOption = styled.div``;
