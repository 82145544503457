import styled from 'styled-components';

const Container = styled.div<{
  contentOrientation:
    | 'free'
    | 'horizontal'
    | 'horizontal-centered'
    | 'vertical'
    | 'vertical-centered'
    | 'centered'
    | undefined;
  configStyles: string | undefined;
}>`
  ${(props) => {
    switch (props.contentOrientation) {
      case 'horizontal':
        return `
            display: flex;
            flex-direction: row;
            `;
      case 'horizontal-centered':
        return `
            display: flex;
            flex-direction: row;
            justify-content: center;
          `;
      case 'vertical':
        return `
            display: flex;
            flex-direction: column;
            `;
      case 'vertical-centered':
        return `
            display: flex;
            flex-direction: column;
            align-content: center;
            `;
      case 'centered':
        return `
            display: flex;
            justify-content: center;
            align-items: center;
          `;
      default:
        return ``;
    }
  }}

  ${(props) => props.configStyles}
`;

export { Container };
