/*
 * Object related utilities
 */

export const removeEmpty = function (object: any) {
  const co = object;
  Object.entries(co).forEach(([k, v]: any) => {
    if (v && typeof v === 'object') removeEmpty(v);
    if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v.length === 0) {
      if (Array.isArray(co)) co.splice(k, 1);
      else if (!(v instanceof Date)) delete co[k];
    }
  });
  return co;
};

export const replacer =
  (replace: any) =>
  (acc: any, [key, value]: any): any => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length === 0 || typeof value[0] !== 'object') acc[key] = value;
        else acc[key] = value.map((v) => Object.entries(v).reduce(replacer(replace), {}));
      } else if (typeof value === 'object') acc[key] = Object.entries(value).reduce(replacer(replace), {});
      else acc[key] = value;
    } else if (typeof value == 'boolean') acc[key] = value;
    else acc[key] = replace;
    return acc;
  };

/* 
  Function name: resolvehPath
  Description: Access nested keys by a dot notation string path
  and retrieves value.
  - 'reference[0].contact_info.phone'
  - 'person.name'
  - 'person.skills[1].type
  Params: (object: any, path: string)
  - object: Object that we want to iterate
  - path: Dot notation string path
  Alternatives: Node package object-resolve-path
*/
export function resolvePath(object: any, path: string) {
  let result = null;
  path = path.replace(/\[(\w+)\]/g, '.$1'); // Convert indexes to properties
  path = path.replace(/^\./, ''); // Strip a leading dot
  const a = path.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (typeof object === 'object' && k in object) {
      result = object[k];
    } else {
      return;
    }
  }
  return result;
}

/* 
  Function name: isEmpty
  Description: Iterates all keys of the object and returns true
  if the all the keys are empty
  Params: (object: any)
  - object: Object that we want to iterate
*/

export function isEmpty(object: any): boolean {
  return Object.values(object).every((v) =>
    v && typeof v === 'object' ? isEmpty(v) : (Array.isArray(v) && v.length === 0) || v,
  );
}
