import React, { ReactNode } from 'react';
import * as Styled from './card.styled';

export type CardProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * a styling element to inherit active status
   */
  checked?: boolean;

  /**
   * content of the card
   */
  children?: ReactNode;

  /**
   * class names override
   */
  className?: string;

  /**
   * styling overrides
   */
  configStyles?: string;

  /**
   * to disable the card
   */
  disabled?: boolean;

  /**
   * to determine if the card is selectable or not
   */
  isSelectable?: boolean;

  /**
   * based on Formik
   * meta object to handle field additional descriptive data
   *  - error
   *  - touched
   *  - value
   */
  meta?: any;

  /**
   * field name to be attached to the component
   */
  name?: string;
};

export function Card({
  id,
  children,
  checked,
  className,
  disabled,
  isSelectable = true,
  name,
  configStyles,
  meta = {},
}: CardProps) {
  return (
    <Styled.Card
      name={name}
      tab-index="0"
      checked={checked}
      disabled={disabled}
      isSelectable={isSelectable}
      configStyles={configStyles}
      formState={meta}
      className={className}
      id={id}
    >
      {Array.isArray(children)
        ? children.map((child, index) => {
            return React.cloneElement(child as React.ReactElement, { checked, disabled: disabled });
          })
        : React.cloneElement(children as React.ReactElement, { checked, disabled: disabled })}
    </Styled.Card>
  );
}
