import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';

export const TypeAhead = styled(ReactSelect)<{ isDisabled: boolean | undefined }>`
  .react-select__control {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray2};
    border-radius: ${(props) => props.theme.radius.sm};
    padding: ${(props) => props.theme.spacings.sm} ${(props) => props.theme.spacings.default};
    width: 100%;
    font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
    outline: 0;
    min-height: 0;

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.primary1};
    }

    &.react-select__control--is-focused {
      outline: 0;
      border: 1px solid ${(props) => props.theme.colors.primary2};
      box-shadow: none;
    }

    &.react-select__control--is-focused.react-select__control--menu-is-open {
      outline: 0;
      border: 1px solid ${(props) => props.theme.colors.primary2};
      box-shadow: none;
    }

    .react-select__value-container {
      padding: 0;
      margin: 0;

      .react-select__placeholder {
        font-weight: normal;
        font-family: inherit;
        color: ${(props) => props.theme.colors.darkGray2};
      }
    }

    .react-select__input-container {
      padding: 0;
      margin: 0;
    }

    .react-select__indicators {
      padding: 0;
      margin: 0;

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        padding: 0;
      }
    }
  }

  .react-select__menu {
    .react-select__menu-list {
    }

    .react-select__option {
    }
  }
`;

export const TypeAheadAsync = styled(AsyncSelect)`
  & .Select__indicator Select__dropdown-indicator {
  }
`;
