import React, { ReactNode } from 'react';
import * as Styled from './circularloader.styled';

export type CircularloaderProps = {
  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * To identify the Circularloader component
   */
  id?: string;
};

export function Circularloader({configStyles, id}: CircularloaderProps) {
  return <Styled.Circularloader configStyles={configStyles} id={id}/>;
}
