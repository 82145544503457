import React from 'react';
import * as Styled from './progressindicator.styled';

export type ProgressindicatorProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * max value to be represented in the progress bar
   */
  maxVal?: number;

  /**
   * Value to be converted to percentage of the filled space
   */
  actualVal?: number;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;
};

export function Progressindicator({ maxVal, actualVal, configStyles, id }: ProgressindicatorProps) {
  return (
    <Styled.ProgressBar configStyles={configStyles} id={id}>
      <Styled.ProgressFill actualVal={actualVal} maxVal={maxVal}>
        <Styled.Indicator />
      </Styled.ProgressFill>
    </Styled.ProgressBar>
  );
}
