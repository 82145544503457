import React, { createContext, useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

export type Viewport = {
  breakpoints: {
    [key: string]: string;
  };
  breakpoint: string;
};

export const ViewportContext = createContext({} as Viewport);

const debounce = (func: () => void, wait: number) => {
  let timeout: any;
  return function (this: unknown, ...args: any) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const ViewportProvider = ({ children }: { children: React.ReactNode }) => {
  const { breakpoints } = useContext(ThemeContext);
  const [breakpoint, setBreakpoint] = useState('');

  const getBreakpoint = (width: number): string => {
    let breakpoint = '';
    Object.entries(breakpoints).forEach(([key, value]: any, index: number, arr: any) => {
      //Strip "px" from "breakpoints" string from theme. 1000 || "1000px"
      const currentScreenValue = parseInt(value, 10);
      if (index === 0) {
        if (width < parseInt(value, 10) || (width >= parseInt(value, 10) && width < parseInt(arr[index + 1][1], 10)))
          breakpoint = key;
      } else if (!arr[index + 1]) {
        if (width >= currentScreenValue) breakpoint = key;
      } else {
        if (width >= currentScreenValue && width < parseInt(arr[index + 1][1], 10)) {
          breakpoint = key;
        }
      }
    });

    return breakpoint;
  };

  useEffect(() => {
    const handleResize = debounce(function () {
      setBreakpoint(getBreakpoint(window.innerWidth));
    }, 100);

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ViewportContext.Provider value={{ breakpoints, breakpoint }}>{children}</ViewportContext.Provider>;
};
