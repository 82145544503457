import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { AUTH } from '../../../../Infrastructure/API/Configs/Auth.api';
import { store } from '../../store';

type CredentialsType = {
  sessionId: string;
  access_token: string;
  refresh_token: string;
};

const CredentialsModelInitialState: CredentialsType = {
  sessionId: 'null',
  access_token: 'null',
  refresh_token: 'null',
};

const CredentialsModel = createModel<RootModel>()({
  state: {
    ...CredentialsModelInitialState,
  } as CredentialsType,
  reducers: {
    SET_SESSION(state, { sessionId }) {
      return {
        ...state,
        sessionId,
      };
    },
    SET_ACCESS_TOKEN(state, { access_token }) {
      return {
        ...state,
        access_token,
      };
    },
    SET_REFRESH_TOKEN(state, { refresh_token }) {
      return {
        ...state,
        refresh_token,
      };
    },
    SET_AUTHENTICATION(state, { access_token, refresh_token }) {
      return {
        ...state,
        access_token,
        refresh_token,
      };
    },
    RESTORE_CREDENTIALS(state, { sessionId, access_token, refresh_token }) {
      return {
        ...state,
        sessionId,
        access_token,
        refresh_token,
      };
    },
    CLEAR_CREDENTIALS() {
      return {
        ...CredentialsModelInitialState,
      };
    },
  },
  effects: (dispatch) => ({
    async setSession({ orgName, appName, sessionId }) {
      await dispatch.CredentialsModel.SET_SESSION({ sessionId });
      const credentialsData = store.getState().CredentialsModel;
      localStorage.setItem(`oa:${orgName}.${appName}`, JSON.stringify({ ...credentialsData, sessionId }));
    },
    async setRefreshToken({ orgName, appName, refresh_token }) {
      const credentialsData = store.getState().CredentialsModel;
      await dispatch.CredentialsModel.SET_REFRESH_TOKEN({ refresh_token });
      localStorage.setItem(`oa:${orgName}.${appName}`, JSON.stringify({ ...credentialsData, refresh_token }));
    },
    async setAccessToken({ orgName, appName, access_token }) {
      await dispatch.CredentialsModel.SET_ACCESS_TOKEN({ access_token });
      const credentialsData = store.getState().CredentialsModel;
      localStorage.setItem(`oa:${orgName}.${appName}`, JSON.stringify({ ...credentialsData, access_token }));
    },
    async restoreCredentials({ orgName, appName }) {
      const oaStorageData: string = localStorage.getItem(`oa:${orgName}.${appName}`) || '';
      const { sessionId, access_token, refresh_token } = JSON.parse(oaStorageData);
      await dispatch.CredentialsModel.RESTORE_CREDENTIALS({ sessionId, access_token, refresh_token });
    },
    async clearCredentials({ orgName, appName }) {
      localStorage.removeItem(`oa:${orgName}.${appName}`);
      await dispatch.CredentialsModel.CLEAR_CREDENTIALS();
    },
    async getCredentials({ organizationId }) {
      // Store actions are handled at OneAppAPIHandler interceptors
      await AUTH.getSession(organizationId);
    },
  }),
});

export { CredentialsModel };
