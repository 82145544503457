import styled from 'styled-components';
import { GridProps } from './grid';

const Grid = styled.div<{ childGridclassName: any } & GridProps>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: ${(props) => (props.gridRows ? props.gridRows : 'repeat(3, 1fr)')};
  grid-template-columns: ${(props) => (props.gridColumns ? props.gridColumns : 'repeat(3, 1fr)')};
  grid-template-areas: ${(props) => (props.gridAreaLayout ? props.gridAreaLayout : `'. . .' '. . .' '. . .'`)};
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  ${(props) => {
    return props.childGridclassName.map((className) => {
      return `
            .grid-cell-${className}{
                grid-area: ${className};
            };
        `;
    });
  }}

  ${(props) => props.configStyles};
`;

export { Grid };
