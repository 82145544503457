import OneAppAPIHandler from '../OneAppApiHandler';
import { BASECONFIG_ENDPOINTS } from '../../../Application/Common/Endpoints/OneApp/index';

const OneAppAPI = {
  getInit: (appName: string, orgName: string) =>
    OneAppAPIHandler.GET(BASECONFIG_ENDPOINTS.GET_INIT, { appName, orgName }),
  getThemes: (appId: string) => OneAppAPIHandler.GET(`${BASECONFIG_ENDPOINTS.GET_THEMES}/${appId}`),
  getPage: (appId: string, path: string, params?: object) =>
    OneAppAPIHandler.GET(BASECONFIG_ENDPOINTS.GET_PAGE, {
      appId,
      path,
      ...params,
    }),
  getLayout: (pageId: string) => OneAppAPIHandler.GET(BASECONFIG_ENDPOINTS.GET_LAYOUT, { pageId }),
  getRegions: (layoutId: string, params: object) =>
    OneAppAPIHandler.GET(BASECONFIG_ENDPOINTS.GET_REGIONS, { layoutId, ...params }),
  getComponent: (id: string, params?: object) =>
    OneAppAPIHandler.GET(`${BASECONFIG_ENDPOINTS.GET_COMPONENT}/${id}`, { ...params }),
  postComponent: (id: string, data: any, params?: object) =>
    OneAppAPIHandler.POST(`${BASECONFIG_ENDPOINTS.GET_COMPONENT}/${id}`, data, { ...params }),
  getData: (data: any, params?: string) => OneAppAPIHandler.POST(BASECONFIG_ENDPOINTS.GET_DATA, data, params),
  getTrigger: (id: string) => OneAppAPIHandler.POST(`${BASECONFIG_ENDPOINTS.GET_TRIGGER}/${id}`),
  getSubmit: (form: any) => OneAppAPIHandler.POST(BASECONFIG_ENDPOINTS.GET_SUBMIT, form),
  postFile: (form: FormData) =>
    OneAppAPIHandler.POST(BASECONFIG_ENDPOINTS.GET_FILE, form, null, { 'Content-Type': 'multipart/form-data' }),
};

export { OneAppAPI };
