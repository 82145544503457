import React, { ReactNode, useState, useEffect } from 'react';
import * as Styled from './toast.styled';
import { Portal } from 'react-portal';

export type ToastProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * positions the toast a number of pixels to the bottom
   */
  bottom?: number;

  /**
   * content of the toast
   */
  children?: ReactNode;

  /**
   * class names override
   */
  className?: string;

  /**
   * styling overrides
   */
  configStyles?: string;

  /**
   * to set whether the toast is open or not
   */
  isToastOpen: boolean;

  /**
   * moves the toast a number of pixels from the left
   */
  left?: number;

  /**
   * to determine if the toast will be displayed in the window or in its container
   */
  mode?: 'default' | 'local' | 'global';

  /**
   * determines the position mode for the toast
   */
  positioning?: 'relative' | 'sticky' | 'absolute' | 'fixed';

  /**
   * moves the toast a number of pixels from the right
   */
  right?: number;

  /**
   * width of the toast
   */
  size?: '100%' | '60%' | '50%';

  /**
   * moves the toast a number of pixels from the top
   */
  top?: number;

  /**
   * defines the type of toast
   */
  variant?: 'default' | 'success' | 'warning' | 'danger';
};

export function Toast({
  id,
  bottom,
  children,
  isToastOpen = false,
  left,
  mode = 'default',
  positioning = 'relative',
  right,
  size = '50%',
  top,
  variant = 'default',
}: ToastProps) {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(isToastOpen);
  const [isLoaded, setIsLoaded] = useState<boolean | undefined>(false);

  const onCloseClickHandler = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    setIsOpen(isToastOpen);
  }, [isToastOpen]);

  const toastMode = (
    mode: 'default' | 'global' | 'local',
    positioning: 'absolute' | 'fixed' | 'relative' | 'sticky',
  ) => {
    if (mode === 'default') {
      if (positioning === 'absolute' || positioning === 'fixed') {
        return 'global';
      } else {
        return 'local';
      }
    } else if (mode === 'global') {
      return 'global';
    } else if (mode === 'local') {
      return 'local';
    }
  };

  return (
    <>
      {toastMode(mode, positioning) === 'global'
        ? isOpen &&
          isLoaded && (
            <Portal>
              <Styled.Toast
                variant={variant}
                size={size}
                positioning={positioning}
                right={right}
                bottom={bottom}
                left={left}
                top={top}
                id={id}
              >
                {children}
                <Styled.CloseButton onClick={onCloseClickHandler}>
                  <span style={{ display: 'inline-block', transform: 'rotate(45deg)', fontSize: '1.25rem' }}>+</span>
                </Styled.CloseButton>
              </Styled.Toast>
            </Portal>
          )
        : isOpen && (
            <Styled.Toast
              id={id}
              variant={variant}
              size={size}
              positioning={positioning}
              right={right}
              bottom={bottom}
              left={left}
              top={top}
            >
              {children}
              <Styled.CloseButton onClick={onCloseClickHandler}>
                <span style={{ display: 'inline-block', transform: 'rotate(45deg)', fontSize: '1.25rem' }}>+</span>
              </Styled.CloseButton>
            </Styled.Toast>
          )}
    </>
  );
}
