import React, { ReactNode, useEffect, useRef } from 'react';
import * as Styled from './filebrowser.styled';
import { Dotloader } from '@pefai/scl-react.components.informational.loaders.dotloader';

export type FilebrowserProps = {
  /**
   * accept is a string that defines the file types the file input should accept.
   */
  accept?: string;

  /**
   *  for content nested inside the span element (role button).
   */
  children: ReactNode;

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * To disable the input[file] field.
   */
  disabled?: boolean;

  /**
   * file size error message
   */
  fileSizeErrorMessage?: string;

  /**
   * file size limit in bytes
   */
  fileSizeLimit?: number;

  /**
   * Handlers: used for utility, helper, external dependency functions
   * + filesManager: this is the structure for file management actions and attributes
   * - upload: that takes files as a parameter and post the file to a given endpoint
   * - files: array that holds files to be posted and read
   * - error: attribute used to detect if any error occured while uploading
   * - loading: attribute to detect loading action
   */
  handlers: {
    file: { upload: any; files: File[] | []; error: string | null; loading: boolean };
  };

  /**
   * To identifies the input[file] semantic element.
   */
  id: string;

  /**
   * based on Formik
   * meta object to handle field additional descriptive data
   *  - error
   *  - touched
   *  - value
   */
  meta?: any;

  /**
   * multiple file tag
   */
  multiple?: boolean;

  /**
   * name specifies a name for an input element
   */
  name: string;

  /**
   * specifies a short hint that describes the expected value of an input field
   */
  placeholder?: string;

  /**
   * based on Formik
   * setError form handler
   */
  setError: any;

  /**
   * Convention [Forms] : This prop is used to validate a field or form status, it affects the "touched" action (edited due to focus but no change)
   * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
   */
  setTouched: () => {};

  /**
   * Convention [Forms] : This is prop (aux) is used to set this field's value on a form
   * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
   */
  setValue: any;

  /**
   * value contains a string that represents the path to the selected file(s).
   */
  value?: string[] | undefined;
};

export function Filebrowser({
  accept,
  children,
  className,
  disabled,
  handlers,
  id,
  name,
  placeholder,
  setValue,
  value,
}: FilebrowserProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    file: { files, upload, error, loading },
  } = handlers;

  useEffect(() => {
    if (Array.isArray(value)) {
      setValue(value);
    }
  }, []);

  useEffect(() => {
    if (files !== null) {
      setValue(files);
    }
  }, [files]);

  const handleChange = (e: any) => {
    const fileList = [...e.currentTarget.files] || [];

    if (fileList) {
      upload(fileList);
    }
  };

  return (
    <>
      <Styled.FileBrowser tabIndex={disabled ? -1 : 0} disabled={disabled || loading}>
        <Styled.Input
          className={className}
          disabled={disabled}
          id={id}
          name={name}
          onChange={(event) => handleChange(event)}
          type="file"
        />
        <Styled.Label htmlFor={id} disabled={disabled}>
          <Styled.Placeholder hasFiles={files || value?.length ? true : false}>
            {Array.isArray(value) && value.length
              ? value
                  .map((file: any) => {
                    return file['name'];
                  })
                  .join(', ')
              : placeholder}
          </Styled.Placeholder>
          <Styled.Span role={'button'}>{loading ? <Dotloader /> : children}</Styled.Span>
        </Styled.Label>
      </Styled.FileBrowser>
      {error && <span style={{ fontSize: '0.75rem', color: '#AF0A45' }}>Ha ocurrido un error</span>}
    </>
  );
}
