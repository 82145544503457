import styled from 'styled-components';

export const Input = styled.input.attrs({ type: 'url' })<{ configStyles: string | undefined }>`
  border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray2};
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacings.sm} ${(props) => props.theme.spacings.default};
  font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
  outline: 0;

  &:hover {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
  }

  &:focus {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
  }

  ${(props) => props.configStyles};
`;
