import React, { ReactNode } from 'react';
import * as Styled from './columns.styled';

export type ColumnsProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  /**
   * 12 column system
   *
   * 1: 8.33333333,
   * 2: 16.66666667,
   * 3: 25,
   * 4: 33.33333333,
   * 5: 41.66666667,
   * 6: 50,
   * 7: 58.33333333,
   * 8: 66.666667,
   * 9: 75,
   * 10: 83.33333333,
   * 11: 91.66666667,
   * 12: 100,
   */
  columnSpan?: number;

  /**
   * classes for external library implementations
   */
  className?: string;

  /**
   * styling configuration overrides
   */

  configStyles?: string;
  /**
   * inherited props from higher components
   */
  props?: any;
};

export function Columns({ children, columnSpan = 12, className = '', configStyles, id }: ColumnsProps) {
  return (
    <Styled.Columns className={className} columnSpan={columnSpan} configStyles={configStyles} id={id}>
      {children}
    </Styled.Columns>
  );
}
