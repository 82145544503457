import React from 'react';
import * as Styled from './typeahead.styled';

export type TypeaheadProps = {
  /**
   * for class names
   */
  className?: string;

  /**
   * specifies the URL endpoint to be fetched
   */
  endpoint?: string;

  /**
   * an error message to be logged when fetching the endpoint fails
   */
  errorMsg?: string;

  /**
   * an identifier for the Typeahead element
   */
  id: string;

  /**
   * a threshold to start filtering results, when reached the URL gets fetched.
   */
  inputThreshold?: number;

  /**
   * specifies whether the Typeahead element is asynchronous or not
   */
  isAsync?: boolean;

  /**
   * to disable or enable the Typeahead element
   */
  disabled?: boolean;

  /**
   * a string to be rendered when loading
   */
  loadingMessage?: string;

  /**
   * specifies the name of the Typeahead element
   */
  name: string;

  /**
   * a string to be rendered when no options are shown
   */
  noOptionsMessage?: string;

  /**
   * a function to be triggered when the Typeahead element loses focus
   */
  onBlur?: () => void;

  /**
   * to handle change events on the select
   */
  onChange?: any;

  /**
   * a function to be triggered when the Typeahead element gets focus
   */
  onFocus?: () => void;

  /**
   * specifies the JSON attribute to be treated as the option label
   */
  optionLabel?: string;

  /**
   * to set the options for the Typeahead element
   */
  options?: {
    value: string;
    label: string;
  }[];

  /**
   * specifies the JSON attribute to be treated as the option value
   */
  optionValue?: string;

  /**
   * a placeholder for the Typeahead element
   */
  placeholder?: string;

  /**
   * function to update parent's value
   */
  setValue: (value: string) => {};

  /**
   * function to update parent's value
   */
  value: string;
};

export function Typeahead({
  className,
  endpoint = '',
  errorMsg = 'Failed to fetch.',
  id,
  inputThreshold = 3,
  isAsync,
  disabled,
  loadingMessage,
  name,
  noOptionsMessage,
  onBlur,
  onChange,
  onFocus,
  optionLabel = '',
  options,
  optionValue = '',
  placeholder,
  setValue,
  value,
}: TypeaheadProps) {
  const onChangeHandler = (option: any) => {
    setValue(option.value);
  };

  if (isAsync) {
    const getOptions = (inputValue: string) => {
      // TODO: [config] define custom headers
      if (inputValue.length >= inputThreshold) {
        return fetch(endpoint)
          .then((res) => res.json())
          .then((data) => data.filter((item) => item[optionLabel].toLowerCase().includes(inputValue.toLowerCase())))
          .catch((error) => {
            console.log(errorMsg);
            console.log(error);
          });
      }
    };

    return (
      <Styled.TypeAheadAsync
        getOptionLabel={(e: any) => e[optionLabel]}
        getOptionValue={(e: any) => e[optionValue]}
        inputId={`typeahead-${id}`}
        loadingMessage={() => loadingMessage}
        loadOptions={getOptions}
        name={`typeahead-${name}`}
        noOptionsMessage={() => noOptionsMessage}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onInputChange={getOptions}
        placeholder={placeholder}
      />
    );
  } else {
    return (
      <Styled.TypeAhead
        className={'react-select-container'}
        classNamePrefix={'react-select'}
        defaultInputValue={options?.find((option) => option.value === value)?.label || ''}
        inputId={`typeahead-${id}`}
        isDisabled={disabled}
        loadingMessage={() => loadingMessage}
        name={`typeahead-${name}`}
        noOptionsMessage={() => noOptionsMessage}
        onBlur={onBlur}
        onChange={(value: any) => {
          onChangeHandler(value);
        }}
        onFocus={onFocus}
        options={options}
        placeholder={placeholder}
      />
    );
  }
}
