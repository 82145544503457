import { store } from '../State/store';

export const modal = (content: string): any => {
  alert(content);
};

export const message = (message: string): any => {
  console.log('Message', message);
};

export const toggleProperty = (model: { modelName: string; property: string }): any => {
  store.dispatch.ComponentsModel['baseEffects']({
    effectActionName: 'BASE_TOGGLE_BOOLEAN',
    modelName: model.modelName,
    affectedProperty: model.property,
  });
};
