import { es } from 'yup-locales';
import * as yup from 'yup';

// ToDo: Integrate i18n for app internationalization consistency
yup.setLocale(es);

const checksSchema = (schema: any, check: any) => {
  const { key, val, err } = check;
  switch (key) {
    case 'required':
      schema = schema.required();
      break;
    case 'min':
      schema = schema.min(val, err ? err : undefined);
      break;
    case 'max':
      schema = schema.max(val, err ? err : undefined);
      break;
    case 'length':
      schema = schema.length(val, err ? err : undefined);
      break;
    case 'email':
      schema = schema.email(err ? err : undefined);
      break;
    case 'url':
      schema = schema.url(err ? err : undefined);
      break;
    case 'shape':
      schema = schema.shape(Object.entries(val).reduce(schemaBuilder, {}));
      break;
    case 'matches':
      schema = schema.matches(val, err ? err : undefined);
      break;
    case 'match_field':
      schema = schema.oneOf([yup.ref(val)], err ? err : undefined);
      break;
    case 'uuid':
      schema = schema.uuid(val, err ? err : undefined);
      break;
    case 'flag':
      schema = schema.oneOf([val], err ? err : undefined);
      break;
    default:
      return schema;
  }
  return schema;
};

export const schemaBuilder = (shape: any, [key, value]: any): any => {
  const { type, label, multiple, checks } = value;
  let schema;

  switch (type) {
    case 'string':
      schema = yup.string();
      break;
    case 'number':
      schema = yup.number();
      break;
    case 'date':
      schema = yup.date();
      break;
    case 'boolean':
      schema = yup.boolean();
      break;
    case 'object':
      schema = yup.object();
      break;
    default:
      return null;
  }

  if (checks) schema = checks.reduce(checksSchema, schema);
  if (multiple) schema = multiple.reduce(checksSchema, yup.array().of(schema));
  if (label) schema = schema.label(label);

  shape[key] = schema;
  return shape;
};

export const validationSchema = (schema) => {
  return yup.object().shape(Object.entries(schema).reduce(schemaBuilder, {}));
};
