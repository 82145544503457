import styled from 'styled-components';

export const AutocompleteSelect = styled.div<{
  classNames: string | undefined;
  validationStatus: boolean | undefined;
  configStyles: string | undefined;
}>`
  width: 100%;
  position: relative;

  ${(props) => {
    if (props.validationStatus !== undefined) {
      return `
      ${Input}{

          border-color: ${props.validationStatus === false && props.theme.colors.negative2};
          
          &:focus {
            border-color: ${props.validationStatus === false && props.theme.colors.negative2};
            outline: 1px solid ${props.validationStatus === false && props.theme.colors.negative2};
          }
        
      }
 
      `;
    }
  }}

  ${(props) => props.configStyles}
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray2};
  padding: ${(props) => `${props.theme.spacings.sm} ${props.theme.spacings.md}`};
  background-color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme?.typography?.base1};
  font-family: ${(props) => props.theme.typography.fontFace};
  color: ${(props) => (!props.value ? props.theme.colors.darkGray2 : props.theme.colors.black)};
  cursor: default;

  &:hover {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
  }

  &:focus {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
  }

  &:hover:not(:disabled),
  :active {
    border-color: ${(props) => props.theme.colors.primary2};
  }

  &:focus,
  &:focus-visible {
    border-color: ${(props) => props.theme.colors.primary2};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1};
    outline: none;
  }

  &:disabled {
    cursor: default;
    border-color: ${(props) => props.theme.colors.darkGray2};
    background-color: ${(props) => props.theme.colors.lightGray1};
    color: ${(props) => props.theme.colors.darkGray2};
  }
`;

export const Placeholder = styled.div<{
  inputPaddingLeft: string | undefined;
  inputPaddingTop: string | undefined;
  inputBorderLeft: string | undefined;
  inputBorderTop: string | undefined;
}>`
  all: initial;
  * {
    all: unset;
  }
  position: absolute;
  opacity: 0.5;
  pointer-events: none;
  ${(props) => {
    if (
      props.inputPaddingLeft === undefined ||
      props.inputPaddingTop === undefined ||
      props.inputBorderLeft === undefined ||
      props.inputBorderTop === undefined
    ) {
      return `display:none;`;
    }
  }}
  left: ${(props) => `calc(${props.inputPaddingLeft} + ${props.inputBorderLeft})`};
  top: ${(props) => `calc(${props.inputPaddingTop} + ${props.inputBorderTop})`};
  font-size: ${(props) => props.theme?.typography?.base1};
`;

export const PlaceholderText = styled.span<{ hasBeenTyped: boolean | undefined }>`
  ${(props) => (props.hasBeenTyped ? `opacity: 0` : `opacity:0.5`)};
  font-family: ${(props) => props.theme.typography.fontFace};
`;

export const OptionList = styled.ul<{
  inputY: number | undefined;
  inputX: number | undefined;
  inputW: number | undefined;
  inputH: number | undefined;
  listHeight: number | undefined;
  isListOverflowed: boolean | undefined;
  visualVp: VisualViewport | null;
}>`
  box-sizing: border-box;
  max-height: 230px;
  width: ${(props) => `${props.inputW}px`};
  margin-top: 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: ${(props) => props.theme.shadows.lightest};
  border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray4};
  list-style: none;
  overflow-y: scroll;
  position: fixed;

  ${(props) => {
    if (props.isListOverflowed === true && props.visualVp) {
      return `
        top: ${`calc(${props.inputY}px - ${props.listHeight}px + ${props.visualVp.offsetTop}px)`};
        left: ${`${props.inputX}px`};
  `;
    } else if (props.isListOverflowed === false && props.visualVp) {
      return `
        top: ${`calc(${props.inputY}px + ${props.inputH}px + ${props.visualVp.offsetTop}px)`};
        left: ${`${props.inputX}px`};
  `;
    }
  }}

`;

export const Option = styled.li`
  margin: 0;
  outline: 0;
  display: block;
  color: ${(props) => props.theme.colors.darkGray3};
  font-size: ${(props) => props.theme?.typography?.base1};
`;

export const AutocompleteOption = styled.button<{ isOptionFallback: boolean | undefined }>`
  width: 100%;
  background-color: transparent;
  border: ${(props) => props.theme.borders.normal} solid transparent;
  display: block;
  box-sizing: border-box;
  text-align: left;
  cursor: auto;

  ${(props) =>
    !props.isOptionFallback &&
    `
    cursor:pointer;
    &:hover {
      background-color: ${props.theme.colors.tertiary2};
      color: ${props.theme.colors.primary4};
      border: ${props.theme.borders.normal} solid ${props.theme.colors.primary1};
    }
    &:focus {
      border: ${props.theme.borders.normal} solid ${props.theme.colors.primary2};
    }

    &:active {
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.primary1};
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }
  `}
`;
