import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './modal.styled';
import { Portal } from 'react-portal';

export type ModalProps = {
  /**
   * content to be rendered insie the modal
   */
  children?: ReactNode;

  /**
   * class names override
   */
  classNames?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * determines if the modal is open or closed from the parent
   */
  isModalOpen: boolean;

  /**
   * size of the modal
   */
  overlay?: 'auto' | 'full';

  /**
   * Connected component.
   * This prop determines which model should the component connect to
   */
  modelName: string;
};

export function Modal({ children, classNames, configStyles, overlay = 'auto', isModalOpen, modelName }: ModalProps) {
  const model = useSelector((state) => state['ComponentsModel'][modelName]);

  useEffect(() => {
    if (model?.isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [model?.isModalOpen]);

  return model?.isModalOpen || isModalOpen ? (
    <Portal>
      <Styled.Modal configStyles={configStyles} className={classNames}>
        <Styled.ModalOverlay></Styled.ModalOverlay>
        <Styled.ModalContent overlay={overlay}>{children}</Styled.ModalContent>
      </Styled.Modal>
    </Portal>
  ) : null;
}
