import styled from 'styled-components';

export const Svgindicator = styled.svg.attrs({ role: 'img' })<{ configStyles: string | undefined }>`
  & > path {
    stroke-width: 1rem;
    fill: none;
  }

  ${(props) => props.configStyles};
`;

export const TrackPath = styled.path`
  stroke: ${(props) => props.theme.colors.darkGray4};
`;

export const ProgressPath = styled.path`
  stroke: ${(props) => props.theme.colors.primary1};
`;
