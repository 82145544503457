import styled from 'styled-components';

const Circularloader = styled.div<{ configStyles: string | undefined }>`
  width: 45px;
  height: 45px;
  border: 3px solid #f0f4fa;
  border-top: 3px solid #0279fe;
  border-radius: 100%;
  animation: spin 1s infinite ease-in-out;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1080deg);
    }
  }
  ${(props) => props.configStyles};
`;

export { Circularloader };
