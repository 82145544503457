import React, { ReactNode, ReactElement, useEffect, useState } from 'react';

export type FormsubmitProps = {
  /**
   * An array of elemenes that have the submit function and one of these is the loader
   */
  children: ReactNode | ReactNode[];

  /**
   * form submission state (handled by formik)
   */
  formStatus?: string | undefined;

  /**
   * To identify the Formsubmit component
   */
  id?: string;

  /**
   * determines if form validations have passed, or form has errors (handled by formik)
   */
  isFormValid?: boolean | undefined;

  /**
   * To manage the existence of a loader in the submitting process
   */
  isSubmitting?: boolean;

  /**
   * An Id to identify the loader among the children
   */
  isSubmittingMessageId: string;

  /**
   * form field name (handled by formik)
   */
  name: string;
};

export function Formsubmit({
  children,
  formStatus,
  id,
  isSubmitting,
  isSubmittingMessageId,
  isFormValid,
}: FormsubmitProps) {
  const [submitStatus, setSubmitStatus] = useState<boolean>(false);

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      setSubmitStatus(false);
    }
  });

  useEffect(() => {
    setSubmitStatus(false);
  }, []);

  useEffect(() => {
    if (isSubmitting === true && isFormValid === true) {
      // Case: When the form is in submit process (attempts submission)
      setSubmitStatus(true);
    } else if (formStatus !== undefined || !isFormValid) {
      // Case: When submission failed due to feedback (attempted sumbmission and failed)
      setSubmitStatus(false);
    }
  }, [isSubmitting, formStatus]);

  return (
    <div id={id}>
      {Array.isArray(children) &&
        (submitStatus
          ? children.filter((child) => {
              const loader = child as ReactElement;
              return loader.props.id === isSubmittingMessageId;
            })
          : children.filter((child) => {
              const defaultChild = child as ReactElement;
              return defaultChild.props.id !== isSubmittingMessageId;
            }))}
    </div>
  );
}
