import styled from 'styled-components';

export const Toast = styled.div.attrs({ role: 'alert' })<{
  variant: string | undefined;
  size: string | undefined;
  positioning: string | undefined;
  right: number | undefined;
  bottom: number | undefined;
  left: number | undefined;
  top: number | undefined;
}>`
  box-sizing: border-box;
  ${(props) => {
    switch (props.positioning) {
      case 'relative':
        return `
        position: relative;
        `;

      case 'absolute':
        return `
        position: absolute;
        ${props.right !== undefined ? `right:${props.right}px;` : ''}
        ${props.bottom !== undefined ? `bottom:${props.bottom}px;` : ''}
        ${props.left !== undefined ? `left:${props.left}px;` : ''}
        ${props.top !== undefined ? `top:${props.top}px;` : ''}
        `;

      case 'fixed':
        return `
        position: fixed;
        ${props.right !== undefined ? `right:${props.right}px;` : ''}
        ${props.bottom !== undefined ? `bottom:${props.bottom}px;` : ''}
        ${props.left !== undefined ? `left:${props.left}px;` : ''}
        ${props.top !== undefined ? `top:${props.top}px;` : ''}
        `;

      case 'sticky':
        return `
        position: sticky;
        ${props.right !== undefined ? `right:${props.right}px;` : ''}
        ${props.bottom !== undefined ? `bottom:${props.bottom}px;` : ''}
        ${props.left !== undefined ? `left:${props.left}px;` : ''}
        ${props.top !== undefined ? `top:${props.top}px;` : ''}
        `;
    }
  }}
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.size};
  min-height: 3rem;
  border-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
  padding: ${(props) => props.theme.spacings.default || '1rem'};
  padding-right: ${(props) => props.theme.spacings.xlg || '2rem'};
  color: ${(props) => props.theme.colors.white || '#ffffff'};
  background-color: ${(props) => {
    switch (props.variant) {
      case 'default':
        return `${props.theme.colors.primary2 || '#004DAD'}`;
      case 'success':
        return `${props.theme.colors.positive2 || '#026363'}`;
      case 'warning':
        return `${props.theme.colors.warning1 || '#FE9902'}`;
      case 'danger':
        return `${props.theme.colors.negative2 || '#AF0A45'}`;
    }
  }};
`;
export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.white || '#ffffff'};
  cursor: pointer;
  margin-left: ${(props) => props.theme.spacings.xsm || '0.5rem'};
`;
