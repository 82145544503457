import React, { Children, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// API
import { OneAppAPI } from 'Infrastructure/API/Configs/OneApp.api';

// Utils
import utils from 'Application/utils';

// Component build
import { useProps, useConfig } from './build';

// SCL
import Components from './scl';

type ComponentSchema = {
  id: string;
  component: string;
  viewport: any | null;
  attributes: {
    [key: string]: any;
  } | null;
  settings: {
    form: any;
    handlers: any;
  } | null;
  events: any | null;
  children: any;
};

const Render = ({
  component,
  attributes,
  viewport,
  settings,
  events: eventsSchema,
  children,
  id,
  ...rest
}: ComponentSchema) => {
  const Cmpt = Components[component];
  const props = useProps(viewport, attributes);
  const { visible, ...config } = useConfig(settings, attributes, id);
  const events = utils.Event.eventsSchema(eventsSchema);
  return visible ? (
    <Cmpt {...props} {...config} {...events} {...rest} id={id}>
      {Children.toArray(children)}
    </Cmpt>
  ) : null;
};

export const Component = (schema: ComponentSchema) => {
  const [component, setComponent] = useState(null);
  const { search } = useLocation();

  useEffect(() => {
    if (schema.component) setComponent(schema);
    else
      (async () => {
        const lazy = await OneAppAPI.getComponent(schema.id, Object.fromEntries(new URLSearchParams(search)));
        setComponent(lazy);
      })();
  }, [schema]);

  return component ? (
    <Render {...component}>
      {component.children && component.children.map((c: any) => <Component key={c.id} {...c} />)}
    </Render>
  ) : null;
};
