// React
import React, { useEffect } from 'react';

// Rematch : Redux
import { shallowEqual, useSelector } from 'react-redux';
import { RootState, dispatch } from '../../Application/State/store';

// OneApp : Components
import { Regions } from './Regions';

interface ILayout {}

export const Layout = (props: ILayout) => {
  // const { success, error } = useSelector((state: RootState) => state.loading.effects.OneAppModel.getLayout);
  const { pageId } = useSelector((state: RootState) => state.OneAppModel, shallowEqual);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    (async () => {
      await dispatch.OneAppModel.getLayout({ pageId });
      setSuccess(true);
    })();
  }, []);

  return <>{success && <Regions />}</>;
};
