/*
 * Array related utilities
 */

/* 
  Function name: arrayEvaluator
  Description: Iterates an array of conditions and logical operators
  evaluating sets of conditions grouped by logicals connectives.
  A condition could be either a plain object, or an array whose first
  entry is the logical operation that joins them, evaluation test runs 
  in each condition and returning true or false depending by the
  logical operator applied.
  - ["AND",
      ["OR",
        { condition: true },
        { condition: false },
      ],
      ["AND",
        { condition: true },
        { condition: true },
      ]
    ]
  Params: (larr, evaluator)
  - larr: Array of conditions with logical context
  - evaluator: Evaluation function applied to each condition of the array
*/
export function arrayEvaluator(larr: [any], evaluator: (value: any) => boolean): boolean {
  const logicalOperator = typeof larr[0] === 'string' ? larr[0] : 'OR';
  const cgrp = larr.filter((el) => typeof el === 'object' && el !== null && !Array.isArray(el));
  const carr = larr.filter((el) => Array.isArray(el));
  let groupFlag = false;

  groupFlag = cgrp.length ? groupEvaluation(logicalOperator, cgrp, evaluator) : groupFlag;

  if (carr.length) {
    if (logicalOperator === 'OR' && (!groupFlag || !cgrp.length)) {
      for (let i = 0; i < carr.length; i++) {
        groupFlag = arrayEvaluator(carr[i], evaluator);
        if (groupFlag) break;
      }
    } else if (logicalOperator === 'AND' && (groupFlag || !cgrp.length)) {
      groupFlag = carr.reduce((acc, la) => acc && arrayEvaluator(la, evaluator), groupFlag);
    }
  }

  function groupEvaluation(
    logicalOperator: string,
    conditionalArray: any,
    evaluationFunction: (value: any) => boolean,
  ): boolean {
    let flag = false;

    switch (logicalOperator) {
      case 'AND':
        flag = conditionalArray.reduce((acc, con) => acc && evaluationFunction(con), true);
        break;
      case 'OR':
        for (let i = 0; i < conditionalArray.length; i++) {
          flag = evaluationFunction(conditionalArray[i]);
          if (flag) break;
        }
        break;
      default:
        flag = false;
    }

    return flag;
  }

  return groupFlag;
}
