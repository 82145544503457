import React, { ReactNode } from 'react';
import * as Styled from './grid.styled';

export type GridProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * a node to be rendered in the special component.
   */
  children: any;
  /**
   * Gird row definition as string, can take any grid-template-row css structure
   */
  gridRows?: string;
  /**
   * Gird columns definition as string, can take any grid-template-column css structure
   */
  gridColumns?: string;
  /**
   * Grid template for container/elements placing
   * Rules:
   * 1) Template should respect the 'element-{index}' structure; grouping elements by index id
   * 2) Grid is bound to template by children order
   */
  gridAreaLayout?: string;
  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;
  /**
   * class override property
   */
  className?: string;
};

function getChildTemplateAreas(gridAreaLayout) {
  const className = gridAreaLayout.split(/[^a-zA-Z0-9-]/g).filter((value) => value !== '');
  return Array.from([...new Set(className)]).sort();
}

export function Grid({
  id,
  children = [],
  gridRows,
  gridColumns,
  gridAreaLayout = `'. . .' '. . .' '. . .'`,
  configStyles,
  className,
}: GridProps) {
  return (
    <Styled.Grid
      id={id}
      gridRows={gridRows}
      gridColumns={gridColumns}
      gridAreaLayout={gridAreaLayout}
      configStyles={configStyles}
      className={className}
      childGridclassName={getChildTemplateAreas(gridAreaLayout)}
    >
      {Array.isArray(children)
        ? children.map((child, index) => {
            return React.cloneElement(child as any, {
              className: `grid-cell-element-${index + 1 || 'free'}`,
              key: `grid-cell-${index}`,
            });
          })
        : { children }}
    </Styled.Grid>
  );
}
