import styled from 'styled-components';

export const CurrencyField = styled.div<{ className: string; configStyles: string }>`
  > input {
    border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.darkGray2};
    padding: ${(props) => props.theme.spacings.sm} ${(props) => props.theme.spacings.default};
    width: 100%;
    display: block;
    box-sizing: border-box;
    font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
    outline: 0;

    &:hover {
      border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary1};
    }

    &:focus {
      border: ${(props) => props.theme.borders.normal} solid ${(props) => props.theme.colors.primary2};
    }

    ${(props) => props.configStyles};
  }
`;
