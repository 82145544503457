import styled from 'styled-components';
import { ProgressindicatorProps } from './progressindicator';

export const ProgressBar = styled.div<ProgressindicatorProps>`
  width: 100%;
  height: ${(props) => props.theme.spacings.default};
  background: ${(props) => props.theme.colors.secondary1};
  border-radius: 5rem;
  overflow: hidden;
  position: relative;

  ${(props) => props.configStyles}
  
`;

export const ProgressFill = styled.div<ProgressindicatorProps>`
  background: ${(props) => props.theme.colors.primary1};
  height: 100%;
  width: ${(props) => (props.actualVal ? props.actualVal * 100 : 50) / (props.maxVal ? props.maxVal : 100)}%;
  border-radius: inherit;
  position: relative;
`;

export const Indicator = styled.span<ProgressindicatorProps>`
  width: 1rem;
  height: 100%;
  background-color: ${(props) => 'white' || props.theme.colors.primary1};
  border-radius: 50%;
  position: absolute;
  right: 0;
`;
