import styled from 'styled-components';

const Heading = styled.div<{ weight: number; level: number; configStyles: string }>`
  font-size: ${(props) => props.theme.typography[`heading${props.level}`]};
  font-weight: ${(props) => props.weight};
  line-height: calc(${(props) => props.theme.typography[`heading${props.level}`]} + 0.5rem);

  ${(props) => props.configStyles};
`;

export { Heading };
