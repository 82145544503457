import styled from 'styled-components';

export const Modal = styled.div.attrs({ role: 'dialog' })<{ configStyles: string | undefined }>`
  ${(props) => props.configStyles}
`;

export const ModalOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.32);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
`;

export const ModalContent = styled.div<{ overlay?: 'auto' | 'full' }>`
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ${(props) => {
    if (props.overlay === 'full') {
      return `
        width:100%;
        height:100%;
      `;
    }
  }}
`;

export const CloseButton = styled.button`
  display: none;
  position: absolute;
  right: ${(props) => props.theme.spacings.sm};
  top: 0.5rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const CloseIcon = styled.span`
  display: inline-block;
  transform: rotate(45deg);
`;
