// React
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { OneAppAPI } from '../../Infrastructure/API/Configs/OneApp.api';

// Rematch : Redux
import { useSelector } from 'react-redux';
import { RootState, dispatch } from '../../Application/State/store';

// Hooks
import { ViewportProvider } from 'Application/context/viewport';

// OneApp : Components
import { Layout } from './Layout';
import { DesignSystemProvider } from './DesignSystemProvider/DesignSystemProvider';
import { DocumentScriptsProvider } from './DocumentScriptsProvider/DocumentScriptsProvider';

type InitParams = {
  orgName: any;
  appName: any;
};

export const Page = () => {
  const { orgName, appName } = useParams<InitParams>();
  let [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const path = `${pathname.split('/').slice(3).join('/')}`;
  const [activeTheme, setActiveTheme] = useState(null);
  const documentHeadElementsReference = useRef(null);
  const [success, setSuccess] = useState(false);
  useEffect(() => {});

  React.useLayoutEffect(() => {
    (async () => {
      // This is the client-side impelemntation of OneApp's service orchestration for the application initialization
      // Dispatch: Attempting to restore application's stored params [credentials, (tbd: theme, page title)]
      await dispatch.OneAppModel.configParams({ orgName, appName });
      // 1. Fetch: /init data with "application name" and "organization name" (from query params)
      const { id: appId, organizationId, favicon } = await OneAppAPI.getInit(appName, orgName);
      // Dispatch: Store "application id" and "organization id" for future implementations
      await dispatch.OneAppModel.initApp({ appId, organizationId });
      // 2. Fetch /page data with "application id", "org/app/'path'", "query params"
      const {
        id: pageId,
        meta,
        title,
        data,
      } = await OneAppAPI.getPage(appId, path, Object.fromEntries(searchParams.entries()));

      // ToDo: remove this property when backend refactors /page call
      const metaHolder = meta
        ? meta.map((tag) => {
            return { type: 'meta', attributes: { ...tag } };
          })
        : [];

      //  Fetch /theme design system data for the themeprovider's application theme
      const appThemes = await OneAppAPI.getThemes(appId);
      // Storing this in state for future impelmentation of multiple themes per app
      setActiveTheme(appThemes);

      // Storing mutable and not render worthy document <head> references from multiple sources (/theme, /page, /init)
      documentHeadElementsReference.current = [
        // from /init call
        { type: 'link', attributes: { rel: 'icon', type: 'image/x-icon', href: favicon } },
        // ... from /page call
        { type: 'title', value: title },
        // ... from /page
        ...metaHolder,
        ...(data || []),
        // ... from design system
        ...appThemes.dependencies,
      ];

      // 3. Dispatch: update of application model with pageId (future implementation will require these 1,2,3 values for /layout and /region calls)
      await dispatch.OneAppModel.getPage({ pageId });
      setSuccess(true);
    })();
  }, []);

  return (
    success && (
      <>
        <DocumentScriptsProvider configDependencies={documentHeadElementsReference.current} />
        <DesignSystemProvider activeTheme={activeTheme}>
          <ViewportProvider>{<Layout />}</ViewportProvider>
        </DesignSystemProvider>
      </>
    )
  );
};
