import styled from 'styled-components';

export const Card = styled.div<{
  name: string | undefined;
  checked: boolean | undefined;
  disabled: boolean | undefined;
  formState:
    | {
        error: string | undefined;
      }
    | undefined;
  isSelectable: boolean | undefined;
  configStyles: string | undefined;
}>`
  box-sizing: border-box;
  display: block;
  border: ${(props) => props.theme.borders.normal || `0.0625rem`} solid
    ${(props) => props.theme.colors.darkGray2 || '#778AA5'};
  border-radius: ${(props) => props.theme.radius.sm || '0.5rem'};
  background-color: ${(props) => props.theme.colors.white || '#ffffff'};
  height: 100%;
  gap: 1rem;
  cursor: pointer;
  ${(props) => !props.isSelectable && `pointer-events: none;`}

  ${(props) => {
    if (props.formState.error !== undefined) {
      return `
       border-color: ${props.theme.colors.negative2 || '#AF0A45'};
       `;
    }
  }}

  ${(props) =>
    props.disabled &&
    `pointer-events: none;background-color: ${props.theme.colors.lightGray1 || '#F0F4FA'}; color: ${
      props.theme.colors.grayDark1 || '#778AA5'
    };`}

  &:hover:not(:disabled) {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.35);
  }

  &:active:not(:disabled) {
    border-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
    outline: ${(props) => props.theme.borders.normal || '0.0625rem'} solid
      ${(props) => props.theme.colors.primary1 || '#0279FE'};
    ${(props) => {
      if (props.formState.error !== undefined) {
        return `
       border-color: ${props.theme.colors.negative2 || '#AF0A45'};
       outline: ${props.theme.borders.normal || '0.0625rem'} solid ${props.theme.colors.negative2 || '#AF0A45'};
       `;
      }
    }}
  }

  &:focus-visible,
  &:focus {
    border-color: ${(props) => props.theme.colors.primary1 || '#0279FE'};
    ${(props) => {
      if (props.formState.error !== undefined) {
        return `border-color: ${props.theme.colors.negative2 || '#AF0A45'};`;
      }
    }}
  }

  ${(props) => {
    if (props.checked) {
      switch (true) {
        case props.formState.error !== undefined:
          return `
        border-color: ${props.theme.colors.negative2 || '#AF0A45'};
        outline: ${props.theme.borders.normal || '0.0625rem'} solid ${props.theme.colors.negative2 || '#AF0A45'};
        `;

        default:
          return `
          border-color: ${props.theme.colors.primary1 || '#0279FE'};
          outline: 1px solid ${props.theme.colors.primary1 || '#0279FE'};
          `;
      }
    }
  }}

  ${(props) => props.configStyles};
`;
