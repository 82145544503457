import React, { ReactNode } from 'react';

import * as Styled from './radio.styled';

export type RadioProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: any;

  /**
   * class names overrides
   */
  className?: string;

  /**
   * styling overrides
   */
  configStyles?: string | undefined;

  /**
   * to disable the radio element
   */
  disabled?: boolean;

  /**
   * display text value
   */
  fieldValue?: string;

  /**
   * Identifies the input[radio] semantic element
   */
  id: string;

  /**
   * Specifies the name of the radio element
   */
  name: string;

  /**
   * onChange event handler
   */
  onChange?: any;

  /**
   * Convention [Forms] : This prop is used to determine if a user input field has been validated or not
   */
  validationStatus?: boolean | undefined;

  /**
   * field value
   */
  value?: string;

  /**
   * this flag is used to hide the HTML control and be replaced by a child component like a pill, card, etc
   */
  isMasked?: boolean;
};

export function Radio({
  children = [],
  className,
  configStyles,
  disabled,
  isMasked = false,
  fieldValue = '',
  id,
  name,
  onChange,
  validationStatus,
  value = '',
}: RadioProps) {
  return (
    <Styled.Radio id={id} className={className} configStyles={configStyles}>
      <Styled.RadioInput
        checked={fieldValue.toString() === value.toString()}
        disabled={disabled}
        hideInput={isMasked}
        id={`${name}-${fieldValue}`}
        name={name}
        onChange={onChange}
        type="radio"
        value={fieldValue}
      />
      <Styled.RadioLabel htmlFor={`${name}-${fieldValue}`}>
        {React.Children.toArray(children).map((child) => {
          return React.cloneElement(child as React.ReactElement, {
            checked: fieldValue.toString() === value.toString(),
            validationStatus,
          });
        })}
      </Styled.RadioLabel>
    </Styled.Radio>
  );
}
