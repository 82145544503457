import React, { ReactNode, Children, useState, useRef, useEffect } from 'react';
import * as Styled from './select.styled';

export type SelectProps = {
  /**
   * For external libraries that implement icons as className
   */
  chevronIconClassName?: string;

  /**
   * An array of elements that make up the list of options.
   */
  children?: ReactNode[] | ReactNode;

  /**
   * to handle class names
   */
  className?: string | undefined;

  /**
   * to handle styles
   */
  configStyles?: string | undefined;

  /**
   * To disable the Select component.
   */
  disabled?: boolean;

  /**
   * Specifies a unique id for Select component.
   */
  id: string;

  /**
   * Specifies the name of Select component.
   */
  name: string;

  /**
   * A function to be triggered when the input element loses focus.
   */
  onBlur?: (e) => any;

  /**
   * A function to be triggered when an option from the list has been chosen.
   */
  onChange?: (e) => any;

  /**
   * The default child of the button element
   */
  placeholder?: string;

  /**
   * Convention [Forms] : This is prop (aux) is used to set this field's value on a form
   * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
   */
  setValue?: any;

  /**
   * To determine the Select status
   */
  validationStatus?: boolean;

  /**
   * Set a value for Select component.
   */
  value?: string;
};

export function Select({
  chevronIconClassName,
  children = [],
  className,
  configStyles,
  disabled,
  id,
  name,
  onBlur = () => {},
  onChange = () => {},
  placeholder,
  setValue = () => {},
  validationStatus = undefined,
  value,
}: SelectProps) {
  // Ensure children is always an array - per React's children optimization of object | array when there's a single child or children instead
  const selectNode = useRef<any>(null);
  const [toggleSelect, setToggleSelect] = useState<boolean>(false);

  const [displayValue, setDisplayValue] = useState<any>(
    // Children.toArray(children).find((element) => element['props']?.['attributes']?.['value'] === value) || '',
    Children.toArray(children).find((element) => {
      if (element['props']?.['attributes']?.['value'] === value || element['props']?.['value'] === value) {
        return element;
      } else {
        return '';
      }
    }),
  );

  useEffect(() => {
    if (!value) {
      setDisplayValue('');
    }
  }, [value]);

  const handleOutsideClick = (event) => {
    if (!selectNode.current.contains(event.target)) {
      setToggleSelect(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOptionClick = (value: string) => {
    const selectedOptionChildren = Children.toArray(children).find((element) => {
      if (element['props']?.['attributes']?.['value'] === value || element['props']?.['value'] === value) {
        return element;
      } else {
        return '';
      }
    });
    onChange(value);
    setValue(value);
    setToggleSelect(false);
    // Remove on click event if component is <SelectOption/>
    setDisplayValue(
      React.cloneElement(selectedOptionChildren as React.ReactElement, {
        onClick: () => {},
      }),
    );
  };

  return (
    <Styled.Select
      ref={selectNode}
      id={id}
      className={className}
      configStyles={configStyles}
      validationStatus={validationStatus}
    >
      <Styled.SelectButton
        name={name}
        type="button"
        value={displayValue}
        disabled={disabled}
        onClick={(event) => {
          setToggleSelect(!toggleSelect);
          event.stopPropagation();
        }}
        onBlur={(e) => {
          onBlur(e);
        }}
      >
        {displayValue || placeholder}
        <Styled.SelectButtonIcon className={chevronIconClassName} isOpen={toggleSelect} />
      </Styled.SelectButton>
      <Styled.SelectOptionList isOpen={toggleSelect}>
        {Children.toArray(children).map((option, index) => (
          <Styled.SelectOptionListItem
            tabIndex={index}
            isOption={option['props']?.['attributes']?.['value'] || option['props']?.['value'] ? true : false}
            key={`select-option-${index}`}
            role={option['props']?.['attributes']?.['value'] || (option['props']?.['value'] && 'option')}
          >
            {React.cloneElement(option as React.ReactElement, {
              onClick: (e) => {
                if (option['props']?.['attributes']?.['value'] || option['props']?.['value']) {
                  handleOptionClick(e);
                }
              },
            })}
          </Styled.SelectOptionListItem>
        ))}
      </Styled.SelectOptionList>
    </Styled.Select>
  );
}

export type SelectOptionProps = {
  /**
   * An array of elements that make up the list of options.
   */
  children?: ReactNode[] | ReactNode;

  /**
   * OnClick handler
   */
  onClick?: (e) => void;

  /**
   * Set a value for Select component.
   */
  value?: string;
};
export function SelectOption({ children, value, onClick = (e) => {} }: SelectOptionProps) {
  return (
    <Styled.SelectOption
      onClick={() => {
        onClick(value);
      }}
    >
      {children}
    </Styled.SelectOption>
  );
}
