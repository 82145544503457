import React from 'react';
import * as Styled from './input.styled';

export type InputProps = {
  /**
   * to allow the browser to predict the value
   */
  autoComplete?: string;

  /**
   * class names override
   */
  className?: string;

  /**
   * styling overrides
   */
  configStyles?: string;

  /**
   * to disable or enable the input element
   */
  disabled?: boolean;

  /**
   * an identifier for the input element
   */
  id: string;

  /**
   * specifies the name of the input element
   */
  name: string;

  /**
   * a function to be triggered when the input element loses focus
   */
  onBlur?: () => void;

  /**
   * a function to be triggered when the input element value change
   */
  onChange?: any;

  /**
   * a function to be triggered when the input element gets focus
   */
  onFocus?: () => void;

  /**
   * a placeholder for the input element
   */
  placeholder?: string;

  /**
   * to keep a user from changing the value
   */
  readOnly?: boolean;

  /**
   * to specify that the input must be filled
   */
  required?: boolean;

  /**
   * to choose a type for the input element (text or password)
   */
  type: 'text' | 'password';

  /**
   * field value
   */
  value?: string;

  /**
   * default field value
   */
  defaultValue?: string;
};

export function Input({
  autoComplete = 'off',
  className,
  configStyles,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly = false,
  required,
  type = 'text',
  value,
  defaultValue,
}: InputProps) {
  return (
    <Styled.Input
      autoComplete={autoComplete}
      className={className}
      configStyles={configStyles}
      disabled={disabled}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      type={type}
      {...(defaultValue ? { defaultValue: defaultValue } : { value: value })}
    />
  );
}
