var setup = function (callback) {
  var latestEnrollmentIdentifier = '';
  var latestSessionResult = null;
  var latestIDScanResult = null;
  var latestServerResult = null;
  var latestProcessor;

  function setLatestServerResult(responseJSON) {
    this.latestServerResult = responseJSON;
  }

  // Get the Session Token from the server
  function getSessionToken(sessionTokenCallback) {
    // Only handle session token error once
    var sessionTokenErrorHasBeenHandled = false;
    var XHR = new XMLHttpRequest();
    XHR.open('GET', window.Config.BaseURL + '/session-token');
    XHR.setRequestHeader('X-Device-Key', window.Config.DeviceKeyIdentifier);
    XHR.setRequestHeader('X-User-Agent', window.FaceTecSDK.createFaceTecAPIUserAgentString(''));

    XHR.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        var sessionToken = '';
        try {
          // Attempt to get the sessionToken from the response object.
          sessionToken = JSON.parse(this.responseText).sessionToken;
          // Something went wrong in parsing the response. Return an error.
          if (typeof sessionToken !== 'string') {
            if (sessionTokenErrorHasBeenHandled === false) {
              sessionTokenErrorHasBeenHandled = true;
              if (XHR.status >= 500) {
                console.log('Server error'); // Manage server error - showAdditionalScreensServerIsDown();
              } else {
                console.log('Token error'); // Manage token error - onServerSessionTokenError();
              }
            }
            return;
          }
        } catch (_a) {
          // Something went wrong in parsing the response. Return an error.
          if (sessionTokenErrorHasBeenHandled === false) {
            sessionTokenErrorHasBeenHandled = true;
            if (XHR.status >= 500) {
              console.log('Server error'); // Manage server error - showAdditionalScreensServerIsDown();
            } else {
              console.log('Token error'); // Manage token error - onServerSessionTokenError();
            }
          }
          return;
        }
        console.log('Hide loading (session token process)'); // SampleAppUtilities.hideLoadingSessionToken();
        sessionTokenCallback(sessionToken);
      }
    };
    // Wait 3s, if the request is not completed yet, show the session token loading screen
    window.setTimeout(function () {
      if (XHR.readyState !== XMLHttpRequest.DONE) {
        console.log('Show loading (session token process)'); // SampleAppUtilities.showLoadingSessionToken();
      }
    }, 3000);
    XHR.onerror = function () {
      // Something went wrong in the XHR request
      if (sessionTokenErrorHasBeenHandled === false) {
        sessionTokenErrorHasBeenHandled = true;
        if (XHR.status >= 500) {
          console.log('Server error'); // Manage server error - showAdditionalScreensServerIsDown();
        } else {
          console.log('Token error'); // Manage token error - onServerSessionTokenError();
        }
      }
    };
    XHR.send();
  }

  var getLatestEnrollmentIdentifier = function () {
    return this.latestEnrollmentIdentifier;
  };

  var clearLatestEnrollmentIdentifier = function () {
    this.latestEnrollmentIdentifier = '';
  };

  var onComplete = function (sessionResult, idScanResult, latestNetworkResponseStatus) {
    this.latestSessionResult = sessionResult;
    this.latestIDScanResult = idScanResult;
    callback(this.latestProcessor.isSuccess());
    if (this.latestProcessor.isSuccess()) {
      console.log('Success');
    } else {
      if (latestServerResult !== null) {
        console.log(latestServerResult);
        if (latestServerResult.meta.message !== null) {
          console.log(latestServerResult.meta.message);
          latestServerResult = null;
          return;
        }
      }
      console.log('Session exited early, see logs for more details.' + window.FaceTecSDK.getStatus());
      // Check for server offline
      if (latestNetworkResponseStatus >= 500) {
        console.log('Server is down.');
        return;
      }
    }
  };

  return {
    latestEnrollmentIdentifier: latestEnrollmentIdentifier,
    latestSessionResult: latestSessionResult,
    latestIDScanResult: latestIDScanResult,
    latestServerResult: latestServerResult,
    latestProcessor: latestProcessor,
    getSessionToken: getSessionToken,
    setLatestServerResult: setLatestServerResult,
    clearLatestEnrollmentIdentifier: clearLatestEnrollmentIdentifier,
    getLatestEnrollmentIdentifier: getLatestEnrollmentIdentifier,
    onComplete: onComplete,
  };
};

export default setup;
