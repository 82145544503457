import { BaseConfig_v1 } from '../../Globals';

const GET_INIT = `${BaseConfig_v1}/init`;
const GET_PAGE = `${BaseConfig_v1}/pages`;
const GET_LAYOUT = `${BaseConfig_v1}/layouts`;
const GET_REGIONS = `${BaseConfig_v1}/regions`;
const GET_COMPONENT = `${BaseConfig_v1}/components`;
const GET_DATA = `${BaseConfig_v1}/dynamic`;
const GET_TRIGGER = `${BaseConfig_v1}/trigger-process`;
const GET_SUBMIT = `${BaseConfig_v1}/submit`;
const GET_FILE = `${BaseConfig_v1}/files`;
const GET_THEMES = `${BaseConfig_v1}/themes`;

export {
  GET_INIT,
  GET_PAGE,
  GET_REGIONS,
  GET_LAYOUT,
  GET_COMPONENT,
  GET_DATA,
  GET_TRIGGER,
  GET_SUBMIT,
  GET_FILE,
  GET_THEMES,
};
