import React from 'react';
import { Formik, useFormikContext, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';

import styled from 'styled-components';
import { Toast } from '@pefai/scl-react.components.informational.notifications.toast';

// API
import { OneAppAPI } from 'Infrastructure/API/Configs/OneApp.api';

// Utils
import utils from 'Application/utils';

// Hooks
import { useDebounce } from 'Application/hooks';

// Actions
import { execute, ExecutableActionsType } from 'Application/actions';

type Values = {
  [key: string]: any;
};

// ToDo - Request : submit from attributes to event/setting?
type FormProps = {
  form_id: string;
  step_id?: string;
  submit: {
    onValid?: boolean;
    onError?: ExecutableActionsType;
    onInvalidForm?: ExecutableActionsType;
  };
  values: Values;
  validations: any;
  children: React.ReactNode;
};

const AutoSubmitOnValidHandler = () => {
  const { dirty, isValid, isSubmitting, values, submitForm } = useFormikContext();
  const submit = () => dirty && isValid && !isSubmitting && submitForm();
  useDebounce(submit, 500, [values]);
  return null;
};

const InvalidFormHandler = (errorConfigurations) => {
  const { isValid, submitCount, errors } = useFormikContext();

  useDebounce(
    () => {
      if (!isValid && submitCount > 0) {
        execute(errorConfigurations.errorConfigurations, { name: Object.keys(errors).at(0) });
      }
    },
    100,
    [isValid, submitCount],
  );

  return null;
};

export const Form = ({ form_id, step_id, submit, values, validations, children }: FormProps) => {
  const iv = Object.entries(values).reduce(utils.Object.replacer(''), {});

  const onSubmit = async (values: Values, { setSubmitting, setErrors, setStatus }: FormikHelpers<Values>) => {
    const payload = {
      form_id,
      step_id,
      values: Object.entries(values).reduce(utils.Object.replacer(null), {}),
    };
    setStatus(undefined);

    try {
      const actions = await OneAppAPI.getSubmit(payload);
      if (actions) execute(actions);
    } catch (error: any) {
      setStatus(error.data.message);
      error.data.fields && setErrors(error.data.fields);
      // ToDo: Design how this should be actions payload from the getSubmit call
      // if(actions) execute(actions)
      if (submit.onError) execute(submit.onError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={iv}
      validateOnMount={true}
      validate={(values) => {
        const validationsFormatter = (fields: any) => {
          return Object.keys(fields).reduce((accum: any, key: any) => {
            if (!fields[key].checks) {
              accum[key] = fields[key];
              return accum;
            }
            const evaluator = (condition): boolean => {
              const value = utils.Object.resolvePath(values, condition.ref);
              return utils.Validation.test.test(condition.key, value, condition.val);
            };
            const checks = fields[key].checks.filter(
              (c) => !c.condition || utils.Array.arrayEvaluator(c.condition, evaluator),
            );
            accum[key] = { ...fields[key], checks: checks };
            return accum;
          }, {});
        };

        try {
          const vs = validations && utils.Validation.schema.validationSchema(validationsFormatter(validations));
          validateYupSchema(values, vs, true);
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={onSubmit}
    >
      {({ handleReset, handleSubmit, status }) => (
        <StyledForm onReset={handleReset} onSubmit={handleSubmit}>
          <Toast isToastOpen={status} mode="default" positioning="relative" size="100%" variant="danger">
            {status}
          </Toast>
          {children}
          {submit.onValid && <AutoSubmitOnValidHandler />}
          {submit.onInvalidForm ? <InvalidFormHandler errorConfigurations={submit.onInvalidForm} /> : null}
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled.form`
  height: 100%;
`;
