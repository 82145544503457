import React, { ReactNode, useState, useRef, useEffect } from 'react';
import * as Styled from './otpverification.styled';

import { Button } from '@pefai/scl-react.components.actionable.buttons.button';
import { Formfield } from '@pefai/scl-react.components.forms.form-fields.formfield';
import { Telephone } from '@pefai/scl-react.components.forms.form-fields.telephone';
import { Maskeddigitinput } from '@pefai/scl-react.components.forms.form-fields.maskeddigitinput';
import { Dotloader } from '@pefai/scl-react.components.informational.loaders.dotloader';

export type OtpverificationProps = {
  /**
   * class names override
   */
  className?: string;

  /**
   * a message about the OTP code input state : error
   */
  codeErrorMsg?: ReactNode;

  /**
   * a string to be rendered as a label for the OTP code input
   */
  codeLabel?: string;

  /**
   * it defines the number of inputs (digits of the OTP code)
   */
  codeLength?: number;

  /**
   * a message about the OTP code input state
   */
  codeMsg?: ReactNode;

  /**
   * label text for the button responsible for resending the code to the user
   */
  codeResendButtonLabel?: ReactNode;

  /**
   * label text for the button responsible for sending the code to the OTP
   */
  codeSendButtonLabel?: string;

  /**
   * a message about the OTP code input state : success
   */
  codeSuccessMsg?: ReactNode;

  /**
   * code submit endpoint
   */
  codeValidationEndpoint?: string;

  /**
   * styling overrides
   */
  configStyles?: string;

  /**
   * to disable both the telephone input and the code input
   */
  disabled?: boolean;

  /**
   * HTTP Client Optional Configurations
   */
  handlers: {
    http: {
      headers: any;
      baseURL: string;
    };
  };

  /**
   * defines if the digit input should be masked or not
   */
  isMasked?: boolean;

  /**
   * based on Formik
   * meta object to handle field additional descriptive data
   *  - error
   *  - touched
   *  - value
   */
  meta?: any;

  /**
   * name of the telephone input
   */
  name: string;

  /**
   * function to handle field blur
   */
  onBlur?: any;

  /**
   * function to handle field on change
   */
  onChange?: any;

  /**
   * function to handle update parent errors
   */
  setError?: any;

  /**
   * function to handle updates on parent touched field status
   */
  setTouched?: any;

  /**
   * function to update parent's value
   */
  setValue?: any;

  /**
   * label text for the button responsible for sending the code to the user
   */
  telephoneButtonLabel?: string;

  /**
   * specifies the telephone format
   */
  telephoneFormat?: string;

  /**
   * telephone submit endpoint
   */
  telephoneHandlerEndpoint?: string;

  /**
   * a text to help the user about the telephone input itself
   */
  telephoneHelpText?: ReactNode;

  /**
   * id of the telephone input
   */
  telephoneId?: string;

  /**
   * a string to be rendered as a label for the telephone input
   */
  telephoneLabel?: ReactNode;

  /**
   * a message about the telephone input state
   */
  telephoneMsg?: ReactNode;

  /**
   * a placeholder for the telephone input
   */
  telephonePlaceholder?: string;

  /**
   * field value
   */
  value?: string;
};

export function Otpverification({
  codeErrorMsg,
  codeLabel,
  codeLength = 6,
  codeMsg,
  codeResendButtonLabel,
  codeSendButtonLabel,
  codeSuccessMsg,
  codeValidationEndpoint = 'oneapp/v1/otp/validate',
  handlers: { http },
  isMasked,
  meta,
  name,
  onBlur,
  onChange,
  setError,
  setTouched,
  setValue,
  telephoneButtonLabel,
  telephoneFormat = '+(##)##-####-####',
  telephoneHandlerEndpoint = 'oneapp/v1/otp/send',
  telephoneHelpText,
  telephoneId,
  telephoneLabel,
  telephoneMsg,
  telephonePlaceholder,
}: OtpverificationProps) {
  const [fieldMode, setFieldMode] = useState<boolean>(true);
  const [isCodeValid, setIsCodeValid] = useState<boolean | undefined>(undefined);
  const [resetFieldValidation, setResetFieldValidation] = useState<boolean>(false);
  const [metaState, setMetaState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [otpCode, setOtpCode] = useState<string>('');

  const [telephoneNumber, setTelephoneNumber] = useState<string>('');
  const transactionId = useRef<string>('');

  const { value } = meta;
  const { headers, baseURL } = http;

  useEffect(() => {
    if (value) {
      setTelephoneNumber(value);
      setIsCodeValid(true);
      setFieldMode(false);
    }
  }, []);

  useEffect(() => {
    if (fieldMode) {
      setMetaState(meta);
    } else {
      if (isCodeValid === false) {
        setMetaState({
          ...meta,
          error: codeErrorMsg,
        });
      } else if (isCodeValid === undefined) {
        setMetaState({
          ...meta,
          value: '',
        });
      } else {
        setMetaState({
          ...meta,
          touched: true,
        });
      }
    }
  }, [isCodeValid, meta]);

  async function submitTelephoneNumber(formattedNumber: string) {
    const unFormattedNumber = formattedNumber.replace(/[+-\s]/g, '').split(/\(([^\)]+)\)/);
    setIsSubmitting(true);

    fetch(`${baseURL}${telephoneHandlerEndpoint}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        countryCode: `+${unFormattedNumber[1]}`,
        phoneNumber: unFormattedNumber[2],
      }),
    })
      .then((response: any) => {
        if (response.ok) {
          // setTelephoneNumber(value);
          // Component toggle
          setFieldMode(false);
          setResetFieldValidation(true);
          // Meta manipulaton
          setError(false);
          setTouched(false, false);
        } else {
          throw new Error(`Service not found`);
        }
        return response.json();
      })
      .then((response: any) => {
        transactionId.current = response['transactionId'];
        setIsSubmitting(false);
      })
      .catch((error: any) => {
        setMetaState({
          ...meta,
          error: `${error}`,
          touched: true,
        });
        setIsSubmitting(false);
      });
  }

  function submitOTPCode(otpCode: string) {
    fetch(`${baseURL}${codeValidationEndpoint}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        otpCode: otpCode,
        transactionId: transactionId.current,
      }),
    })
      .then((response: any) => {
        if (response.ok) {
          setIsCodeValid(true);
          setValue(telephoneNumber);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        setIsCodeValid(false);
      });
  }

  return (
    <Styled.Otpverification isSubmitting={isSubmitting}>
      <Formfield
        name={name}
        id={`formfieldCodeContainer`}
        label={
          fieldMode ? (
            telephoneLabel
          ) : (
            <>
              {codeLabel} <Styled.PhoneNumberLabel>{telephoneNumber}</Styled.PhoneNumberLabel>
            </>
          )
        }
        msg={fieldMode ? telephoneMsg : isCodeValid === true ? codeSuccessMsg : codeMsg}
        helpText={
          fieldMode
            ? telephoneHelpText
            : isCodeValid !== true && (
                <Styled.ResendButton
                  onClick={() => {
                    setFieldMode(true);
                    setValue('', false);
                    setIsCodeValid(undefined);
                    setOtpCode('');
                  }}
                >
                  {codeResendButtonLabel}
                </Styled.ResendButton>
              )
        }
        clearValidation={resetFieldValidation}
        meta={metaState}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        {fieldMode ? (
          /**
           * * - Mode: Telephone Input
           */
          <>
            <Telephone
              id={telephoneId}
              format={telephoneFormat}
              placeholder={telephonePlaceholder}
              onChange={(event) => {
                setTelephoneNumber(event.target.value);
              }}
              onBlur={(event) => {
                onBlur(event);
              }}
              disabled={isSubmitting}
            />
            <Button
              type="button"
              variant="button-primary"
              onClick={() => {
                submitTelephoneNumber(telephoneNumber);
              }}
              disabled={(() => {
                if (isSubmitting) {
                  return true;
                }
                if (telephoneNumber) {
                  return (
                    telephoneFormat.replace(/[+-\s]/g, '').split(/\(([^\)]+)\)/)[2].length !==
                    // eslint-disable-next-line no-useless-escape
                    telephoneNumber.replace(/[+-\s]/g, '').split(/\(([^\)]+)\)/)[2].length
                  );
                } else {
                  return true;
                }
              })()}
            >
              {isSubmitting ? <Dotloader /> : telephoneButtonLabel}
            </Button>
          </>
        ) : (
          /**
           * * Mode: OTP [length] input
           */
          <>
            <Maskeddigitinput
              id="masked-input"
              name="masked-input"
              isCodeValid={isCodeValid}
              codeLength={codeLength}
              onChangeHandler={(value: string) => {
                setOtpCode(value);
              }}
              onFocusHandler={() => {
                if (!isCodeValid) {
                  setIsCodeValid(undefined);
                  setOtpCode('');
                }
              }}
              isMasked={isMasked}
              onPaste={(value: string) => {
                submitOTPCode(value);
              }}
            />
            {isCodeValid !== true && (
              <Button
                type="button"
                variant="button-primary"
                onClick={() => {
                  submitOTPCode(otpCode);
                }}
                disabled={otpCode.length < codeLength}
              >
                {codeSendButtonLabel}
              </Button>
            )}
          </>
        )}
      </Formfield>
    </Styled.Otpverification>
  );
}
