import {
  AutocompleteOption,
  Autocompleteselect,
} from '@pefai/scl-react.components.forms.form-fields.autocompleteselect';
import { Button } from '@pefai/scl-react.components.actionable.buttons.button';
import { Card } from '@pefai/scl-react.components.actionable.cards.card';
import { Carousel } from '@pefai/scl-react.components.layouts.containers.carousels.carousel';
import { Checkbox } from '@pefai/scl-react.components.forms.form-fields.checkbox';
import { Circularloader } from '@pefai/scl-react.components.informational.loaders.circularloader';
import { Columns } from '@pefai/scl-react.components.layouts.containers.columns';
import { Container } from '@pefai/scl-react.components.layouts.containers.container';
import { Countdown } from '@pefai/scl-react.components.informational.counters.countdown';
import { Currency } from '@pefai/scl-react.components.forms.form-fields.currency';
import { Dotloader } from '@pefai/scl-react.components.informational.loaders.dotloader';
import { Dropdown } from '@pefai/scl-react.components.actionable.dropdowns.dropdown';
import { Email } from '@pefai/scl-react.components.forms.form-fields.email';
import { Fieldelement } from '@pefai/scl-react.components.forms.form-fields.fieldelement';
// import { Filebrowser } from '@pefai/scl-react.components.forms.form-fields.filebrowser';
import { Filebrowser } from 'Infrastructure/components/filebrowser';
import { Flatnavigation, Navlinks } from '@pefai/scl-react.components.navigation.flatnavigation';
import { Formfield } from '@pefai/scl-react.components.forms.form-fields.formfield';
import { Formsubmit } from '@pefai/scl-react.components.forms.submit.formsubmit';
import { Googlemaps } from '@pefai/scl-react.components.informational.location.googlemaps';
import { Grid } from '@pefai/scl-react.components.layouts.containers.grid';
import { Heading } from '@pefai/scl-react.components.typography.heading';
import { Icon } from '@pefai/scl-react.components.actionable.icons.icon';
import { Image } from '@pefai/scl-react.components.informational.media.image';
import { Input } from '@pefai/scl-react.components.forms.form-fields.input';
import { Label } from '@pefai/scl-react.components.forms.form-fields.label';
import { Link } from '@pefai/scl-react.components.navigation.link';
import { List } from '@pefai/scl-react.components.informational.lists.list';
// import { Modal } from '@pefai/scl-react.components.layouts.containers.modal';
import { Number } from '@pefai/scl-react.components.forms.form-fields.number';
import { Otpverification } from '@pefai/scl-react.components.forms.authentication.one-time-password.otpverification';
import { Parragraph } from '@pefai/scl-react.components.typography.parragraph';
import { Pdfviewer } from '@pefai/scl-react.components.informational.documentmanagers.pdfviewer';
import { Pill } from '@pefai/scl-react.components.actionable.pills.pill';
import { Progressindicator } from '@pefai/scl-react.components.informational.progressindicator';
import { Radio } from '@pefai/scl-react.components.forms.form-fields.radio';
import { Rangeslider } from '@pefai/scl-react.components.actionable.range.rangeslider';
import { Rows } from '@pefai/scl-react.components.layouts.containers.rows';
import { Select, SelectOption } from '@pefai/scl-react.components.forms.form-fields.select';
import { Staticcalendar } from '@pefai/scl-react.components.informational.calendars.staticcalendar';
import { Svgindicator } from '@pefai/scl-react.components.informational.svgindicator';
import { Telephone } from '@pefai/scl-react.components.forms.form-fields.telephone';
import { Text } from '@pefai/scl-react.components.typography.text';
import { Textarea } from '@pefai/scl-react.components.forms.form-fields.textarea';
import { Toast } from '@pefai/scl-react.components.informational.notifications.toast';
import { Toggle } from '@pefai/scl-react.components.actionable.switches.toggle';
import { Typeahead } from '@pefai/scl-react.components.forms.autocomplete.typeahead';
import { Url } from '@pefai/scl-react.components.forms.form-fields.url';
import { Video } from '@pefai/scl-react.components.informational.media.video';

// Normalization effort
import { Form } from 'Presentation/container/Form/Form';
import FaceTec from 'Infrastructure/components/facetec';
import { Catalog } from 'Presentation/container/catalog';
import { Conditional } from 'Presentation/container/catalog';
import { Modal } from 'Infrastructure/components/modal';

const Components: { [key: string]: any } = Object.freeze({
  AutocompleteOption,
  Autocompleteselect,
  Button,
  Card,
  Carousel,
  Catalog,
  Checkbox,
  Circularloader,
  Columns,
  Conditional,
  Container,
  Countdown,
  Currency,
  Dotloader,
  Dropdown,
  Email,
  FaceTec,
  Fieldelement,
  Filebrowser,
  Flatnavigation,
  Form,
  Formfield,
  Formsubmit,
  Googlemaps,
  Grid,
  Heading,
  Icon,
  Image,
  Input,
  Label,
  Link,
  List,
  Modal,
  Navlinks,
  Number,
  Otpverification,
  Parragraph,
  Pdfviewer,
  Pill,
  Progressindicator,
  Radio,
  Rangeslider,
  Rows,
  Select,
  SelectOption,
  Staticcalendar,
  Svgindicator,
  Telephone,
  Text,
  Textarea,
  Toast,
  Toggle,
  Typeahead,
  Url,
  Video,
});

export default Components;
