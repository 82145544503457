import styled from 'styled-components';

export const RangeContainer = styled.div<{
  disabled: boolean | undefined;
  isRangeValid: boolean | undefined;
  configStyles: string | undefined;
}>`
  position: relative;
  height: ${(props) => props.theme.spacings.xxxlg};
  display: flex;
  align-items: center;
  justify-content: center;
  input[type='range'] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    pointer-events: none;
    height: 0;
    width: 100%;
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    margin-top: 0.25rem;
    border: ${(props) => props.theme.borders.normal} solid
      ${(props) =>
        props.disabled === true
          ? props.theme.colors.darkGray2
          : props.isRangeValid === false
          ? props.theme.colors.negative2
          : props.theme.colors.primary2};
    border-radius: 50%;
    height: ${(props) => props.theme.spacings.sm};
    width: ${(props) => props.theme.spacings.sm};
    pointer-events: ${(props) => (props.disabled === true ? 'none' : 'all')};
    cursor: pointer;
    background-color: ${(props) =>
      props.disabled === true ? props.theme.colors.lightGray1 : props.theme.colors.white};
    &:hover {
      border-width: ${(props) => props.theme.borders.highlight};
    }
  }
  input[type='range']:active::-webkit-slider-thumb,
  input[type='range']:focus::-webkit-slider-thumb,
  input[type='range']:focus-visible::-webkit-slider-thumb {
    border-width: ${(props) => props.theme.borders.normal};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1};
    outline: none;
  }
  input[type='range']::-moz-range-thumb {
    position: relative;
    margin-top: 0.25rem;
    border: ${(props) => props.theme.borders.normal} solid
      ${(props) =>
        props.disabled === true
          ? props.theme.colors.darkGray2
          : props.isRangeValid === false
          ? props.theme.colors.negative2
          : props.theme.colors.primary2};
    border-radius: 50%;
    height: ${(props) => props.theme.spacings.sm};
    width: ${(props) => props.theme.spacings.sm};
    transform: translateY(0.125rem);
    pointer-events: ${(props) => (props.disabled === true ? 'none' : 'all')};
    cursor: pointer;
    background-color: ${(props) =>
      props.disabled === true ? props.theme.colors.lightGray1 : props.theme.colors.white};
    &:hover {
      border-width: ${(props) => props.theme.borders.highlight};
    }
  }
  input[type='range']:active::-moz-range-thumb,
  input[type='range']:focus::-moz-range-thumb,
  input[type='range']:focus-visible::-moz-range-thumb {
    border-width: ${(props) => props.theme.borders.normal};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.primary1};
    outline: none;
  }

  ${(props) => props.configStyles};
`;

export const CurrentValuesContainer = styled.div<{ disabled: boolean | undefined; isRangeValid: boolean | undefined }>`
  width: 100%;
  display: flex;
  position: absolute;
  > div {
    position: absolute;
    bottom: ${(props) => props.theme.spacings.default};
    font-size: ${(props) => props.theme.typography.base3};
    padding: 0.2rem 0.3rem;
    border-radius: ${(props) => props.theme.radius.sm};
    background-color: ${(props) =>
      props.disabled === true
        ? props.theme.colors.darkGray4
        : props.isRangeValid === false
        ? props.theme.colors.lightAccent1
        : props.theme.colors.tertiary2};
    color: ${(props) =>
      props.disabled === true
        ? props.theme.colors.darkGray2
        : props.isRangeValid === false
        ? props.theme.colors.negative2
        : props.theme.colors.primary2};
  }
`;

export const MinCurrentValue = styled.div``;

export const MaxCurrentValue = styled.div`
  right: 0;
`;

export const InputRangeMin = styled.input.attrs({ type: 'range' })`
  z-index: 3;
`;

export const InputRangeMax = styled.input.attrs({ type: 'range' })`
  z-index: 4;
`;

export const Slider = styled.div`
  position: relative;
  width: 100%;
  > div:nth-child(1),
  div:nth-child(2) {
    position: absolute;
    border-radius: 0.25rem;
    height: 0.2rem;
  }
  > div:nth-child(3),
  div:nth-child(4) {
    position: absolute;
    color: ${(props) => props.theme.colors.darkGray2};
    font-size: ${(props) => props.theme.typography.base4};
    top: ${(props) => props.theme.spacings.default};
  }
`;

export const Track = styled.div<{
  sliderType: string | undefined;
  disabled: boolean | undefined;
  isRangeValid: boolean | undefined;
}>`
  width: 100%;
  z-index: 1;
  background-color: ${(props) =>
    props.sliderType === 'single' && props.disabled === true
      ? props.theme.colors.darkGray2
      : props.sliderType === 'range' && props.disabled === true
      ? props.theme.colors.darkGray4
      : props.sliderType === 'single' && props.isRangeValid === false
      ? props.theme.colors.negative2
      : props.sliderType === 'range' && props.isRangeValid === false
      ? props.theme.colors.darkGray2
      : props.sliderType === 'single' && !props.disabled
      ? props.theme.colors.primary2
      : props.sliderType === 'range' && !props.disabled
      ? props.theme.colors.darkGray2
      : props.theme.colors.primary2};
`;

export const Range = styled.div<{
  sliderType: string | undefined;
  disabled: boolean | undefined;
  isRangeValid: boolean | undefined;
}>`
  z-index: 2;
  background-color: ${(props) =>
    props.sliderType === 'single' && props.disabled === true
      ? props.theme.colors.darkGray4
      : props.sliderType === 'range' && props.disabled === true
      ? props.theme.colors.darkGray2
      : props.sliderType === 'single' && props.isRangeValid === false
      ? props.theme.colors.darkGray2
      : props.sliderType === 'range' && props.isRangeValid === false
      ? props.theme.colors.negative2
      : props.sliderType === 'range' && !props.disabled
      ? props.theme.colors.primary2
      : props.sliderType === 'single' && !props.disabled
      ? props.theme.colors.darkGray2
      : props.theme.colors.primary2};
`;

export const MinValue = styled.div``;

export const MaxValue = styled.div`
  right: 0;
`;
