import { useForm } from './useForm';
import { useHandlers } from './useHandlers';
import { useModel } from './useModel';

const Settings: { [key: string]: any } = Object.freeze({
  form: useForm,
  handlers: useHandlers,
  model: useModel,
});

/**
 * Configuration json schema attribute - "settings"
 *  "settings": {
 *    "form": {
 *      "type": "form" | "field" | "meta",
 *      "visible": [
 *         {
 *          "ref": "name",
 *          "key": "equals",
 *          "val": "some string value"
 *        }
 *      ]
 *    },
 *    "handlers": {
 *      "file": "true"
 *    }
 *  }
 */
export const useConfig = (settings, attributes, id) => {
  if (!settings) return { visible: true };
  const config = Object.entries(settings).reduce((accum, [key, value]) => {
    if (key in Settings) {
      const schema = Settings[key](value, attributes, id);
      return { ...accum, ...schema };
    }
    return accum;
  }, {});
  if (config['visible'] === undefined) return { ...config, visible: true };

  return { ...config };
};
