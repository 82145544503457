import { useEffect } from 'react';

interface Script {
  src: string;
  type?: string;
  async?: boolean;
  defer?: boolean;
  scope?: 'head' | 'body';
}

const useScript = ({ src, async = false, scope = 'head' }: Script) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = async;
    document[scope].append(script);

    return () => {
      document[scope].removeChild(script);
    };
  }, [src]);
};

export default useScript;
