import React, { ReactNode } from 'react';
import * as Styled from './rows.styled';

export type RowsProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  /**
   * classes for external library implementations
   */
  className?: string;
  /**
   * styling configuration overrides
   */
  configStyles?: string;
  /**
   * inherited props from higher components
   */
  props?: any;
};

export function Rows({ children, className = '', configStyles, id }: RowsProps) {
  return (
    <Styled.Rows className={className} configStyles={configStyles} id={id}>
      {children}
    </Styled.Rows>
  );
}
