import React, { ReactNode } from 'react';
import * as Styled from './pill.styled';

export type PillProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * a styling element to inherit active status
   */
  checked?: boolean;

  /**
   * a node to be rendered in the special component.
   */
  children: ReactNode;

  /**
   * a styling element to inherit active status
   */
  disabled?: boolean | undefined;

  /**
   * based on Formik
   * meta object to handle field additional descriptive data
   *  - error
   *  - touched
   *  - value
   */
  meta?: any;

  /**
   * field name to be attached to the component
   */
  name?: string;
};

export function Pill({ name, children, checked, disabled, meta = {}, id }: PillProps) {
  return (
    <Styled.Pill name={name} checked={checked} disabled={disabled} formState={meta} id={id}>
      {children}
    </Styled.Pill>
  );
}
