import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { OneAppAPI } from '../../../../Infrastructure/API/Configs/OneApp.api';
import { store } from '../../store';

type OneAppType = {
  appId: string;
  appName: string;
  orgName: string;
  organizationId: string;
  pageId: string;
  path: string;
  layoutId: string;
};

const OneAppInitialState: OneAppType = {
  appId: '',
  appName: '',
  orgName: '',
  organizationId: '',
  pageId: '',
  path: '',
  layoutId: '',
};

const OneAppModel = createModel<RootModel>()({
  state: {
    ...OneAppInitialState,
  } as OneAppType,
  reducers: {
    INTI_CONFIG(state, { appName, orgName }) {
      return {
        ...state,
        appName,
        orgName,
      };
    },
    INIT_APP(state, { id, organizationId, appThemes }) {
      return {
        ...state,
        appId: id,
        organizationId,
        appThemes,
      };
    },
    SET_PAGE_DATA(state, { pageId }) {
      return {
        ...state,
        pageId,
      };
    },
    SET_LAYOUT_ID(state, layoutId: string) {
      return {
        ...state,
        layoutId,
      };
    },
  },
  effects: (dispatch) => ({
    async configParams({ orgName, appName }) {
      const storedData = localStorage.getItem(`oa:${orgName}.${appName}`);
      if (storedData) {
        await dispatch.CredentialsModel.restoreCredentials({ orgName, appName });
      }
      await dispatch.OneAppModel.INTI_CONFIG({ appName, orgName });
    },
    async initApp({ appId, organizationId }) {
      const { orgName, appName } = store.getState().OneAppModel;
      const storedData = localStorage.getItem(`oa:${orgName}.${appName}`);

      if (!storedData) {
        await dispatch.CredentialsModel.getCredentials({ organizationId });
      }

      // ToDo: Store current theme, else default to first in object
      await dispatch.OneAppModel.INIT_APP({
        id: appId,
        organizationId,
      });
    },
    async getPage({ pageId }) {
      await dispatch.OneAppModel.SET_PAGE_DATA({ pageId });
    },

    async getLayout({ pageId }) {
      const layout = await OneAppAPI.getLayout(pageId);
      await dispatch.OneAppModel.SET_LAYOUT_ID(layout.id);
    },
    async getRegion() {},
    /**
     * navigateTo: Navigate to externa site
     */
    async navigateTo({ url }) {
      window.location.href = url;
    },
  }),
});

export { OneAppModel };
