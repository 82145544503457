import { Routes, Route } from 'react-router-dom';

import { Page } from './Presentation/component/Page';

function App() {
  return (
    <Routes>
      <Route path="/:orgName/:appName" element={<Page />}>
        <Route path="*" element={<Page />} />
      </Route>
    </Routes>
  );
}

export default App;
