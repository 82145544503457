import React, { ReactNode, useState } from 'react';
import * as Styled from './text.styled';

export type TextProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  /**
   * classes for external library implementations
   */
  className?: string;

  /**
   * for component level styling override (Design System)
   * @config implementation required
   */
  configStyles?: string;

  /**
   * To identify the Text component
   */
  id?: string;

  /**
   * semantic and regular text types
   */
  type?: 'bold' | 'deleted' | 'emphasis' | 'italic' | 'small' | 'span' | 'strong' | 'subscript' | 'superscript';

  /**
   * a node to render wrapless text value
   */
  value?: string;

  onBlur?: (e: any) => {};
  /**
   * a property to know if the rendered text is editable
   */
  isTextEditable?: boolean;

  /**
   * property that is passed to the component to know if it is inside a drag n drop enviroment
   */
  isDragDrop?: boolean;
};

const TextSemanticTypes: Record<string, React.ElementType> = {
  bold: 'b',
  deleted: 'del',
  emphasis: 'em',
  italic: 'i',
  small: 'small',
  span: 'span',
  strong: 'strong',
  subscript: 'sub',
  superscript: 'sup',
};

function TextElement(textProps: TextProps) {
  const {
    children,
    className,
    configStyles = '',
    id,
    type = '',
    value = 'Default Value',
    onBlur = (e) => {},
    isTextEditable,
  } = textProps;
  const [content, setContent] = useState<string>(value);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0);

  if (textProps.isDragDrop || isTextEditable) {
    const handleDoubleCick = () => {
      setIsEditable(true);
    };

    const handleBlur = (e: { target: { value: React.SetStateAction<string> } }) => {
      setIsEditable(false);
      setContent(e.target.value);
      onBlur(e.target.value);
    };

    const handleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
      setContent(e.target.value);
    };

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      e.target.select();
    };

    return (
      <Styled.EditableElement
        id={id}
        as={TextSemanticTypes[type]}
        className={className}
        onDoubleClick={handleDoubleCick}
      >
        {isEditable ? (
          <Styled.TextInput
            defaultValue={content}
            onBlur={handleBlur}
            onFocus={(e) => handleFocus(e)}
            onKeyUp={(e: any) => {
              setTextAreaHeight(e.target.scrollHeight);
              e.preventDefault();
              handleChange(e);
            }}
            height={textAreaHeight}
            autoFocus
          />
        ) : (
          <>{content}</>
        )}
      </Styled.EditableElement>
    );
  } else {
    if (TextSemanticTypes.hasOwnProperty(type)) {
      return (
        <Styled.Element as={TextSemanticTypes[type]} className={className} configStyles={configStyles} id={id}>
          {children}
        </Styled.Element>
      );
    } else {
      return <>{value}</>;
    }
  }
}

export function Text(textProps: TextProps) {
  return <TextElement {...textProps} />;
}
