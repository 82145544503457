import styled from 'styled-components';

export const Checkbox = styled.div<any>`
  display: flex;
  align-items: center;
  ${(props) => props.configStyles};
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })<{ hideInput: boolean }>`
  ${(props) => {
    if (props.hideInput) {
      return `
      +label{
        height: 100%;
      }
      `;
    }
  }}

  margin: 0 ${(props) => props.theme.spacings.xsm} 0 0;
  appearance: none;
  display: ${(props) => (props.hideInput ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  border: ${(props) => props.theme.borders.highlight} solid ${(props) => props.theme.colors.darkGray1 || '#778AA5'};
  border-radius: 0.25rem;
  height: ${(props) => props.theme.spacings.default};
  width: ${(props) => props.theme.spacings.default};
  overflow: hidden;
  outline: none;

  &:active {
    border-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
  }

  &:checked {
    &:after {
      content: '';
      margin-bottom: 3px;
      width: 6px;
      height: 3px;
      border-left: 0.125rem solid #0279fe;
      border-bottom: 0.125rem solid #0279fe;
      transform: rotate(-53deg);
    }
  }

  &:hover:not(:disabled) {
    border-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
  }

  &:focus,
  &:focus-visible {
    border-color: ${(props) => props.theme.colors.primary2 || '#004DAD'};
    outline: none;
  }

  &:disabled {
    border: ${(props) => props.theme.borders.highlight} solid ${(props) => props.theme.colors.darkGray3 || '#436087'};
    background-color: ${(props) => props.theme.colors.lightGray1};
  }

  &:disabled + label {
    color: ${(props) => props.theme.colors.darkGray4};
  }
`;

export const CheckboxLabel = styled.label`
  font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
  color: ${(props) => props.theme.colors.darkGray1};
  outline: 0;
`;
