import React, { ReactNode, useState, useEffect, useRef, useCallback } from 'react';
import * as Styled from './autocompleteselect.styled';
import { Portal } from 'react-portal';

const getOptionProps = (options: any, type: string) => {
  if (options?.['props']?.['attributes']?.[type]) {
    return options?.['props']?.['attributes']?.[type];
  } else if (options?.['props']?.[type]) {
    return options?.['props']?.[type];
  }
};

const getChunkedOptions = (options: ReactNode[], optionsChunkSize: number) => {
  return options.filter((option, index) => index < optionsChunkSize);
};

export type AutocompleteselectProps = {
  /**
   * these are the options of the AutcompleteSelect
   */
  children?: ReactNode;

  /**
   * to handle class names
   */
  classNames?: string | undefined;

  /**
   * to handle styles
   */
  configStyles?: string | undefined;

  /**
   * To disable the AutcompleteSelect component.
   */
  disabled?: boolean;

  /**
   * Specifies a unique id for AutcompleteSelect component.
   */
  id: string;

  /**
   * a threshold to start filtering results, when set to 0 the Autocomplete component show all results on click and starts filtering as the user types
   */
  inputThreshold?: number;

  /**
   * Specifies the name of AutcompleteSelect component.
   */
  name: string;

  /**
   * determines the max number of options showed at once
   */
  optionsChunkSize?: number;

  /**
   * The default label for the AutocompleteSelect component
   */
  placeholder?: string;

  /**
   * Convention [Forms] : This is prop (aux) is used to set this field's value on a form
   * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
   */
  setValue?: any;

  /**
   * To determine the Select status
   */
  validationStatus?: boolean;

  /**
   * Set a value for Select component.
   */
  value?: string;
};

export function Autocompleteselect({
  children,
  classNames,
  configStyles,
  disabled,
  id,
  inputThreshold = 0,
  name,
  validationStatus,
  value,
  setValue,
  optionsChunkSize,
  placeholder,
}: AutocompleteselectProps) {
  const [toggleSelect, setToggleSelect] = useState<boolean>(false);
  const [typedLabel, setTypedLabel] = useState<string>('');
  const [options, setOptions] = useState<any[]>([]);
  const [position, setPosition] = useState({});
  const [isListOverflowed, setIsListOverflowed] = useState(true);
  const selectNode = useRef<any>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && listRef.current) {
      setOptionsPosition(inputRef.current, listRef.current);
    }
  }, [toggleSelect, options]);

  useEffect(() => {
    setTypedLabel('');
    if (value) {
      const elementOfTheValue = React.Children.toArray(children).filter(
        (element) => getOptionProps(element, 'optionValue') === value,
      );
      setTypedLabel(getOptionProps(elementOfTheValue[0], 'optionLabel'));
    } else {
      if (inputThreshold === 0 && typedLabel?.length === 0) {
        const currentOptions = getOptions('all');
        if (optionsChunkSize) {
          showOptions(true, getChunkedOptions(currentOptions, optionsChunkSize));
        } else {
          showOptions(true, currentOptions);
        }
      }
    }
    setToggleSelect(false);
  }, [children]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('resize', resizeDebounce);
    window.addEventListener('scroll', scrollDebounce);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('resize', resizeDebounce);
      window.addEventListener('scroll', scrollDebounce);
    };
  }, []);

  const setOptionsPosition = (input, list) => {
    const inputY = input.getBoundingClientRect().top - window.scrollY - document.body.getBoundingClientRect().top;
    const inputX = input.getBoundingClientRect().left - window.scrollX - document.body.getBoundingClientRect().left;
    const inputW = input.getBoundingClientRect().width;
    const inputH = input.getBoundingClientRect().height;
    const inputPaddingLeft = window.getComputedStyle(input, null).getPropertyValue('padding-left');
    const inputPaddingTop = window.getComputedStyle(input, null).getPropertyValue('padding-top');
    const inputBorderLeft = window.getComputedStyle(input, null).getPropertyValue('border-left-width');
    const inputBorderTop = window.getComputedStyle(input, null).getPropertyValue('border-top-width');
    const listHeight = list.getBoundingClientRect().height;
    setPosition({
      inputY,
      inputX,
      inputW,
      inputH,
      inputPaddingLeft,
      inputPaddingTop,
      inputBorderLeft,
      inputBorderTop,
      listHeight,
    });

    if (window.visualViewport) {
      if (
        input.getBoundingClientRect().top + list.getBoundingClientRect().height + input.getBoundingClientRect().height >
        window.visualViewport.height
      ) {
        setIsListOverflowed(true);
      } else {
        setIsListOverflowed(false);
      }
    } else {
      // on everything else
      if (
        input.getBoundingClientRect().top + list.getBoundingClientRect().height + input.getBoundingClientRect().height >
        window.innerHeight
      ) {
        setIsListOverflowed(true);
      } else {
        setIsListOverflowed(false);
      }
    }
  };

  const handleOutsideClick = (event: any) => {
    if (listRef.current) {
      if (!selectNode.current.contains(event.target) && !listRef.current.contains(event.target)) {
        setToggleSelect(false);
      }
    }
  };

  const onFocused = (event) => {
    event.target.select();
  };

  const debounce = (func: (e?: any) => void) => {
    let timer: null | ReturnType<typeof setTimeout>;
    return function (e: any) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        func(e);
      }, 1);
    };
  };

  const closeOnResize = () => {
    setToggleSelect(false);
  };

  const resizeDebounce = useCallback(debounce(closeOnResize), []);
  const scrollDebounce = useCallback(debounce(closeOnResize), []);

  const showOptions = (state: boolean, payload: ReactNode[] = []) => {
    if (state === true && payload?.length > 0) {
      setOptions(payload);
      setToggleSelect(true);
    } else {
      setOptions([]);
      setToggleSelect(state);
    }
  };

  const handleInputChange = (e: any) => {
    const currentOptions = getOptions('option', e.target.value);
    if (e.target.value?.length >= inputThreshold) {
      if (currentOptions) {
        if (currentOptions.length > 0) {
          if (optionsChunkSize) {
            showOptions(true, getChunkedOptions(currentOptions, optionsChunkSize));
          } else {
            showOptions(true, currentOptions);
          }
        } else {
          if (inputThreshold >= 0 && getOptions('fallback')?.length > 0) {
            showOptions(true, getOptions('fallback'));
          }
        }
      }
    } else {
      showOptions(false);
    }
    if (e.target.value === '' && inputThreshold === 0) {
      if (optionsChunkSize) {
        showOptions(true, getChunkedOptions(getOptions('all'), optionsChunkSize));
      } else {
        showOptions(true, getOptions('all'));
      }
    }
  };

  const onChangeDebounce = useCallback(debounce(handleInputChange), []);

  const getOptions = (type: 'all' | 'option' | 'fallback', searchTerm?: string) => {
    switch (type) {
      case 'all':
        return React.Children.toArray(children).filter((element) => {
          const labelText = element as React.ReactElement;
          return !getOptionProps(labelText, 'isOptionFallback');
        });
      case 'option':
        return React.Children.toArray(children).filter((element) => {
          const labelText = element as React.ReactElement;
          if (searchTerm) {
            return (
              !getOptionProps(labelText, 'isOptionFallback') &&
              getOptionProps(labelText, 'optionLabel').toLowerCase().includes(searchTerm.toLowerCase()) &&
              searchTerm
            );
          }
        });

      case 'fallback':
        return React.Children.toArray(children).filter((element) => getOptionProps(element, 'isOptionFallback'));
      default:
        return React.Children.toArray(children);
    }
  };

  const handleOptionClick = (optionValue: string, optionLabel: string, option: any) => {
    setTypedLabel(optionLabel);
    setToggleSelect(false);
    setValue(optionValue);
    setOptions(option);
  };

  const handleToggle = (e: any) => {
    if (options?.length > 0) {
      setToggleSelect((toggleSelect) => !toggleSelect);
      e.stopPropagation();
    }
  };

  const onScroll = () => {
    if (listRef.current) {
      if (
        listRef.current.clientHeight + listRef.current.scrollTop >= listRef.current.scrollHeight - 5 &&
        optionsChunkSize
      ) {
        if (inputThreshold > 0) {
          if (getOptions('option', typedLabel) != undefined) {
            setOptions(getOptions('option', typedLabel).slice(0, options?.length + optionsChunkSize));
          }
        } else if (inputThreshold === 0 && typedLabel?.length === 0) {
          setOptions(getOptions('all').slice(0, options?.length + optionsChunkSize));
        } else if (inputThreshold === 0 && typedLabel?.length > 0) {
          setOptions(getOptions('option', typedLabel).slice(0, options?.length + optionsChunkSize));
        }
      }
    }
  };

  const showPlaceholder = () => {
    if (toggleSelect && typedLabel?.length > 0) {
      return (
        <Styled.Placeholder
          inputPaddingLeft={position['inputPaddingLeft']}
          inputPaddingTop={position['inputPaddingTop']}
          inputBorderLeft={position['inputBorderLeft']}
          inputBorderTop={position['inputBorderTop']}
        >
          <Styled.PlaceholderText hasBeenTyped={true}>
            {getOptionProps(options[0], 'optionLabel')?.slice(0, typedLabel?.length)?.toLowerCase() ===
              typedLabel?.toLowerCase() && typedLabel}
          </Styled.PlaceholderText>
          <Styled.PlaceholderText hasBeenTyped={false}>
            {getOptionProps(options[0], 'optionLabel')?.slice(0, typedLabel?.length)?.toLowerCase() ===
              typedLabel?.toLowerCase() &&
              getOptionProps(options[0], 'optionLabel')?.slice(
                typedLabel?.length,
                options[0]?.props?.optionLabel?.length,
              )}
          </Styled.PlaceholderText>
        </Styled.Placeholder>
      );
    } else if (typedLabel?.length === 0) {
      return (
        <Styled.Placeholder
          inputPaddingLeft={position['inputPaddingLeft']}
          inputPaddingTop={position['inputPaddingTop']}
          inputBorderLeft={position['inputBorderLeft']}
          inputBorderTop={position['inputBorderTop']}
        >
          <Styled.PlaceholderText hasBeenTyped={false}>{placeholder}</Styled.PlaceholderText>
        </Styled.Placeholder>
      );
    }
  };

  return (
    <Styled.AutocompleteSelect
      id={id}
      ref={selectNode}
      classNames={classNames}
      validationStatus={validationStatus}
      configStyles={configStyles}
    >
      <Styled.InputContainer>
        <Styled.Input
          ref={inputRef}
          disabled={disabled}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              options?.length !== 0 &&
              !getOptionProps(options[0], 'isOptionFallback') &&
              typedLabel?.length !== 0
            ) {
              setTypedLabel(getOptionProps(options[0], 'optionLabel'));
              setValue(getOptionProps(options[0], 'optionValue'));
              setToggleSelect(false);
            }
          }}
          name={name}
          type="text"
          value={typedLabel}
          onChange={(e) => {
            setTypedLabel(e.target.value);
            onChangeDebounce(e);
          }}
          onClick={handleToggle}
          autoComplete="off"
          placeholder={placeholder}
          onFocus={(e) => onFocused(e)}
        />
        {showPlaceholder()}
      </Styled.InputContainer>
      <Portal>
        {toggleSelect && (
          <Styled.OptionList
            ref={listRef}
            onScroll={onScroll}
            inputY={position['inputY']}
            inputX={position['inputX']}
            inputW={position['inputW']}
            inputH={position['inputH']}
            listHeight={position['listHeight']}
            isListOverflowed={isListOverflowed}
            visualVp={window.visualViewport}
          >
            {React.Children.toArray(options).map((option, index) => {
              return (
                <Styled.Option key={`select-option-${index}`}>
                  {React.cloneElement(option as React.ReactElement, {
                    onClick: (optionValue: string, optionLabel: string) => {
                      !getOptionProps(option, 'isOptionFallback') &&
                        handleOptionClick(optionValue, optionLabel, option);
                    },
                  })}
                </Styled.Option>
              );
            })}
          </Styled.OptionList>
        )}
      </Portal>
    </Styled.AutocompleteSelect>
  );
}

export type AutocompleteOptionProps = {
  /**
   * An array of elements that make up the list of options.
   */
  children?: ReactNode[] | ReactNode;

  /**
   * if present, the component will be shown to tell the user that no matches were found
   */
  isOptionFallback?: boolean;

  /**
   * OnClick handler
   */
  onClick?: (value: string, label: string) => void;

  /**
   * Set a label for AutocompleteSelect component.
   */
  optionLabel?: string;

  /**
   * Set a value for AutocompleteSelect component.
   */
  optionValue?: string;
};

export function AutocompleteOption({
  children,
  isOptionFallback = false,
  optionValue,
  optionLabel,
  onClick = (e) => {},
}: AutocompleteOptionProps) {
  return (
    <Styled.AutocompleteOption
      onClick={() => {
        optionValue && optionLabel && onClick(optionValue, optionLabel);
      }}
      isOptionFallback={isOptionFallback}
    >
      {children}
    </Styled.AutocompleteOption>
  );
}
