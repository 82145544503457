import React from 'react';
import * as Styled from './email.styled';
export type EmailProps = {
  /**
   * autocomplete allows the browser to predict the value
   */
  autoComplete?: string;

  /**
   * for accessibility roles and attributes
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * To disable the input[email] field.
   */
  disabled?: boolean;

  /**
   * To identifies the input[email] semantic element.
   */
  id: string;

  /**
   * The Boolean multiple attribute, means the user can enter comma separated email addresses in the email widget.
   */
  multiple?: boolean;

  /**
   * name specifies a name for an input element
   */
  name: string;

  /**
   * A function to be triggered when the input element loses focus
   */
  onBlur?: () => void;

  /**
   * Set the current value in ReactState
   */
  onChange?: (e) => void;

  /**
   * A function to be triggered when the input element gets focus
   */
  onFocus?: () => void;

  /**
   * Pattern is a regular expression that the input's value must match in order for the value to pass constraint validation.
   */
  pattern?: string;

  /**
   * Placeholder is a string that provides a brief hint to the user as to what kind of information is expected in the field.
   */
  placeholder?: string;

  /**
   * readOnly is a Boolean attribute which, if present, means this field cannot be edited by the user.
   */
  readOnly?: boolean;

  /**
   * required specifies that an input field must be filled out before submitting the form
   */
  required?: boolean;

  /**
   * Value contains a string which is automatically validated as conforming to e-mail syntax.
   */
  value?: string;
};
export function Email({
  autoComplete = 'off',
  className,
  configStyles,
  disabled,
  id,
  multiple = false,
  name,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  readOnly = false,
  required,
  value,
}: EmailProps) {
  return (
    <Styled.Input
      autoComplete={autoComplete}
      className={className}
      configStyles={configStyles}
      disabled={disabled}
      id={id}
      multiple={multiple}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      pattern={pattern}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      type="email"
      value={value}
    />
  );
}
